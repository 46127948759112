import axios from 'axios';
import setTokenHeader from '../../setTokenInHeader';

import { walletAPI } from '../..';

const updateUserInWallet = async (value) => {
  await setTokenHeader();
  const { payload, id } = value;
  return await axios.put(`${walletAPI}/accounts/users/${id}`, payload);
};

export default updateUserInWallet;
