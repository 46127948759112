import React, { useState, useEffect } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import TextField from '../../../customComponent/CustomInput';
import styles from '../../styles';
import Layout from '../../../Layout';
import SuccessAlert from '../../../alert/SuccessAlert';
import updateWallet from '../../../../../api/handler/wallet/updateWallet';
import loadError from '../../../../../api/loadErr';
import ToastAlert from '../../../alert/ToastAlert';

// Material-ui/Core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

// Material-ui/Icon
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(styles);

const WalletPin = (props) => {
  const classes = useStyles();
  useEffect(() => {
    document.title = 'PMT eWallet - Change wallet Pin';
  });

  // Target Pasword Input
  const [isDisplayPin, setIsDisplayPin] = useState(false);
  const [isDisplayNewPin, setIsDisplayNewPin] = useState(false);
  const [isDisplayConfirmPin, setIsDisplayConfirmPin] = useState(false);

  // Alert State
  const [alert, setAlert] = useState(null);
  const removeAlert = () => {
    setAlert(null);
    props.history.goBack();
  };
  const removeToastAlert = () => {
    setAlert(null);
  };

  // Toggle Password
  const togglePin = () => {
    setIsDisplayPin(!isDisplayPin);
  };
  // Toggle Password
  const toggleNewPin = () => {
    setIsDisplayNewPin(!isDisplayNewPin);
  };
  // Toggle Password
  const toggleConfirmPin = () => {
    setIsDisplayConfirmPin(!isDisplayConfirmPin);
  };

  // Pin
  const [pin, setPin] = useState('');
  const [newPin, setNewPin] = useState('');
  const [confirmPin, setConfirmPin] = useState('');

  //Error State
  const [isPinErr, setPinErr] = useState(false);
  const [isNewPinErr, setNewPinErr] = useState(false);
  const [isConfirmPinErr, setConfirmPinErr] = useState(false);

  // UseMutation
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(updateWallet, {
    onSettled: (data, error) => {
      if (error) {
        setAlert({
          msg: loadError(error),
          type: 'error',
        });
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries('user-account');
      setAlert({
        title: 'Wallet Settings Message',
        msg: 'Wallet pin enabled successfully!',
        type: 'success',
      });
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const regExp = /[0-9]{5}/;

    // Check for Pin
    if (!pin.match(regExp)) {
      setPinErr(true);
    } else {
      setPinErr(false);
    }
    if (!newPin.match(regExp)) {
      setNewPinErr(true);
    } else {
      setNewPinErr(false);
    }

    if (confirmPin !== newPin) {
      setConfirmPinErr(true);
    } else {
      setConfirmPinErr(false);
    }

    if (pin.match(regExp) && newPin.match(regExp) && newPin === confirmPin) {
      mutate({ wallet: props.match.params.id, payload: { pin, newPin } });
    }
  };

  return (
    <Layout title='settings'>
      {alert && alert.type === 'error' && (
        <ToastAlert
          msg={alert.msg}
          type={alert.type}
          closeAlert={removeToastAlert}
        />
      )}
      {alert && alert.type === 'success' && (
        <SuccessAlert
          msg={alert.msg}
          type={alert.type}
          title={alert.title}
          removeAlert={removeAlert}
        />
      )}
      <Box className={classes.container}>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography variant='body1'>Change wallet pin</Typography>
        </Box>
        <Box maxWidth='520px' margin='auto'>
          <Box marginTop='10px'>
            <Typography style={{ marginBottom: '5px' }} variant='body2'>
              Specify old pin
            </Typography>
            <form onSubmit={handleSubmit}>
              <Box marginBottom='10px'>
                <TextField
                  error={isPinErr}
                  helperText={isPinErr && 'Pin must be a number'}
                  fullWidth={true}
                  size='small'
                  variant='outlined'
                  label='Old Pin'
                  type={isDisplayPin ? 'text' : 'password'}
                  inputProps={{
                    maxLength: 5,
                    minLength: 0,
                  }}
                  value={pin}
                  endAdornment={
                    <InputAdornment position='end'>
                      {isDisplayPin ? (
                        <VisibilityOffIcon
                          className={classes.visibilityIcon}
                          onClick={() => togglePin()}
                        />
                      ) : (
                        <VisibilityIcon
                          className={classes.visibilityIcon}
                          onClick={() => togglePin()}
                        />
                      )}
                    </InputAdornment>
                  }
                  onChange={(e) => {
                    e.target.value > 0 && setPin(false);

                    setPin(e.target.value);
                  }}
                />
              </Box>
              <Box marginTop='30px' marginBottom='5px'>
                <Typography variant='body2'>Specify new pin</Typography>
              </Box>
              <Box marginBottom='10px'>
                <TextField
                  error={isNewPinErr}
                  helperText={isNewPinErr && 'New pin must be a number'}
                  fullWidth={true}
                  size='small'
                  variant='outlined'
                  label='New Pin'
                  type={isDisplayNewPin ? 'text' : 'password'}
                  value={newPin}
                  inputProps={{
                    maxLength: 5,
                    minLength: 0,
                  }}
                  endAdornment={
                    <InputAdornment position='end'>
                      {isDisplayPin ? (
                        <VisibilityOffIcon
                          className={classes.visibilityIcon}
                          onClick={() => toggleNewPin()}
                        />
                      ) : (
                        <VisibilityIcon
                          className={classes.visibilityIcon}
                          onClick={() => toggleNewPin()}
                        />
                      )}
                    </InputAdornment>
                  }
                  onChange={(e) => {
                    e.target.value > 0 && setNewPin(false);
                    setNewPin(e.target.value);
                  }}
                />
              </Box>
              <Box marginBottom='10px'>
                <TextField
                  error={isConfirmPinErr}
                  helperText={isConfirmPinErr && 'Pin do not match'}
                  fullWidth={true}
                  size='small'
                  variant='outlined'
                  label='Confirm New Pin'
                  type={isDisplayConfirmPin ? 'text' : 'password'}
                  value={confirmPin}
                  inputProps={{
                    maxLength: 5,
                    minLength: 0,
                  }}
                  endAdornment={
                    <InputAdornment position='end'>
                      {isDisplayConfirmPin ? (
                        <VisibilityOffIcon
                          className={classes.visibilityIcon}
                          onClick={() => toggleConfirmPin()}
                        />
                      ) : (
                        <VisibilityIcon
                          className={classes.visibilityIcon}
                          onClick={() => toggleConfirmPin()}
                        />
                      )}
                    </InputAdornment>
                  }
                  onChange={(e) => {
                    e.target.value > 0 && setConfirmPin(false);
                    setConfirmPin(e.target.value);
                  }}
                />
              </Box>
              <Button
                disabled={isLoading}
                type='submit'
                className={classes.btnBg}
              >
                {isLoading && (
                  <CircularProgress className={classes.circularProgress} />
                )}
                Change
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default WalletPin;
