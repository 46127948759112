import axios from 'axios';

const setTokenHeader = async () => {
	const token = await JSON.parse(localStorage.getItem('pmt-user-token'));
	if (token) {
		axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
	}
};

export default setTokenHeader;
