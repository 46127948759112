import React, { useReducer, useContext } from 'react';
import AuthContext from '../auth/authContext';
import WalletContext from './walletContext';
import walletReducer from './walletReducer';
import setTokenHeader from '../../utils/setTokenInHeader';
import axios from 'axios';

import {
  ERROR_MESSAGE,
  REMOVE_ERROR,
  CLOSE_NOTICE,
  UPDATE_WALLET_STATE,
  UPDATE_TRANSACTIONS,
} from '../types';

const api = process.env.REACT_APP_LOCAL_WALLET_API;

const WalletState = ({ children }) => {
  const authContext = useContext(AuthContext);
  const { logOut } = authContext;
  const initialState = {
    wallets: null,
    transactions: null,
    isWallet: false,
    transfers: '',
    deposits: '',
    withdraws: '',
    initiateCreateWallet: false,
    isNotice: false,
  };

  const [state, dispatch] = useReducer(walletReducer, initialState);

  // Get Banks
  const updateWalletState = (payload) => {
    dispatch({
      type: UPDATE_WALLET_STATE,
      payload,
    });
  };

  // Create Transfer
  const updateTransfer = async (details, id) => {
    await setTokenHeader();
    try {
      const res = await axios.put(`${api}/transfers/${id}`, details);
      const data = res.data;
      return data;
    } catch (err) {
      loadError(err);
    }
  };

  // Update Transaction
  const updateTransactions = (deposits, transfers, withdraws) => {
    dispatch({
      type: UPDATE_TRANSACTIONS,
      payload: [...deposits, ...transfers, ...withdraws],
      deposits,
      transfers,
      withdraws,
    });
  };

  const closeNotice = (id) =>
    dispatch({
      type: CLOSE_NOTICE,
      payload: id,
    });

  // Load Error
  const loadError = (err) => {
    if (typeof err.response !== 'undefined') {
      if (err.response.status === 403) {
        logOut();
      }
      const errMsg = err.response.data.message;

      dispatch({
        type: ERROR_MESSAGE,
        error: errMsg,
      });
      console.log(errMsg);
    } else {
      dispatch({
        type: ERROR_MESSAGE,
        error: err.message,
      });
      console.log(err.message);
    }

    setTimeout(() => {
      dispatch({
        type: dispatch({
          type: REMOVE_ERROR,
        }),
      });
    }, 3000);
  };

  return (
    <WalletContext.Provider
      value={{
        wallets: state.wallets,
        initiateCreateWallet: state.initiateCreateWallet,
        transactions: state.transactions,
        isWallet: state.isWallet,
        isNotice: state.isNotice,
        transfers: state.transfers,
        withdraws: state.withdraws,
        deposits: state.deposits,
        updateTransfer,
        closeNotice,
        updateTransactions,
        updateWalletState,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export default WalletState;
