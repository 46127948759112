import './component/assets/css/App.css';
import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import AuthContext from './context/auth/authContext';
import WalletContext from './context/wallet/walletContext';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import routes, { privateRoutes } from './routes/routes';
import Alert from './component/layouts/alert/ToastAlert';
import GlobalLoadingIndicator from './utils/GlobalLoadingIndicator';
import ScrollToTop from './utils/ScrollToTop';

import getUser from './api/handler/auth/getUser';
import getAccount from './api/handler/wallet/getAccount';
import loadError from './api/loadErr';

function App() {
  const authContext = useContext(AuthContext);
  const walletContext = useContext(WalletContext);
  const { updateWalletState } = walletContext;
  const { updateUserState, logOut } = authContext;

  // Alert State
  const [alert, setAlert] = useState(null);
  const removeAlert = () => setAlert(null);

  // Load User
  const token = JSON.parse(localStorage.getItem('pmt-user-token'));
  const { data } = useQuery(['user-data', token], getUser, {
    enabled: !!token,
    onSettled: (data, error) => {
      if (error) {
        if (
          typeof error.response !== 'undefined' &&
          error.response.status === 403
        ) {
          logOut();
        }

        setAlert({
          msg: loadError(error),
          type: 'error',
        });
      }
    },
    onSuccess: (data) => {
      updateUserState(data.data.payload);
    },
  });
  const user = data && data?.data?.payload;
  useQuery(['user-account', user], getAccount, {
    enabled: !!user,
    onSettled: (data, error) => {
      if (error) {
        console.log(error);
        setAlert({
          msg: loadError(error),
          type: 'error',
        });
      }
    },
    onSuccess: (data) => {
      updateWalletState(data.data.payload);
    },
  });

  // Get Routes
  const getRoutes = (routes) => {
    return routes.map((route, key) => (
      <Route
        exact
        path={`${route.path}`}
        component={route.component}
        key={key}
      />
    ));
  };

  return (
    <Router>
      <GlobalLoadingIndicator />
      <ScrollToTop />
      {alert && (
        <Alert msg={alert.msg} type={alert.type} closeAlert={removeAlert} />
      )}
      <Switch>
        {token ? (
          getRoutes(privateRoutes)
        ) : (
          <>
            <Redirect from='/' to='/auth/login' />
            {getRoutes(routes)}
          </>
        )}
      </Switch>
    </Router>
  );
}

export default App;
