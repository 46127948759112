import { LOGIN_USER, LOGOUT_USER, UPDATE_AUTH_STATE } from '../types';

const appReducer = (state, action) => {
	switch (action.type) {
		case UPDATE_AUTH_STATE:
			return {
				...state,
				isAuthenticated: true,
				user: action.payload,
			};

		case LOGIN_USER:
			localStorage.setItem(
				'pmt-user-token',
				JSON.stringify(action.payload.token)
			);
			return {
				...state,
				isAuthenticated: true,
			};
		case LOGOUT_USER:
			localStorage.removeItem('pmt-user-token');
			return {
				...state,
				isAuthenticated: false,
				token: null,
				user: null,
			};

		default:
			return state;
	}
};

export default appReducer;
