import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AppContext from '../../../context/app/appContext';
import AuthContext from '../../../context/auth/authContext';
import styles from './styles';
import logo from '../../assets/img/peace-logo.png';
import classnames from 'classnames';

// Material-UI/Core
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';

// Material-UI/Icon
import SettingsIcon from '@material-ui/icons/Settings';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const useStyles = makeStyles(styles);

const Wrapper = () => {
  const classes = useStyles();
  const appContext = useContext(AppContext);
  const authContext = useContext(AuthContext);
  const { logOut } = authContext;
  const { isDisplaySidebar, toggleSideBar } = appContext;

  // Path State
  const [path, setPath] = useState(null);

  useEffect(
    () => {
      setPath(window.location.pathname.substr(1).split('/')[0]);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <Drawer
      style={{ zIndex: '5000' }}
      variant='permanent'
      className={classes.drawer}
      anchor={'left'}
      open={isDisplaySidebar}
      onClose={() => toggleSideBar()}
      ModalProps={{
        keepMounted: true,
      }}
    >
      <Box>
        <Box
          padding='30px 15px'
          style={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box width='18px' height='18px' marginRight='5px'>
            <img style={{ width: '100%' }} src={logo} alt='peace logo' />
          </Box>
          <Typography className={classes.headerText} variant='h6'>
            PMT Wallet
          </Typography>
        </Box>
        <Divider className={classes.divider} />
        <List className={classes.list}>
          <Link
            className={classnames({ [classes.activeLink]: path === '' })}
            to='/'
          >
            <ListItem button>
              <DashboardIcon />
              Dashboard
            </ListItem>
          </Link>
          <Link
            className={classnames({
              [classes.activeLink]: path === 'deposit',
            })}
            to='/deposit'
          >
            <ListItem button>
              <HowToVoteIcon />
              Deposit
            </ListItem>
          </Link>
          <Link
            className={classnames({
              [classes.activeLink]: path === 'withdraw',
            })}
            to='/withdraw'
          >
            <ListItem button>
              <CreditCardIcon />
              Withdraw
            </ListItem>
          </Link>
          <Link
            className={classnames({
              [classes.activeLink]: path === 'transfer',
            })}
            to='/transfer'
          >
            <ListItem button>
              <SwapHorizIcon />
              Transfer
            </ListItem>
          </Link>
          <Link
            style={{ marginBottom: '10px' }}
            className={classnames({
              [classes.activeLink]: path === 'transactions',
            })}
            to='/transactions'
          >
            <ListItem button>
              <AccessTimeIcon />
              Transaction History
            </ListItem>
          </Link>
          <Divider className={classes.divider} />
          <Link
            style={{ marginTop: '10px' }}
            className={classnames({
              [classes.activeLink]: path === 'settings',
            })}
            to='/settings'
          >
            <ListItem button>
              <SettingsIcon />
              Settings
            </ListItem>
          </Link>

          <ListItem
            onClick={() => {
              window.open(
                'https://pmt.ng/contact-us',
                '_blank',
                'noopener,noreferrer'
              );
            }}
            button
          >
            <HelpOutlineOutlinedIcon />
            Help
          </ListItem>
          <ListItem
            onClick={() => {
              logOut();
            }}
            button
          >
            <ExitToAppIcon /> Logout
          </ListItem>
        </List>
      </Box>
    </Drawer>
  );
};

export default Wrapper;
