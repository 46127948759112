import { grayColor, secColor, whiteColor } from '../../../assets/js/styleCss';

const styles = () => ({
  container: {
    borderRadius: '5px',
    boxShadow: `0px 0px 5px rgba(0, 0, 0, 0.1)`,
  },
  header: {
    backgroundColor: secColor,
    fontWeight: 'bold',
    color: whiteColor,
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    padding: '15px',
  },
  children: {
    padding: '15px',
    borderBottomRightRadius: '5px',
    borderBottomLeftRadius: '5px',
    backgroundColor: whiteColor,
  },
  closeBtn: {
    color: secColor,
    backgroundColor: whiteColor,
    textTransform: 'capitalize',
    fontWeight: '600',
    '&:hover': {
      backgroundColor: grayColor[4],
    },
  },
});

export default styles;
