import React from 'react';

// Material-Ui/Core
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { secColor } from '../../assets/js/styleCss';

const CircularProgressWrapper = () => {
  return (
    <Box
      position='fixed'
      bgcolor='rgba(250, 250, 250, 0.4)'
      top='0'
      left='0'
      width='100%'
      height='100vh'
      display='flex'
      alignItems='center'
      justifyContent='center'
      zIndex='18000'
    >
      <CircularProgress style={{ color: secColor }} />
    </Box>
  );
};

export default CircularProgressWrapper;
