import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryClient, useMutation, useQuery } from 'react-query';
import AuthContext from '../../../context/auth/authContext';
import CustomInput from '../../layouts/customComponent/CustomInput';
import CustomSelect from '../../layouts/customComponent/CustomSelect';
import cx from 'classnames';
import styles from './styles';
import Alert from '../../layouts/alert/ToastAlert';
import bg from '../../assets/img/bg.jpg';
import logo from '../../assets/img/peace-logo.png';
import loginUser from '../../../api/handler/auth/loginUser';
import getUser from '../../../api/handler/auth/getUser';
import classNames from 'classnames';

// Material-UI/Core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';

// Material-UI/Icon
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import loadError from '../../../api/loadErr';

const useStyles = makeStyles(styles);

const Login = () => {
	const classes = useStyles();

	// Change Document Title
	useEffect(() => {
		document.title = 'PMT eWallet - Login';
	});

	const authContext = useContext(AuthContext);
	const { updateUserState } = authContext;
	const history = useHistory();

	// Query Clinet
	const queryClient = useQueryClient();
	const { mutate, isLoading, data } = useMutation(loginUser, {
		onSettled: (data, error) => {
			if (error) {
				setAlert({
					msg: loadError(error),
					type: 'error',
				});
			}
		},
	});

	const gridItemStyles = classNames(classes.gridItem, {
		[classes.overlay]: isLoading,
	});

	const token = data && data?.data?.payload?.token;
	if (token) {
		localStorage.setItem('pmt-user-token', JSON.stringify(token));
	}

	queryClient.invalidateQueries('user-data');

	const { isLoading: gettingUser } = useQuery(['user-data', token], getUser, {
		enabled: !!token,
		onSettled: (data, error) => {
			if (error) {
				setAlert({
					msg: loadError(error),
					type: 'error',
				});
			}
		},
		onSuccess: (data) => {
			updateUserState(data.data.payload);
			history.push('/');
		},
	});

	const [userId, setUserId] = useState('');
	const [password, setPassword] = useState('');
	const [userType, setUserType] = useState('');

	// Error State
	const [isPasswordErr, setPasswordErr] = useState(false);
	const [isUserIdErr, setUserIdErr] = useState(false);
	const [isUserTypeErr, setUserTypeErr] = useState(false);

	// Target Pasword Input
	const [isDisplayPassword, setIsDisplayPassword] = useState(false);

	// Toggle Password
	const togglePassword = () => {
		setIsDisplayPassword(!isDisplayPassword);
	};

	//Alert State
	const [alert, setAlert] = useState(null);
	const removeAlert = () => setAlert(null);

	const handleLogin = (e) => {
		e.preventDefault();
		if (password === '') {
			setPasswordErr(true);
		} else {
			setPasswordErr(false);
		}
		if (userId === '') {
			setUserIdErr(true);
		} else {
			setUserIdErr(false);
		}
		if (userType === '') {
			setUserTypeErr(true);
		} else {
			setUserTypeErr(false);
		}

		// Email RegExp
		const emailRex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

		if (password !== '' && userId !== '' && userType !== '') {
			const payload = {
				password,
				userType,
			};
			if (emailRex.test(userId.trim())) {
				payload.email = userId.trim();
			} else {
				payload.phone = userId.trim();
			}
			mutate(payload);
		}
	};

	const users = [
		{
			type: 'C',
			name: 'Customer',
		},
		{
			type: 'S',
			name: 'Staff',
		},
	];

	return (
		<Box
			display='flex'
			flexDirection='column'
			justifyContent='center'
			alignItems='center'
			height='100vh'
			style={{
				background: `linear-gradient(90deg, rgba(250, 250, 250, 1), rgba(250, 250, 250, 0.6)), url('${bg}') no-repeat `,
				backgroundSize: 'cover',
				backgroundPosition: 'center ',
			}}
		>
			{alert && (
				<Alert msg={alert.msg} type={alert.type} closeAlert={removeAlert} />
			)}
			<Grid container>
				<Grid className={gridItemStyles} item xs={11} sm={8} md={6} lg={4}>
					{isLoading && <LinearProgress className={classes.linearProgress} />}
					<Box padding='15px'>
						<Box margin='auto' marginBottom='20px' width='50px' height='50px'>
							<img style={{ width: '100%' }} src={logo} alt='peace-logo' />
						</Box>
						<Box marginBottom='30px'>
							<Typography className={classes.headerText} variant='h5'>
								PMT Wallet
							</Typography>
						</Box>
						<form onSubmit={handleLogin} className={cx(classes.formContainer)}>
							<Box marginBottom='20px'>
								<CustomSelect
									error={isUserTypeErr}
									helperText={isUserTypeErr && 'User type is required'}
									fullWidth={true}
									variant='outlined'
									size='small'
									value={userType}
									onChange={(e) => {
										setUserType(e.target.value);
									}}
								>
									<option className={classes.listItem} value={''}>
										Select user type
									</option>
									{users.map((user, i) => (
										<option key={i} value={user.type}>
											{user.name}
										</option>
									))}
								</CustomSelect>
							</Box>
							<Box marginBottom='20px'>
								<CustomInput
									label='Phone/Email'
									type='text'
									size='small'
									value={userId}
									variant='outlined'
									error={isUserIdErr}
									helperText={isUserIdErr && 'Enter phone/Email address'}
									fullWidth={true}
									onChange={(e) => setUserId(e.target.value)}
								/>
							</Box>
							<Box marginBottom='20px'>
								<CustomInput
									label='Password'
									value={password}
									size='small'
									isAutoComplete='off'
									variant='outlined'
									error={isPasswordErr}
									helperText={isPasswordErr && 'Password is required'}
									type={isDisplayPassword ? 'text' : 'password'}
									fullWidth={true}
									endAdornment={
										<InputAdornment position='end'>
											{isDisplayPassword ? (
												<VisibilityOffIcon
													className={classes.visibilityIcon}
													onClick={() => togglePassword()}
												/>
											) : (
												<VisibilityIcon
													className={classes.visibilityIcon}
													onClick={() => togglePassword()}
												/>
											)}
										</InputAdornment>
									}
									onChange={(e) => setPassword(e.target.value)}
								/>
							</Box>
							<Button
								disabled={isLoading || gettingUser}
								type='submit'
								className={classes.btn}
							>
								Login
							</Button>
						</form>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Login;
