import React, { useState } from 'react';
import { useQueryClient, useMutation } from 'react-query';
import styles from '../styles';
import ConfirmUpdate from './ConfirmUpdate';
import Alert from '../../alert/SuccessAlert';
import ToastAlert from '../../alert/ToastAlert';
import updateWallet from '../../../../api/handler/wallet/updateWallet';
import loadError from '../../../../api/loadErr';

// Material-Ui/Core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

const EnableOTP = ({ wallet }) => {
	const classes = useStyles();

	// App State
	const [isChecked, setChecked] = useState(wallet && wallet.transferOtp);
	const toggleCheck = () => {
		setDisplayModal(true);
		setChecked(!isChecked);
	};

	// Display Confirm Pin
	const [isDisplayModal, setDisplayModal] = useState(false);
	const closeModal = () => {
		setChecked(wallet.transferOtp);
		setDisplayModal(false);
		setPin('');
	};

	// Pin
	const [pin, setPin] = useState('');

	// Alert State
	const [alert, setAlert] = useState(null);
	const removeAlert = () => setAlert(null);

	// UseMutation
	const queryClient = useQueryClient();

	const { isLoading, mutate } = useMutation(updateWallet, {
		onSettled: (data, error) => {
			if (error) {
				console.log(error);
				setAlert({
					msg: loadError(error),
					type: 'error',
				});
			}
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries('user-account');
			setAlert({
				title: 'Wallet Setting Message',
				msg: 'OTP enabled successfully!',
				type: 'success',
			});
			setPin('');
			setDisplayModal(false);
		},
	});

	// Process Update
	const confirmUpdate = () =>
		mutate({
			payload: {
				transferOtp: isChecked,
				pin,
			},
			wallet: wallet.wallet,
		});

	return (
		<>
			{alert && alert.type === 'success' && (
				<Alert
					msg={alert.msg}
					title={alert.title}
					type={alert.type}
					removeAlert={removeAlert}
				/>
			)}
			{alert && alert.type === 'error' && (
				<ToastAlert
					msg={alert.msg}
					type={alert.type}
					closeAlert={removeAlert}
				/>
			)}
			{isDisplayModal && (
				<ConfirmUpdate
					pin={pin}
					setPin={setPin}
					isProcessing={isLoading}
					confirmUpdate={confirmUpdate}
					close={closeModal}
				/>
			)}
			<Box marginBottom='30px' className={classes.spaceBetween}>
				<Typography>Enable Transfer OTP</Typography>
				<Switch
					className={classes.switch}
					size='small'
					checked={isChecked}
					onChange={() => toggleCheck()}
				/>
			</Box>
		</>
	);
};

export default EnableOTP;
