import React, { useState, useContext } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import WalletContext from '../../../context/wallet/walletContext';
import styles from './styles';
import TextField from '../customComponent/CustomInput';
import numberWithComma from '../../utils/numberWithCommas';
import Alert from '../alert/ToastAlert';
import SuccessAlert from '../alert/SuccessAlert';
import InfoAlert from '../alert/ToastAlert';
import ConfirmTransfer from './modal/ConfirmTransfer';
import uuid from 'react-uuid';
import TransferReceipt from './TransferReceipt';
import createTransfer from '../../../api/handler/wallet/createTransfer';
import loadError from '../../../api/loadErr';
import getWalletInfo from '../../../api/handler/wallet/getWalletInfo';
import Content from './components/Content';
import CustomInput from '../customComponent/CustomInput';
import cx from 'classnames';
import CircularProgress from '../spinner/CircularProgress';
import VerifyOtp from './modal/VerifyOtp';
import updateTransfer from '../../../api/handler/wallet/updateTransfer';

// MAterial-ui/Core
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Material-ui/Icon
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(styles);

const Transfer = () => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const walletContext = useContext(WalletContext);
  const { wallets } = walletContext;

  // Transfer State
  const [amount, setAmount] = useState('');
  const [walletTo, setWalletTo] = useState('');
  const [narration, setNarration] = useState('');
  const [pin, setPin] = useState('');
  const [otp, setOtp] = useState('');

  // Error State
  const [isAmountErr, setAmountErr] = useState(false);
  const [isNarrationErr, setNarrationErr] = useState(false);
  const [isWalletToErr, setWalletToErr] = useState(false);

  // Alert State
  const [alert, setAlert] = useState(null);
  const removeAlert = () => {
    setAlert(null);
    // setDisplayReceipt(true);
  };

  // Verify Wallet State
  const [isDisplayVerifyBtn, displayVerifyBtn] = useState(false);

  // Verify Wallet State
  const [isVerify, setVerify] = useState(false);
  const [verifiedWallet, setVerifiedWallet] = useState(null);

  // Verify Wallet To
  const { isLoading: isVerifyingWallet } = useQuery(
    ['wallet-info', walletTo],
    () => getWalletInfo(walletTo),
    {
      enabled: !!isVerify,
      onSettled: (data, error) => {
        setVerify(false);
        if (error) {
          setAlert({
            msg: loadError(error),
            type: 'error',
          });
        }
      },
      onSuccess: (data) => {
        if (data.data.payload.length > 0) {
          setVerifiedWallet(data.data.payload[0]);
        } else {
          setAlert({
            msg: 'No wallet found',
            type: 'error',
          });
        }
      },
    }
  );

  // OTP checked State
  const [isTransferOtpEnabled, setOtpChecked] = useState(false);

  // Handle Selected Wallet
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [selectedWalletErr, setSelectedWalletErr] = useState(false);
  const handleSelectedWallet = (wallet) => {
    setSelectedWallet(wallet);
    setOtpChecked(wallet.transferOtp);
  };

  // Display Transfer Modal
  const [isDisplayTransferModal, displayTransferModal] = useState(false);
  const closeTransferModal = () => {
    displayTransferModal(false);
    setPin('');
  };

  // Display Verify OTP Modal
  const [isDisplayOtpModal, displayOtpModal] = useState(false);
  const closeOtpModal = () => {
    displayOtpModal(false);
    // Clear Local Storage
    localStorage.removeItem('user_pin');
  };

  // Receipt Modal
  const [isDisplayReceipt, setDisplayReceipt] = useState(false);
  const closeReceipt = () => {
    setDisplayReceipt(false);
    setSelectedWallet(null);
    // Clear State
    setAmount('');
    setWalletTo('');
    setVerifiedWallet(null);
  };

  // Handle Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (wallets && wallets.length > 0) {
      if (amount === '') {
        setAmountErr(true);
      } else {
        setAmountErr(false);
      }
      if (!verifiedWallet) {
        setWalletToErr(true);
      } else {
        setWalletToErr(false);
      }
      if (selectedWallet) {
        setSelectedWalletErr(false);
      } else {
        setSelectedWalletErr(true);
      }

      if (narration === '') {
        setNarrationErr(true);
      } else {
        setNarrationErr(false);
      }

      if (
        amount !== '0' &&
        verifiedWallet &&
        selectedWallet &&
        narration !== ''
      ) {
        displayTransferModal(true);
      }
    } else {
      setAlert({
        type: 'info',
        msg: 'Unable to create transfer. Please setup wallet',
      });
    }
  };

  // UseMutation Create Transfer
  const { isLoading, mutate, data } = useMutation(createTransfer, {
    onSettled: (data, error) => {
      if (error) {
        setAlert({
          msg: loadError(error),
          type: 'error',
        });
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries('transactions');
      queryClient.invalidateQueries('user-account');

      // Display Transfer Receipt or Display Verify OTP
      if (isTransferOtpEnabled) {
        localStorage.setItem('user_pin', JSON.stringify(pin));
        displayOtpModal(true);
      } else {
        setDisplayReceipt(true);
      }

      displayTransferModal(false);
      // Clear State
      setPin('');
      setOtpChecked(false);
      displayVerifyBtn(false);
    },
  });

  const processTransfer = async () => {
    const sameWallet = wallets.find(
      (account) => account.wallet === verifiedWallet.wallet
    );
    mutate({
      pin,
      amount,
      narration,
      walletFrom: selectedWallet.wallet,
      walletTo: verifiedWallet.wallet,
      trxref: `gen-${uuid()}`,
      type: sameWallet ? 'U' : 'T',
    });
  };

  // UseMutation Create Transfer
  const { isLoading: isVerifyingOtp, mutate: mutateOtp } = useMutation(
    updateTransfer,
    {
      onSettled: (data, error) => {
        if (error) {
          setAlert({
            msg: loadError(error),
            type: 'error',
          });
        }
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries('transactions');
        queryClient.invalidateQueries('user-account');

        // Clear Local Storage
        localStorage.removeItem('user_pin');

        setDisplayReceipt(true);
        // Clear State
        displayOtpModal(false);
      },
    }
  );

  // handle Verify OTP
  const handleVerifyOtp = () => {
    mutateOtp({
      details: {
        pin: JSON.parse(localStorage.getItem('user_pin')),
        status: 'APPROVE',
        otp,
      },
      id: data.data.payload.id,
    });
  };

  return (
    <Box className={classes.depositContainer}>
      {(isLoading || isVerifyingWallet || isVerifyingOtp) && (
        <CircularProgress />
      )}
      {isDisplayReceipt && (
        <TransferReceipt
          close={closeReceipt}
          amount={amount}
          walletTo={walletTo}
          walletFrom={selectedWallet}
        />
      )}
      {alert && alert.type === 'error' && (
        <Alert msg={alert.msg} type={alert.type} closeAlert={removeAlert} />
      )}
      {alert && alert.type === 'success' && (
        <SuccessAlert
          title={alert.title}
          msg={alert.msg}
          removeAlert={removeAlert}
          type={alert.type}
        />
      )}
      {alert && alert.type === 'info' && (
        <InfoAlert msg={alert.msg} type={alert.type} closeAlert={removeAlert} />
      )}

      {isDisplayTransferModal && (
        <ConfirmTransfer
          title='Confirm Transfer'
          amount={amount}
          walletTo={verifiedWallet}
          close={closeTransferModal}
          setAlert={setAlert}
          pin={pin}
          walletFrom={selectedWallet}
          setPin={setPin}
          isProcessing={isLoading}
          processTransfer={processTransfer}
        />
      )}
      {isDisplayOtpModal && (
        <VerifyOtp
          title='Enter OTP'
          close={closeOtpModal}
          id={data && data.data.payload.id}
          otp={otp}
          setAlert={setAlert}
          setOtp={setOtp}
          isProcessing={isVerifyingOtp}
          handleVerifyOtp={handleVerifyOtp}
        />
      )}
      <Box maxWidth='540px' style={{ margin: '20px auto' }}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Box>
            <Content
              style={{
                marginBottom: '30px',
              }}
              title='Specify amount'
            >
              <CustomInput
                error={isAmountErr}
                helperText={isAmountErr && 'Amount is required'}
                variant='outlined'
                fullWidth={true}
                size='small'
                type='number'
                value={amount}
                onChange={(e) => {
                  e.target.value.length > 0 && setAmountErr(false);
                  setAmount(e.target.value);
                }}
              />
            </Content>

            <Content
              style={{
                marginBottom: '30px',
              }}
              title='Select Wallet Account'
            >
              {wallets && wallets.length > 0 ? (
                <Typography
                  className={cx({ [classes.errorText]: selectedWalletErr })}
                  variant='body2'
                >
                  {selectedWalletErr && '*Select wallet to transfer from'}
                </Typography>
              ) : (
                <Typography variant='body2'>No Wallet(s)</Typography>
              )}
              <Box
                className={cx(classes.walletWrapper, {
                  [classes.walletGrid]: wallets && wallets.length > 1,
                })}
              >
                {wallets &&
                  wallets.map((wallet) => (
                    <Button
                      style={{
                        textTransform: 'capitalize',
                      }}
                      onClick={() => handleSelectedWallet(wallet)}
                      key={wallet.id}
                    >
                      <Box
                        position='relative'
                        display='flex'
                        alignItems='center'
                      >
                        {selectedWallet &&
                          selectedWallet.wallet === wallet.wallet && (
                            <Box position='absolute' right='-15px' top='-5px'>
                              <CheckCircleIcon className={classes.checked} />
                            </Box>
                          )}
                        <Box marginRight='10px'>
                          <AccountBalanceWalletIcon />
                        </Box>
                        <Box>
                          <Typography align='left' variant='body2'>
                            Wallet ID: {wallet.wallet}
                          </Typography>
                          <Typography align='left' variant='body2'>
                            Balance: &#8358;{'  '}
                            {numberWithComma(wallet ? wallet.balance : '-')}
                          </Typography>
                          <Typography align='left' variant='body2'>
                            Type:{'  '}
                            {numberWithComma(wallet ? wallet.type : '-')}
                          </Typography>
                        </Box>
                      </Box>
                    </Button>
                  ))}
              </Box>
            </Content>

            {!verifiedWallet ? (
              <Box>
                <Box marginTop='20px'>
                  <Content title='Beneficiary Wallet'>
                    <TextField
                      fullWidth={true}
                      error={isWalletToErr}
                      helperText={
                        isWalletToErr && 'Benificiary wallet is required'
                      }
                      size='small'
                      variant='outlined'
                      value={walletTo}
                      inputProps={{
                        maxLength: 10,
                        minLength: 0,
                      }}
                      onChange={(e) => {
                        if (e.target.value.length === 10) {
                          displayVerifyBtn(true);
                        } else {
                          displayVerifyBtn(false);
                        }
                        e.target.value !== '' && setWalletToErr(false);
                        setWalletTo(e.target.value);
                      }}
                    />
                  </Content>
                </Box>
                {isDisplayVerifyBtn && (
                  <Box marginTop='20px'>
                    <Button
                      onClick={() => setVerify(true)}
                      className={classes.btnBg}
                    >
                      confirm wallet
                    </Button>
                  </Box>
                )}
              </Box>
            ) : (
              <Box>
                <Content
                  style={{
                    marginBottom: '30px',
                  }}
                  title='Verified Wallet'
                  isChange={true}
                  handleChange={() => setVerifiedWallet(null)}
                >
                  <Box position='relative' display='flex' alignItems='center'>
                    <Box marginRight='10px'>
                      <VerifiedUserIcon className={classes.verifyIcon} />
                    </Box>
                    <Box>
                      <Box>
                        <Typography variant='body2'>
                          Account name: {verifiedWallet.name}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant='body2'>
                          Wallet: {verifiedWallet.wallet}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Content>
                <Content
                  style={{
                    marginBottom: '30px',
                  }}
                  title='Narration'
                >
                  <CustomInput
                    error={isNarrationErr}
                    helperText={isNarrationErr && 'Narration is required'}
                    variant='outlined'
                    fullWidth={true}
                    size='small'
                    value={narration}
                    inputProps={{
                      maxLength: 60,
                      minLength: 0,
                    }}
                    onChange={(e) => {
                      e.target.value.length > 0 && setNarrationErr(false);
                      setNarration(e.target.value);
                    }}
                  />
                </Content>
                <Box marginTop='20px'>
                  <Box marginBottom='10px' display='flex' alignItems='center'>
                    <InfoOutlinedIcon
                      style={{ fontSize: '16px', marginRight: '3px' }}
                    />
                    <Typography
                      style={{ lineHeight: 'initial' }}
                      variant='body2'
                    >
                      Processing fees apply to all transfers.
                    </Typography>
                  </Box>
                  <Button type='submit' className={classes.btnBg}>
                    Transfer{' '}
                    <span style={{ marginLeft: '10px' }}>
                      &#8358;{amount !== '' ? numberWithComma(amount) : '0'}
                    </span>
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Transfer;
