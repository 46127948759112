import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../../context/auth/authContext';
import WalletContext from '../../../context/wallet/walletContext';
import styles from './styles';

// Material-ui/COre
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from '@material-ui/lab/Skeleton';

// Material-ui/Icon
import HistoryIcon from '@material-ui/icons/History';

const useStyles = makeStyles(styles);

const User = () => {
	const classes = useStyles();
	const authContext = useContext(AuthContext);
	const walletContext = useContext(WalletContext);
	const { user, authError } = authContext;
	const { walletErr } = walletContext;

	// Handle Error
	const [errorMsg, setErrorMsg] = useState(null);
	useEffect(
		() => {
			if (authError || walletErr) {
				setErrorMsg(authError || walletErr);
			}
		},
		// eslint-disable-next-line
		[authError, walletErr]
	);

	return (
		<Box style={{ margin: '50px 0px' }} className={classes.container}>
			<Box className={classes.content}>
				<Box display='flex' alignItems='center'>
					<Box marginRight='25px'>
						<Avatar className={classes.avatar} />
					</Box>
					<Box
						width='100%'
						display='flex'
						justifyContent='space-between'
						alignItems='flex-start'
					>
						<Box>
							<Typography
								style={{ display: 'flex' }}
								className={classes.userText}
							>
								Hello
								{user ? (
									` ${user.surname} ${user.otherName.charAt(0)}.`
								) : errorMsg ? (
									' -'
								) : (
									<Skeleton
										style={{ width: '200px', marginLeft: '10px' }}
										variant='text'
									/>
								)}
							</Typography>
							<Typography
								className={classes.smallText}
								variant='body2'
								style={{ display: 'flex' }}
							>
								Last login:{' '}
								{user ? (
									`${new Date(user.lastLogin).toDateString()}`
								) : errorMsg ? (
									' -'
								) : (
									<Skeleton
										style={{ width: '100px', marginLeft: '10px' }}
										variant='text'
									/>
								)}
							</Typography>
						</Box>
						<Hidden implementation='css' smDown>
							<Box>
								<Link
									to='/transactions'
									className={classes.link}
									style={{
										cursor: 'pointer',
										userSelect: 'none',
										display: 'flex',
										textDecoration: 'none',
									}}
								>
									<Typography style={{ marginRight: '5px' }} variant='body1'>
										History
									</Typography>
									<HistoryIcon />
								</Link>
							</Box>
						</Hidden>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default User;
