import axios from 'axios';
import setTokenHeader from '../../setTokenInHeader';

import { walletAPI } from '../..';

const getWalletToCreateAutoTransfer = async () => {
	await setTokenHeader();
	return await axios.get(`${walletAPI}/accounts/auto-transfer`);
};

export default getWalletToCreateAutoTransfer;
