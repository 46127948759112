import React, { useState } from 'react';
import { useQuery } from 'react-query';
import styles from './styles';
import getTransaction from '../../../api/handler/wallet/getTransactions';
import Spinner from '../spinner/Spinner';
import TransactionItem from './TransactionItem';
import loadError from '../../../api/loadErr';
import ToastAlert from '../alert/ToastAlert';

// Material-ui/Core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// Material-ui/Icon
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles(styles);

const AllTransactions = () => {
  const classes = useStyles();

  // Alert State
  const [alert, setAlert] = useState(null);
  const removeAlert = () => setAlert(null);

  // Transaction
  const [transactions, setTransactions] = useState(null);

  /*   const { isLoading, error } = useQuery('transactions', getTransaction, {
    onSettled: (data, error) => {
      if (error) {
        setAlert({
          msg: loadError(error),
          type: 'error',
        });
      }
    },
    onSuccess: (data) => {
      if (data) {
        const deposits = data[0].data.payload.map((value) => ({
          ...value,
          transactionType: 'deposit',
        }));
        const transfers = data[1].data.payload.map((value) => ({
          ...value,
          transactionType: 'transfer',
        }));
        const withdraws = data[2].data.payload.map((value) => ({
          ...value,
          transactionType: 'withdraw',
        }));

        const transactions = [...deposits, ...transfers, ...withdraws];

        setTransactions(
          transactions.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )
        );
      }
    },
  }); */

  const { isLoading, error } = useQuery('transactions', getTransaction, {
    onSettled: (data, error) => {
      if (error) {
        setAlert({
          msg: loadError(error),
          type: 'error',
        });
      }
    },
    onSuccess: (data) => {
      if (data.data.success) {
        setTransactions(data.data.payload.payload);
      }
    },
  });

  if (error) {
    return (
      <Box className={classes.transactionContainer}>
        {alert && (
          <ToastAlert
            msg={alert.msg}
            type={alert.type}
            closeAlert={removeAlert}
          />
        )}
        <Box
          marginTop='20px'
          display='flex'
          alignItems='center'
          justifyContent='center'
          flexDirection='column'
        >
          <ErrorIcon
            style={{ fontSize: '50px' }}
            className={classes.errorText}
          />
          <Typography className={classes.errorText} variant='h6'>
            {loadError(error)}
          </Typography>
        </Box>
      </Box>
    );
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box className={classes.transactionContainer}>
      <Box marginTop='20px'>
        {transactions &&
          transactions.map((transaction, key) => (
            <TransactionItem transaction={transaction} key={key} />
          ))}
      </Box>
    </Box>
  );
};

export default AllTransactions;
