import {
  btn,
  container,
  grayColor,
  primaryColor,
  whiteColor,
  secColor,
  blueColor,
  defaultBoxShadow,
  link,
} from '../../assets/js/styleCss';

const styles = () => ({
  container: {
    ...container,
    padding: '10px 15px',
    marginTop: '0px',
  },
  gridItem: {
    ...defaultBoxShadow,
    backgroundColor: 'rgba(250, 250, 250, 0.8)',
    position: 'relative',

    borderRadius: '5px',
    margin: '40px auto !important',
  },
  headerText: {
    textAlign: 'center',
    flex: '1',
    color: secColor,
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },

  cardHidden: {
    opacity: '0',
    transform: 'translate3d(0, -60px, 0)',
  },
  showcase: {
    backgroundColor: primaryColor,
  },
  title: {
    textAlign: 'center',
    color: primaryColor,
    marginBottom: '20px',
    textTransform: 'uppercase',
  },
  text: {
    textAlign: 'center',
    margin: '15px 0px',
    color: grayColor[6],
    '& span': {
      color: primaryColor,
    },
  },
  overlay: {
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '0px',
      top: '0px',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(250, 250, 250, 0.5)',
      zIndex: '2000',
    },
  },
  linearProgress: {
    color: `${primaryColor} !important`,
    zIndex: '2200',
  },
  btn: {
    ...btn,
    position: 'relative',
    color: whiteColor,
    backgroundColor: secColor,
    width: '100%',
    '&:hover': {
      backgroundColor: secColor,
      opacity: '0.8',
    },
  },
  btnInline: {
    ...btn,
    position: 'relative',
    color: whiteColor,
    textTransform: 'capitalize',
    backgroundColor: primaryColor,
    '&:hover': {
      backgroundColor: primaryColor,
      opacity: '0.8',
    },
  },
  option: {
    textAlign: 'center',
    width: '70%',
    margin: '20px auto',
    color: grayColor[6],
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: '0px',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '45%',
      height: '2px',
      backgroundColor: grayColor[9],
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      right: '0px',
      top: '50%',
      transform: 'translateY(-50%)',
      width: '45%',
      height: '2px',
      backgroundColor: grayColor[9],
    },
  },
  link: {
    color: primaryColor,
    textDecoration: 'none',
    listStyle: 'none',
    fontSize: '14px',
    '&:hover': {
      textDecoration: 'underline !important',
    },
  },
  inlineLink: {
    color: grayColor[6],
    textDecoration: 'none',
    listStyle: 'none',
    fontSize: '14px',
    '&:hover': {
      textDecoration: 'underline !important',
    },
  },
  visibilityIcon: {
    color: grayColor[6],
    fontSize: '17px',
    cursor: 'pointer',
  },
  svgIcon: {
    minWidth: 'initial !important',
    padding: '8px',
    borderRadius: '50%',
    margin: '0 15px',
    color: primaryColor,
    ...defaultBoxShadow,
  },
  blueColor: {
    color: blueColor,
  },
  skyblue: {
    color: 'skyblue',
  },
  formIcon: {
    fontSize: '16px',
    color: grayColor[6],
  },
  circularProgress: {
    position: 'absolute',
    height: '30px !important',
    width: '30px !important',
    color: whiteColor,
  },
  forgetPassword: {
    ...link,
    color: secColor,
    marginTop: '5px',
    display: 'flex',
    justifyContent: 'flex-end',
    '&:hover': {
      textDecoration: 'underline !important',
    },
  },
});

export default styles;
