import axios from 'axios';
import setTokenHeader from '../../setTokenInHeader';

import { walletAPI } from '../..';

const updateTransfer = async (value) => {
	await setTokenHeader();
	const { details, id } = value;

	return await axios.put(`${walletAPI}/transfers/${id}`, details);
};

export default updateTransfer;
