export const env = process.env.REACT_APP_BASE_API;

export const loginAPIs = [
	{
		path: 'erp/staff',
		type: 'S',
		name: 'STAFF',
		url: `${env}/erp/staff`,
	},
	{
		path: 'crm/customers',
		type: 'C',
		name: 'PEACE CUSTOMER',
		url: `${env}/crm/customers`,
	},
];

export const walletAPI = `${process.env.REACT_APP_BASE_API}/ewallet`;
