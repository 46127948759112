import axios from 'axios';
import setTokenHeader from '../../setTokenInHeader';

import { walletAPI } from '../..';

const createWallet = async (details) => {
	await setTokenHeader();
	const payload = {
		...details,
	};
	return await axios.post(`${walletAPI}/accounts`, payload);
};

export default createWallet;
