import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../../context/auth/authContext';
import { Link } from 'react-router-dom';
import CustomInput from '../../layouts/customComponent/CustomInput';
import cx from 'classnames';
import styles from './styles';
import Alert from '../../layouts/alert/ToastAlert';
import logo from '../../assets/img/peace-logo.png';

// Material-UI/Core
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

// Material-UI/Icon
import LockIcon from '@material-ui/icons/Lock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(styles);

const VerifyOtp = () => {
	const classes = useStyles();
	const authContext = useContext(AuthContext);
	const { loginUser, errorMessage, requestOtp } = authContext;
	const history = useHistory();

	const [otp, setOtp] = useState('');
	const [otpErr, setOtpErr] = useState(null);

	// Target Pasword Input
	const [isDisplayOtp, setIsDisplayOtp] = useState(false);

	// Toggle Password
	const togglePassword = () => {
		setIsDisplayOtp(!isDisplayOtp);
	};

	//Alert State
	const [alert, setAlert] = useState(null);
	const removeAlert = () => setAlert(null);

	useEffect(() => {
		if (errorMessage) {
			setAlert({ msg: errorMessage, type: 'error' });
			setIsProcessing(false);
		}
	}, [errorMessage]);

	const [isProcessing, setIsProcessing] = useState(false);
	const handleLogin = async (e) => {
		e.preventDefault();
		if (otp === '') {
			setOtpErr('OTP Is require');
		}

		if (otp !== '') {
			setIsProcessing(true);
			const userContact = JSON.parse(localStorage.getItem('otp-contact'));
			if (!userContact) {
				setAlert({ type: 'error', msg: 'Invalid user contact' });
			}
			const res = await loginUser({ ...userContact, otp });
			console.log(res);
			if (res) {
				if (res.success) {
					setIsProcessing(false);
					history.push('/auth/update-password');
				}
			}
		}
	};

	const resendOtp = async () => {
		const userDetail = JSON.parse(localStorage.getItem('otp-contact'));
		if (userDetail) {
			const res = await requestOtp(userDetail);
			if (res) {
				if (res.success) {
					setAlert({
						type: 'success',
						msg: `${res.message}, Verify OTP.`,
					});
				}
			}
		}
	};

	return (
		<>
			{alert && (
				<Alert msg={alert.msg} type={alert.type} closeAlert={removeAlert} />
			)}
			<Box
				height='100vh'
				display='flex'
				alignContent='center'
				justifyContent='center'
				className={classes.container}
			>
				<Grid container justify='center' alignItems='center'>
					<Grid item xs={11} sm={6} md={6} lg={5}>
						<Box
							height='400px'
							border='1px solid rgba(0, 0, 0, 0.1)'
							padding='20px'
							borderRadius='5px'
						>
							<Box margin='auto' marginBottom='20px' width='50px' height='50px'>
								<img style={{ width: '100%' }} src={logo} alt='peace-logo' />
							</Box>
							<Box marginBottom='30px'>
								<Typography paragraph align='center' variant='h6'>
									Verify OTP
								</Typography>
								<Typography
									color='textSecondary'
									align='center'
									variant='body2'
								>
									Enter the OTP sent to phone number or email address
								</Typography>
							</Box>
							<form
								onSubmit={handleLogin}
								className={cx(classes.formContainer)}
							>
								<CustomInput
									style={{ marginBottom: '20px' }}
									label='Otp'
									variant='outlined'
									value={otp}
									error={otpErr ? true : false}
									helperText={otpErr}
									type={isDisplayOtp ? 'text' : 'password'}
									fullWidth={true}
									startAdornment={
										<InputAdornment position='start'>
											<LockIcon className={classes.formIcon} />
										</InputAdornment>
									}
									endAdornment={
										<InputAdornment position='end'>
											{isDisplayOtp ? (
												<VisibilityOffIcon
													className={classes.visibilityIcon}
													onClick={() => togglePassword()}
												/>
											) : (
												<VisibilityIcon
													className={classes.visibilityIcon}
													onClick={() => togglePassword()}
												/>
											)}
										</InputAdornment>
									}
									onChange={(e) => setOtp(e.target.value)}
								/>
								<Box display='flex' justifyContent='flex-end'>
									<Button
										disabled={isProcessing}
										type='submit'
										className={classes.btnInline}
									>
										{isProcessing && (
											<CircularProgress className={classes.circularProgress} />
										)}
										Verify OTP
									</Button>
								</Box>
							</form>
							<Typography className={classes.text} variant='body2' paragraph>
								Didn't get OTP?
								<Link
									style={{ marginLeft: '10px' }}
									className={classes.link}
									to='/resend-otp'
									onClick={(e) => {
										e.preventDefault();
										resendOtp();
									}}
								>
									Resend OTP.
								</Link>
							</Typography>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default VerifyOtp;
