import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../../../../context/auth/authContext';
import WalletContext from '../../../../context/wallet/walletContext';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import TextField from '../../customComponent/CustomInput';
import Alert from '../../alert/SuccessAlert';
import Layout from '../../Layout';
import loadError from '../../../../api/loadErr';
import ToastAlert from '../../alert/ToastAlert';
import searchUser from '../../../../api/handler/wallet/searchUser';
import updateUserInWallet from '../../../../api/handler/wallet/updateUsersInWallet';
import Loader from '../../spinner/CircularProgress';
import ConfirmUpdate from './ConfirmUpdate';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

// Material-ui/Core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

// Material-ui/Icon
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CheckCircle from '@material-ui/icons/CheckCircle';

import {
  whiteColor,
  grayColor,
  secColor,
  defaultBoxShadow,
  dangerColor,
} from '../../../assets/js/styleCss';

const useStyles = makeStyles({
  grid: {
    '& .MuiGrid-item': {
      margin: 'auto',
    },
  },
  displayFlex: {
    display: 'flex',
    flexDirection: 'column',
    '@media (min-width: 700px)': {
      flexDirection: 'row',
    },
  },
  btn: {
    color: whiteColor,
    backgroundColor: secColor,
    minWidth: '100%',
    display: 'block',
    alignSelf: 'flex-start',
    '@media (min-width: 700px)': {
      minWidth: '100px',
      marginLeft: '20px',
    },
    '&:hover': {
      backgroundColor: secColor,
      opacity: '0.8',
    },
  },
  btnBlock: {
    color: whiteColor,
    backgroundColor: secColor,
    minWidth: '100%',
    display: 'block',
    alignSelf: 'flex-start',
    '@media (min-width: 700px)': {
      minWidth: '100px',
    },
    '&:hover': {
      backgroundColor: secColor,
      opacity: '0.8',
    },
  },
  btnBlockDel: {
    color: whiteColor,
    backgroundColor: dangerColor[0],
    minWidth: '100%',
    display: 'block',
    alignSelf: 'flex-start',
    '@media (min-width: 700px)': {
      minWidth: '100px',
    },
    '&:hover': {
      backgroundColor: dangerColor[0],
      opacity: '0.8',
    },
  },
  btnDel: {
    color: whiteColor,
    backgroundColor: dangerColor[0],
    display: 'block',
    alignSelf: 'flex-start',
    '@media (min-width: 700px)': {
      minWidth: '100px',
    },
    '&:hover': {
      backgroundColor: dangerColor[0],
      opacity: '0.8',
    },
  },
  infoText: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
    '& .MuiSvgIcon-root': {
      marginRight: '5px',
      fontSize: '18px',
    },
  },
  contactWrapper: {
    ...defaultBoxShadow,
    padding: '15px',
    borderRadius: '5px',
    marginTop: '30px',
  },
  headerText: {
    color: secColor,
    marginBottom: '5px',
  },
  svgIcon: {
    color: secColor,
    fontSize: '14px',
    marginRight: '5px',
  },
  visibilityIcon: {
    color: grayColor[6],
    fontSize: '17px',
    cursor: 'pointer',
  },
});

const AddUserToWallet = (props) => {
  const classes = useStyles();

  // Wallet ID
  const walletId = props.match.params.id;
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const walletContext = useContext(WalletContext);
  const { wallets } = walletContext;

  // Load and Match User
  const [walletUsers, setWalletUsers] = useState(null);
  useEffect(
    () => {
      if (wallets && wallets.length > 0) {
        const matchWallet = wallets.find(
          (wallet) => wallet.wallet === walletId
        );

        matchWallet && setWalletUsers(matchWallet.users);
      }
    },
    // eslint-disable-next-line
    [wallets, walletId]
  );

  const [adminUser, setAdminUser] = useState(null);

  // Check for Admin
  useEffect(
    () => {
      if (wallets) {
        setAdminUser(
          wallets[0].users.filter(
            (walletUser) => walletUser.id === wallets[0].admin
          )[0]
        );
      }
    },
    // eslint-disable-next-line
    [wallets, user.id]
  );

  //Alert State
  const [alert, setAlert] = useState(null);
  const removeAlert = () => {
    setAlert(null);
    // history.goBack();
  };
  const removeToastAlert = () => {
    setAlert(null);
  };

  // state
  const [contact, setContact] = useState('');
  const [isContactErr, setContactErr] = useState(false);
  const [isFindUser, setFindUser] = useState(false);
  const [pin, setPin] = useState('');
  const [foundUser, setFoundUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [payload, setPayload] = useState([]);
  const [action, setAction] = useState('');
  const [responseMsg, setResponseMsg] = useState('');

  const clearState = () => {
    setPayload([]);
    setAction('');
    setResponseMsg('');
    setFoundUser(null);
    setFindUser(false);
    setUsers([]);
    setContact('');
    setDisplayModal(false);
    setPin('');
  };

  const [isDisplayModal, setDisplayModal] = useState(false);
  const closeModal = () => {
    setDisplayModal(false);
    setPin('');
  };

  // Mutation
  const { isLoading: isGettingUser } = useQuery(
    'add-user',
    () => searchUser(contact),
    {
      enabled: isFindUser,
      onSettled: (data, error) => {
        if (error) {
          setAlert({
            msg: loadError(error),
            type: 'error',
          });
        }
        setFindUser(false);
      },
      onSuccess: (data) => {
        // queryClient.invalidateQueries('user-account');
        if (data.data && !Array.isArray(data.data.payload)) {
          setFoundUser(data.data.payload);
          setAlert({
            msg: 'Contact retrieve successfully!',
            type: 'get',
          });
        } else {
          setAlert({
            type: 'error',
            msg: 'Contact not found',
          });
        }
      },
    }
  );

  // Mutation
  const queryClient = useQueryClient();
  const { isLoading: isHandlingSubmit, mutate } = useMutation(
    updateUserInWallet,
    {
      enabled: isFindUser,
      onSettled: (data, error) => {
        if (error) {
          console.log('error');
          setAlert({
            msg: loadError(error),
            type: 'error',
          });
        }
        setFindUser(false);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries('user-account');
        if (data.data && data.data.payload.length > 0) {
        }
        clearState();
        setAlert({
          title: 'Wallet Message',
          msg: responseMsg,
          type: 'success',
        });
      },
    }
  );

  // Handle Search
  const handleSearch = (e) => {
    e.preventDefault();
    contact === '' ? setContactErr(true) : setContactErr(false);
    if (contact !== '') {
      setFindUser(true);
    }
  };

  const addUser = (foundUser) => {
    setAction('ADD');
    setResponseMsg('User added successfully');
    const isFound = payload.find((value) => value === foundUser.id);
    if (!isFound) {
      if (
        foundUser.enterprise === user.enterprise &&
        foundUser.userType === user.userType
      ) {
        setPayload([...payload, foundUser.id]);
        setUsers([...users, foundUser]);
        setFoundUser(null);
      } else {
        setAlert({
          msg: `Unable to add user "${foundUser.surname} ${foundUser.otherName}", user does not belong to you category`,
          type: 'error',
        });
      }
    } else {
      setAlert({
        msg: 'User already selected',
        type: 'info',
      });
    }
  };

  // Remove User
  const removeUser = (data) => {
    setUsers(users.filter((user) => user.id !== data.id));
    setPayload(payload.filter((id) => id !== data.id));
  };

  // Form Submit
  const handleSubmit = async () => {
    mutate({
      payload: {
        pin,
        users: payload,
        action,
      },
      id: walletId,
    });
  };

  const handleRemoveUser = (user) => {
    setPayload([user.id]);
    setAction('REMOVE');
    setResponseMsg('User remove successfully');

    // setPayload()
    setDisplayModal(true);
  };

  return (
    <Layout title='Add user to wallet'>
      {isDisplayModal && (
        <ConfirmUpdate
          pin={pin}
          setPin={setPin}
          isProcessing={isHandlingSubmit}
          confirmUpdate={handleSubmit}
          close={closeModal}
        />
      )}
      {isGettingUser && <Loader />}
      {alert && alert.type === 'success' && (
        <Alert
          title={alert.title}
          msg={alert.msg}
          type={alert.type}
          removeAlert={removeAlert}
        />
      )}
      {alert && alert.type === 'get' && (
        <ToastAlert
          msg={alert.msg}
          type='success'
          closeAlert={removeToastAlert}
        />
      )}
      {alert && alert.type === 'error' && (
        <ToastAlert
          msg={alert.msg}
          type={alert.type}
          closeAlert={removeToastAlert}
        />
      )}
      {alert && alert.type === 'info' && (
        <ToastAlert
          msg={alert.msg}
          type={alert.type}
          closeAlert={removeToastAlert}
        />
      )}
      <Box flex='1' display='flex' padding='15px' flexDirection='column'>
        <Grid className={classes.grid} container>
          <Grid item xs={12} sm={7} md={6}>
            <Box marginTop='20px'>
              <Typography variant='body2' className={classes.infoText}>
                <InfoOutlinedIcon /> Search user via phone
              </Typography>
              <Box className={classes.displayFlex} marginTop='5px'>
                <TextField
                  error={isContactErr}
                  helperText={isContactErr && 'Please specify phone number'}
                  fullWidth={true}
                  label='phone'
                  variant='outlined'
                  size='small'
                  value={contact}
                  onChange={(e) => {
                    setContact(e.target.value);
                  }}
                />
                <Button
                  onClick={(e) => handleSearch(e)}
                  className={classes.btn}
                >
                  search
                </Button>
              </Box>
            </Box>

            {foundUser && (
              <Box className={classes.contactWrapper}>
                <Typography className={classes.headerText} variant='h5'>
                  Contact Details
                </Typography>
                <Box>
                  <Box marginBottom='5px'>
                    Name: {`${foundUser.surname} ${foundUser.otherName}`}
                  </Box>
                  <Box marginBottom='5px'>Email: {foundUser.email}</Box>
                  <Box>Phone: {foundUser.phone}</Box>
                </Box>
                <Box
                  display='grid'
                  gridTemplateColumns='repeat(2, 1fr)'
                  gridGap='10px'
                  marginTop='15px'
                >
                  <Button
                    onClick={() => addUser(foundUser)}
                    size='small'
                    className={classes.btnBlock}
                  >
                    Add user
                  </Button>
                  <Button
                    onClick={() => setFoundUser(null)}
                    size='small'
                    className={classes.btnBlockDel}
                  >
                    remove
                  </Button>
                </Box>
              </Box>
            )}

            {users.length > 0 && (
              <Box marginTop='30px'>
                <Typography className={classes.headerText} variant='h5'>
                  Selected User{users.length > 1 ? 's' : ''}
                </Typography>
                <List style={{ padding: '0px' }}>
                  {users.map((user) => (
                    <ListItem
                      key={user.id}
                      style={{
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <span
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <CheckCircle className={classes.svgIcon} />
                        {`${user.surname} ${user.otherName}`}
                      </span>
                      <Button onClick={() => removeUser(user)}>remove</Button>
                    </ListItem>
                  ))}
                </List>
                <Button
                  style={{ marginTop: '20px' }}
                  className={classes.btnBlock}
                  onClick={() => setDisplayModal(true)}
                >
                  Save Changes
                </Button>
              </Box>
            )}

            {walletUsers && walletUsers.length > 0 && (
              <Box margin='3rem 0px'>
                <Typography className={classes.headerText} variant='h5'>
                  Added User
                </Typography>
                {walletUsers.map((user) => (
                  <Box
                    display='flex'
                    alignItems='center'
                    justifyContent='space-between'
                    key={user.id}
                    marginBottom='10px'
                  >
                    <Typography variant='body2'>
                      {`${user.surname} ${user.otherName} ${
                        adminUser?.id === user.id ? '(Admin)' : ''
                      }`}
                    </Typography>
                    <Button
                      onClick={() => handleRemoveUser(user)}
                      style={{
                        textTransform: 'capitalize',
                      }}
                      size='small'
                      className={classes.btnDel}
                    >
                      Remove user
                    </Button>
                  </Box>
                ))}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default AddUserToWallet;
