import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import Chart from 'react-apexcharts';
import cx from 'classnames';
import ToastAlert from '../alert/ToastAlert';
import loadError from '../../../api/loadErr';
import getTransaction from '../../../api/handler/wallet/getTransactions';

// Material-ui/Core
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';

// Material-ui/Icon
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { transition } from '../../assets/js/styleCss';

const useStyles = makeStyles({
  container: {
    '& .apexcharts-toolbar': {
      display: 'none',
    },
  },
  expandIcon: {
    ...transition,
  },
  rotateIcon: {
    transform: 'rotate(-180deg)',
  },
});

const Index = () => {
  const classes = useStyles();
  // Chart Data
  const chart_data = ['', '', '', '', '', '', '', '', '', '', '', ''];

  // Transactions
  const [deposits, setDeposits] = useState(null);
  const [transfers, setTransfers] = useState(null);
  const [withdraws, setWithdraws] = useState(null);

  const [isDisplay, setDisplay] = useState(true);
  const toggleExpand = () => setDisplay(!isDisplay);

  // Alert State
  const [alert, setAlert] = useState(null);
  const removeAlert = () => setAlert(null);

  const expandIcon = cx(classes.expandIcon, {
    [classes.rotateIcon]: isDisplay,
  });

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const generateData = (value) => {
    const chart_data = ['', '', '', '', '', '', '', '', '', '', '', ''];
    const month_set = value.map(
      (value) => months[new Date(value.createdAt).getUTCMonth()]
    );
    const filterMonth = month_set.reduce((obj, i) => {
      obj[i] = (obj[i] || 0) + 1;
      return obj;
    }, {});

    Object.keys(filterMonth).map(
      (value) => (chart_data[months.indexOf(value)] = filterMonth[value])
    );

    return chart_data;
  };

  /*   useQuery(['chart-transactions', wallets], getTransactions, {
    enabled: !!wallets && wallets.length > 0,
    onSettled: (data, error) => {
      if (error) {
        setAlert({
          msg: loadError(error),
          type: 'error',
        });
      }
      if (data) {
        const deposits = data[0].data.payload.map((value) => ({
          ...value,
          transactionType: 'deposit',
        }));
        const transfers = data[1].data.payload.map((value) => ({
          ...value,
          transactionType: 'transfer',
        }));
        const withdraws = data[2].data.payload.map((value) => ({
          ...value,
          transactionType: 'withdraw',
        }));
        setDeposits(deposits);
        setTransfers(transfers);
        setWithdraws(withdraws);
        updateTransactions(deposits, transfers, withdraws);
      }
    },
  }); */

  useQuery('transactions', getTransaction, {
    onSettled: (data, error) => {
      if (error) {
        setAlert({
          msg: loadError(error),
          type: 'error',
        });
      }
    },
    onSuccess: (data) => {
      if (data.data.success) {
        setTransfers(
          data.data.payload.payload.filter((value) => value.transfer)
        );
        setDeposits(data.data.payload.payload.filter((value) => value.deposit));
        setWithdraws(
          data.data.payload.payload.filter((value) => value.withdraw)
        );
      }
    },
  });

  // Update State
  useEffect(
    () => {
      if (deposits || withdraws || transfers) {
        setData({
          ...data,
          series: [
            {
              name: 'Deposit',
              data: deposits ? generateData(deposits) : chart_data,
            },
            {
              name: 'Transfer',
              data: transfers ? generateData(transfers) : chart_data,
            },
            {
              name: 'Withdraw',
              data: withdraws ? generateData(withdraws) : chart_data,
            },
          ],
        });
      }
    },
    // eslint-disable-next-line
    [deposits, withdraws, transfers]
  );

  const [data, setData] = useState({
    series: [
      {
        name: 'Deposit',
        data: [''],
      },
      {
        name: 'Transfer',
        data: [''],
      },
      {
        name: 'Withdraw',
        data: [''],
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '60%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      xaxis: {
        categories: months,
      },
      yaxis: {
        title: {
          text: 'transactions',
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + ' transactions';
          },
        },
      },
    },
  });
  return (
    <>
      {alert && (
        <ToastAlert
          msg={alert.msg}
          type={alert.type}
          closeAlert={removeAlert}
        />
      )}
      <Box marginTop='20px'>
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <Typography>My Statistics</Typography>
          <IconButton onClick={() => toggleExpand()}>
            <ExpandMoreIcon className={expandIcon} />
          </IconButton>
        </Box>
        <Collapse in={isDisplay}>
          <Chart
            className={classes.container}
            options={data.options}
            series={data.series}
            type='bar'
            width={'100%'}
            height={320}
          />
        </Collapse>
      </Box>
    </>
  );
};

export default Index;
