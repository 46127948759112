import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import TextField from '../customComponent/CustomInput';
import Alert from '../alert/SuccessAlert';
import Layout from '../Layout';
import updateWalletPin from '../../../api/handler/wallet/updateWalletPin';
import ToastAlert from '../alert/ToastAlert';

// Material-ui/Core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

// Material-ui/Icon
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { whiteColor, grayColor, secColor } from '../../assets/js/styleCss';
import loadError from '../../../api/loadErr';

const useStyles = makeStyles({
	grid: {
		'& .MuiGrid-item': {
			margin: 'auto',
		},
	},
	btn: {
		color: whiteColor,
		backgroundColor: secColor,
		width: '100%',
		'&:hover': {
			backgroundColor: secColor,
			opacity: '0.8',
		},
	},
	infoText: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '15px',
		'& .MuiSvgIcon-root': {
			marginRight: '5px',
			fontSize: '18px',
		},
	},

	visibilityIcon: {
		color: grayColor[6],
		fontSize: '17px',
		cursor: 'pointer',
	},
});

const SetupWalletPin = (props) => {
	const classes = useStyles();
	const history = useHistory();

	//Alert State
	const [alert, setAlert] = useState(null);
	const removeAlert = () => {
		setAlert(null);
		history.push('/');
	};
	const removeToastAlert = () => {
		setAlert(null);
	};

	// Create Wallet State
	const [walletDetails, setWalletDetails] = useState({
		pin: '',
		confirmPin: '',
	});

	const { pin, confirmPin } = walletDetails;

	// Input Err State
	const [pinErr, setPinErr] = useState(null);
	const [confirmPinErr, setConfirmPinErr] = useState(null);

	// Visibility Pin State
	const [isDisplayPin, setDisplayPin] = useState(false);
	const [isDisplayConfirmPin, setDisplayConfirmPin] = useState(false);

	const togglePin = () => setDisplayPin(!isDisplayPin);
	const toggleConfirmPin = () => setDisplayConfirmPin(!isDisplayConfirmPin);

	// React-Query
	const queryClient = useQueryClient();
	const { isLoading, mutate } = useMutation(updateWalletPin, {
		onSettled: (data, error) => {
			if (error) {
				setAlert({
					msg: loadError(error),
					type: 'error',
				});
			}
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries('user-account');
			setAlert({
				title: 'Success',
				msg: 'Wallet created successfully!',
				type: 'success',
			});
		},
	});

	// Form Submit
	const handleSubmit = async (e) => {
		e.preventDefault();
		const walletId = props.match.params.id;

		const regExp = /[\d]{5}/;

		if (!regExp.test(parseInt(pin))) {
			setPinErr('Pin must be a number');
		} else {
			setPinErr(null);
		}
		if (confirmPin === '') {
			setConfirmPinErr('Confirm pin must be a number');
		} else if (pin !== confirmPin) {
			setConfirmPinErr('Pin do not match');
		} else {
			setConfirmPinErr(null);
		}

		// Submit Form
		if (regExp.test(parseInt(pin)) && pin === confirmPin) {
			mutate({ pin, wallet: walletId });
		}
	};

	return (
		<Layout title='Set wallet pin'>
			{alert && alert.type === 'success' && (
				<Alert
					title={alert.title}
					msg={alert.msg}
					type={alert.type}
					removeAlert={removeAlert}
				/>
			)}
			{alert && alert.type === 'error' && (
				<ToastAlert
					msg={alert.msg}
					type={alert.type}
					closeAlert={removeToastAlert}
				/>
			)}
			<Box flex='1' display='flex' padding='15px' flexDirection='column'>
				<Grid className={classes.grid} container>
					<Grid item xs={12} sm={7} md={5}>
						<Box marginTop='20px'>
							<Typography variant='body2' className={classes.infoText}>
								<InfoOutlinedIcon /> Specify wallet pin (5 digits number)
							</Typography>
							<form onSubmit={(e) => handleSubmit(e)}>
								<Box marginBottom='15px'>
									<TextField
										size='small'
										variant='outlined'
										label='Pin'
										type={isDisplayPin ? 'number' : 'password'}
										error={pinErr && true}
										helperText={pinErr}
										inputProps={{
											maxLength: 5,
											minLength: 0,
										}}
										fullWidth={true}
										value={pin}
										endAdornment={
											<InputAdornment position='end'>
												<IconButton size='small' onClick={() => togglePin()}>
													{isDisplayPin ? (
														<VisibilityOffIcon
															className={classes.visibilityIcon}
														/>
													) : (
														<VisibilityIcon
															className={classes.visibilityIcon}
														/>
													)}
												</IconButton>
											</InputAdornment>
										}
										onChange={(e) => {
											e.target.value !== '' && setPinErr(null);
											setWalletDetails({
												...walletDetails,
												pin: e.target.value,
											});
										}}
									/>
								</Box>
								<Box marginBottom='15px'>
									<TextField
										size='small'
										variant='outlined'
										label='Confirm pin'
										type={isDisplayConfirmPin ? 'number' : 'password'}
										error={confirmPinErr && true}
										helperText={confirmPinErr}
										fullWidth={true}
										value={confirmPin}
										inputProps={{
											maxLength: 5,
											minLength: 0,
										}}
										endAdornment={
											<InputAdornment position='end'>
												<IconButton
													size='small'
													onClick={() => toggleConfirmPin()}
												>
													{isDisplayConfirmPin ? (
														<VisibilityOffIcon
															className={classes.visibilityIcon}
														/>
													) : (
														<VisibilityIcon
															className={classes.visibilityIcon}
														/>
													)}
												</IconButton>
											</InputAdornment>
										}
										onChange={(e) => {
											e.target.value !== '' && setConfirmPinErr(null);
											setWalletDetails({
												...walletDetails,
												confirmPin: e.target.value,
											});
										}}
									/>
								</Box>
								<Button
									disabled={isLoading}
									type='submit'
									className={classes.btn}
								>
									Submit
								</Button>
							</form>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Layout>
	);
};

export default SetupWalletPin;
