import React from 'react';
import classNames from 'classnames';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import {
	transition,
	whiteColor,
	lightSecColor,
} from '../../assets/js/styleCss';

const useStyles = makeStyles({
	input: {
		...transition,
		'& .MuiInput-underline:after': {
			borderBottom: `2px solid ${lightSecColor}`,
		},
		'& .MuiInputLabel-root.Mui-focused': {
			color: `${lightSecColor} !important`,
		},
		'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: `rgba(0, 0, 0, 0.23) !important`,
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: `${lightSecColor} !important`,
		},

		'& .MuiInputLabel-formControl': {
			top: '0px',
		},
		'& .MuiInputLabel-formControl.Mui-focused': {
			top: '1px !important',
		},
		'& .MuiOutlinedInput-multiline': {
			padding: '0px !important',
		},
	},
	alignCenter: {
		'& .MuiOutlinedInput-input': {
			textAlign: 'center',
		},
	},
	noBorder: {
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			border: `none !important`,
		},
		'& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
			border: `none !important`,
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
		'& .PrivateNotchedOutline-root-92': {
			border: 'none !important',
		},
	},
	largeFont: {
		'& .MuiOutlinedInput-input': {
			fontSize: '40px',
		},
	},
	backgroundColor: {
		'& .MuiInputBase-input': {
			backgroundColor: whiteColor,
			borderRadius: '5px',
		},
	},
});

export default function CustomInput(props) {
	const classes = useStyles();
	const {
		id,
		error,
		ref,
		label,
		isDisabled,
		onChange,
		onBlur,
		type,
		size,
		largeFont,
		alignCenter,
		placeholder,
		fullWidth,
		isAutoComplete,
		multiline,
		rows,
		variant,
		inputProps,
		backgroundColor,
		endAdornment,
		startAdornment,
		style,
		noBorder,
		value,
		helperText,
	} = props;

	const input = classNames({
		[classes.backgroundColor]: backgroundColor,
		[classes.noBorder]: noBorder,
		[classes.alignCenter]: alignCenter,
		[classes.largeFont]: largeFont,
	});

	return (
		<TextField
			error={error}
			ref={ref}
			size={size}
			className={`${classes.input} ${input}`}
			id={id}
			onBlur={onBlur}
			disabled={isDisabled}
			autoComplete={isAutoComplete}
			label={label}
			style={style}
			fullWidth={fullWidth}
			multiline={multiline}
			rows={rows}
			placeholder={placeholder}
			value={value}
			type={type}
			helperText={helperText && helperText}
			variant={variant}
			onChange={onChange}
			InputProps={{
				inputProps,
				startAdornment,
				endAdornment,
			}}
		/>
	);
}

CustomInput.propTypes = {};
