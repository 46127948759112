import { grayColor, secColor, whiteColor } from '../../assets/js/styleCss';

const styles = () => ({
	drawer: {
		'& .MuiDrawer-paper': {
			backgroundColor: secColor,
			color: grayColor[8],
			display: 'flex',
			top: '0px',
			width: '80%',
			zIndex: '4000',
			'@media (min-width: 760px)': {
				width: '280px',
			},
		},
	},
	headerText: {
		textTransform: 'uppercase',
		color: whiteColor,
		fontFamily: '"Montserrat", serif',
		fontWeight: '600',
		lineHeight: 'initial',
		display: 'flex',
		fontSize: '20px',
	},
	closeIcon: {
		color: whiteColor,
	},
	divider: {
		backgroundColor: whiteColor,
		opacity: '0.5',
	},
	list: {
		'& a': {
			textDecoration: 'none',
			display: 'block',
			color: 'inherit',
			marginBottom: '10px',
			'&:last-child': {
				marginBottom: '0px',
			},
		},
		'& .MuiListItem-root': {
			fontSize: '16px',
			padding: '15px',
			marginBottom: '10px',
			'&:last-child': {
				marginBottom: '0px',
			},
		},
		'& .MuiSvgIcon-root': {
			fontSize: '20px',
			marginRight: '10px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
	},
	activeLink: {
		backgroundColor: 'rgba(250, 250, 250, 0.4)',
		borderLeft: `4px solid ${whiteColor}`,
	},
});

export default styles;
