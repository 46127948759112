import React, { useState } from 'react';
import { useQuery } from 'react-query';
import styles from '../styles';
import TextField from '../../customComponent/CustomInput';

// MAterial-ui/Core
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import resendTransferOtp from '../../../../api/handler/wallet/resendTransferOtp';

// Material-ui/Icon
import CloseIcon from '@material-ui/icons/Close';
import loadError from '../../../../api/loadErr';

const useStyles = makeStyles(styles);

const VerifyOtp = ({
  id,
  close,
  title,
  otp,
  pin,
  setPin,
  setOtp,
  isProcessing,
  handleVerifyOtp,
  setAlert,
  displayPin,
}) => {
  const classes = useStyles();

  // Pin Err
  const [isOtpErr, setOtpErr] = useState(false);
  const [isPinErr, setPinErr] = useState(false);

  // Resend Otp State
  const [isResendOtp, setResentOtp] = useState(false);

  useQuery('otp-data', () => resendTransferOtp(id), {
    enabled: !!isResendOtp,
    onSettled: (data, error) => {
      if (error) {
        setAlert({
          msg: loadError(error),
        });
      }
      setResentOtp(false);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (displayPin) {
      if (otp === '') {
        setOtpErr(true);
      } else {
        setOtpErr(false);
      }
      if (pin === '') {
        setPinErr(true);
      } else {
        setPinErr(false);
      }

      if (otp !== '') {
        handleVerifyOtp();
      }
    } else {
      if (otp === '') {
        setOtpErr(true);
      } else {
        setOtpErr(false);
      }

      if (otp !== '') {
        handleVerifyOtp();
      }
    }
  };

  return (
    <Box className={classes.fixedContainer} position='fixed'>
      <Grid
        className={classes.formGrid}
        container
        justifyContent='center'
        alignItems='center'
      >
        <Grid item xs={11} sm={7} md={4}>
          <form onSubmit={handleSubmit} style={{ backgroundColor: 'white' }}>
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              marginBottom='20px'
            >
              <Typography variant='h6'>{title}</Typography>
              <IconButton onClick={() => close()} size='small'>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Box>
            <Box marginBottom='15px'>
              <TextField
                label='OTP'
                error={isOtpErr}
                helperText={isOtpErr ? 'OTP is required' : ''}
                fullWidth={true}
                size='small'
                variant='outlined'
                value={otp}
                inputProps={{
                  maxLength: 5,
                  minLength: 0,
                }}
                onChange={(e) => {
                  e.target.value.length > 0 && setOtpErr(false);
                  setOtp(e.target.value);
                }}
              />
            </Box>
            {displayPin && (
              <Box marginBottom='15px'>
                <TextField
                  label='pin'
                  error={isPinErr}
                  helperText={isPinErr ? 'Pin is required' : ''}
                  fullWidth={true}
                  size='small'
                  variant='outlined'
                  value={pin}
                  inputProps={{
                    maxLength: 5,
                    minLength: 0,
                  }}
                  onChange={(e) => {
                    e.target.value.length > 0 && setPinErr(false);
                    setPin(e.target.value);
                  }}
                />
              </Box>
            )}
            <Button
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              disabled={isProcessing}
              type='submit'
              className={classes.btnBg}
            >
              send
            </Button>
            <Box marginTop='20px'>
              <Typography variant='body2'>
                Didn't get OTP,
                <span
                  onClick={() => setResentOtp(true)}
                  className={classes.resendBtn}
                >
                  {' '}
                  resend OTP
                </span>
                .
              </Typography>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VerifyOtp;
