import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

// react component used to create sweet alerts
import SweetAlert from 'react-bootstrap-sweetalert';

import {
	btn,
	grayColor,
	whiteColor,
	secColor,
	dangerColor,
} from '../../assets/js/styleCss';

const useStyles = makeStyles({
	cardTitle: {
		marginTop: '0',
		marginBottom: '3px',
		color: grayColor[2],
		fontSize: '18px',
	},
	center: {
		textAlign: 'center',
	},
	right: {
		textAlign: 'right',
	},
	left: {
		textAlign: 'left',
	},
	btn: {
		...btn,
		padding: '8px 10px',
		minWidth: '80px',
		color: whiteColor,
		display: 'inline-block',
		margin: '0 auto !important',
		backgroundColor: secColor,
	},
	btnClose: {
		...btn,
		color: whiteColor,
		display: 'inline-block',
		margin: '0 auto !important',
		backgroundColor: dangerColor[0],
	},
});

const SuccessAlert = (props) => {
	const { type, title, msg, removeAlert } = props;
	const classes = useStyles();

	return (
		<SweetAlert
			type={type}
			style={{ display: 'block' }}
			title={title ? title : ''}
			onConfirm={() => removeAlert()}
			onCancel={() => removeAlert()}
			confirmBtnCssClass={classes.button + ' ' + classes.btn}
		>
			{msg ? msg : ''}
		</SweetAlert>
	);
};

export default SuccessAlert;
