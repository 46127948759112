import React, { useEffect } from 'react';
import Layout from '../layouts/Layout';
import Content from '../layouts/wallet/Withdraw';

const Withdraw = () => {
  useEffect(() => {
    document.title = 'PMT eWallet - Withdraw';
  });
  return (
    <Layout title='Withdraw'>
      <Content />
    </Layout>
  );
};

export default Withdraw;
