const loadError = (err) => {
  if (typeof err.response !== 'undefined') {
    err.response.status === 403 && localStorage.removeItem('pmt-user-token');
    const errMsg = err.response.data.message;
    console.log(errMsg);
    return errMsg;
  } else {
    console.log(err.message);
    return err.message;
  }
};

export default loadError;
