import React from 'react';
import styles from './styles';
import logo from '../../assets/img/peace-logo.png';

// Material-Ui/Core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(styles);

const About = () => {
	const classes = useStyles();

	return (
		<Box
			padding='15px'
			display='flex'
			alignItems='center'
			className={classes.container}
		>
			<Box width='30px' height='30px' marginRight='10px'>
				<img style={{ width: '100%' }} src={logo} alt='peace-logo' />
			</Box>
			<Typography
				style={{ fontSize: '18px' }}
				className={classes.headerText}
				variant='h5'
			>
				PMT Wallet
			</Typography>
		</Box>
	);
};

export default About;
