import React, { useContext, useRef, useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import numberWithComma from '../../utils/numberWithCommas';
import WalletContext from '../../../context/wallet/walletContext';
import AuthContext from '../../../context/auth/authContext';
import styles from './styles';

import { isBrowser } from 'react-device-detect';

//  Material-ui/Core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Skeleton from '@material-ui/lab/Skeleton';
import Tooltip from '@material-ui/core/Tooltip';
import Card from '@material-ui/core/Card';

// Metarial-ui/Icon
import InfoIcon from '@material-ui/icons/Info';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const useStyles = makeStyles(styles);

const Accounts = () => {
  const classes = useStyles();

  // Initilize Location
  const location = useLocation();

  // Initialize History
  const history = useHistory();

  const walletContext = useContext(WalletContext);
  const authContext = useContext(AuthContext);
  const { authError } = authContext;
  const { wallets, walletErr } = walletContext;

  // Handle Error
  const [isError, setIsError] = useState(false);
  useEffect(
    () => {
      if (walletErr || authError) {
        setIsError(true);
      }
    },
    // eslint-disable-next-line
    [authError, walletErr]
  );

  const copyEl = useRef(null);

  // Copied State
  const [copiedMsg, setCopiedMsg] = useState(null);
  const closeAlert = () => setCopiedMsg(null);

  // Handle Copy Id
  const handleCopyText = () => {
    const content = copyEl.current.textContent;
    // navigator.clipboard.writeText(content);

    setCopiedMsg(`${content} copied`);
    setTimeout(() => {
      setCopiedMsg(null);
    }, 2000);
  };

  // Handle Redirection
  const handleRedirection = (e) => {
    e.preventDefault();
    localStorage.setItem('prev_path', JSON.stringify(location.pathname));
    history.push('/wallet/create');
  };

  const Empty = () => (
    <Box alignItems='center' marginBottom='15px' marginTop='10px'>
      <Box display='flex' alignItems='center'>
        <AccountBalanceWalletIcon
          style={{ fontSize: '34px', marginRight: '10px' }}
        />
        <Typography style={{ fontSize: '32px', display: 'flex' }}>
          &#8358; {'  '}
          {isError ? (
            ' -'
          ) : (
            <Skeleton
              variant='text'
              style={{ width: '120px', marginLeft: '10px' }}
            />
          )}
        </Typography>
      </Box>
      <Box display='flex' alignItems='center'>
        <Typography style={{ display: 'flex' }} variant='body2'>
          ID:{' '}
          {isError ? (
            ' -'
          ) : (
            <Skeleton
              style={{ width: '100px', marginLeft: '10px' }}
              variant='text'
            />
          )}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <>
      <Snackbar
        open={copiedMsg ? true : false}
        autoHideDuration={6000}
        onClose={closeAlert}
      >
        <Alert severity='success'>{copiedMsg}</Alert>
      </Snackbar>
      <Box marginBottom='20px'>
        <Card className={classes.card}>
          <Box marginBottom='15px'>
            <Typography className={classes.headerText} variant='h5'>
              Account{wallets && wallets.length > 1 ? 's' : ''}
            </Typography>
          </Box>
          <Box
            className={
              wallets && wallets.length > 1
                ? classes.cardContentM
                : classes.cardContent
            }
          >
            {wallets ? (
              wallets.length > 0 ? (
                wallets.map((wallet) => (
                  <Box key={wallet.id} marginBottom='15px' marginTop='10px'>
                    <Box display='flex' alignItems='center'>
                      <AccountBalanceWalletIcon
                        style={{ fontSize: '34px', marginRight: '10px' }}
                      />
                      <Typography style={{ fontSize: '32px' }}>
                        &#8358; {'  '}
                        {numberWithComma(wallet ? wallet.balance : '')}
                      </Typography>
                    </Box>
                    <Box display='flex' alignItems='center'>
                      <Typography variant='body2' color='textSecondary'>
                        ID:{' '}
                        <span ref={copyEl}>{wallet ? wallet.wallet : '-'}</span>
                      </Typography>
                      {isBrowser && (
                        <CopyToClipboard
                          text={wallet && wallet.wallet}
                          onCopy={() => handleCopyText()}
                        >
                          <Tooltip title='Copy wallet ID' placement='bottom'>
                            <IconButton style={{ marginLeft: '10px' }}>
                              <FileCopyIcon style={{ fontSize: '16px' }} />
                            </IconButton>
                          </Tooltip>
                        </CopyToClipboard>
                      )}
                    </Box>
                  </Box>
                ))
              ) : (
                <Box margin='20px 0px' display='flex' alignItems='center'>
                  <InfoIcon
                    style={{ marginRight: '10px' }}
                    className={classes.infoColor}
                  />
                  <Typography>
                    No account in Wallet.{' '}
                    <Link
                      onClick={(e) => handleRedirection(e)}
                      className={classes.link}
                      to='/wallet/create'
                    >
                      Setup wallet.
                    </Link>
                  </Typography>
                </Box>
              )
            ) : (
              <Empty />
            )}
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default Accounts;
