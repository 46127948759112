import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import {
	defaultFont,
	grayColor,
	dangerColor,
	transition,
	successColor,
	secColor,
} from '../../assets/js/styleCss';

const useStyles = makeStyles({
	formControl: {
		width: '100% !important',
		verticalAlign: 'unset',
		color: grayColor[14],
		'& .MuiInputBase-root, & .MuiMenuItem-root': {
			fontSize: '12px !important',
		},
	},
	underline: {
		'&:hover:before,&:before': {
			borderColor: grayColor[4] + '!important',
			borderWidth: '1px !important',
		},

		'&:after': {
			borderColor: secColor,
		},
		'& + p': {
			fontWeight: '300',
		},
	},
	underlineError: {
		'&:after': {
			borderColor: dangerColor[0],
		},
	},
	underlineSuccess: {
		'&:after': {
			borderColor: successColor[0],
		},
	},
	disableSelect: {
		'&:after': {
			borderColor: grayColor[4] + '!important',
			borderWidth: '1px !important',
			cursor: 'not-allowed',
		},
	},
	labelRoot: {
		...defaultFont,
		color: grayColor[3] + ' !important',
		fontWeight: '400',
		top: '0',
		fontSize: '12px !important',
		verticalAlign: 'middle',
		lineHeight: '1.3',
		letterSpacing: 'unset',
	},
	select: {
		...transition,
		fonSize: '14px',
		'& .MuiInputBase-root': {
			fontSize: '16px',
		},
		'& .MuiInput-underline:after': {
			borderBottom: `2px solid ${secColor}`,
		},
		'& .MuiInputLabel-root.Mui-focused': {
			color: `${secColor} !important`,
		},
		'& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: `rgba(0, 0, 0, 0.23) !important`,
		},
		'& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
			borderColor: `${secColor} !important`,
		},

		'& .MuiInputLabel-formControl': {
			top: '0px',
		},
		'& .MuiInputLabel-formControl.Mui-focused': {
			top: '1px !important',
		},
		'& .MuiOutlinedInput-multiline': {
			padding: '0px !important',
		},
	},
	labelRootError: {
		color: dangerColor[0] + ' !important',
	},
	labelRootSuccess: {
		color: successColor[0] + ' !important',
	},
	errorText: {
		color: dangerColor[0],
		marginLeft: '15px',
		marginTop: '6px',
	},
});

const CustomSelect = (props) => {
	const classes = useStyles();

	const {
		children,
		id,
		variant,
		onChange,
		error,
		size,
		fullWidth,
		success,
		helperText,
		value,
		style,
		isDisabled,
	} = props;

	const labelRoot = classNames({
		[' ' + classes.labelRootError]: error,
		[' ' + classes.labelRootSuccess]: success && !error,
	});

	const underline = classNames({
		[classes.underline]: true,
		[' ' + classes.underlineError]: error,
		[' ' + classes.underlineSuccess]: success && !error,
	});

	return (
		<div>
			<FormControl
				fullWidth={fullWidth}
				size={size}
				error={error}
				style={{ ...style }}
				className={classes.select}
			>
				<InputLabel className={`${classes.labelRoot} ${labelRoot}`} id={id}>
					{id}
				</InputLabel>
				<Select
					variant={variant}
					native
					disabled={isDisabled}
					onChange={onChange}
					value={value}
					className={`${classes.select} ${underline} ${
						isDisabled && classes.disableSelect
					}`}
				>
					{children}
				</Select>
				{helperText && (
					<small className={classes.errorText}>{helperText}</small>
				)}
			</FormControl>
		</div>
	);
};

export default CustomSelect;
