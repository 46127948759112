import React, { useContext } from 'react';
import AppContext from '../../../context/app/appContext';
import { Link } from 'react-router-dom';

// @material-ul/core

import { makeStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

//Material-Ui/Icon
import MenuIcon from '@material-ui/icons/Menu';
import HistoryIcon from '@material-ui/icons/History';

import styles from './styles';
const useStyles = makeStyles(styles);

const Header = (props) => {
  const classes = useStyles();
  const { title } = props;

  const appContext = useContext(AppContext);
  const { toggleSideBar } = appContext;

  return (
    <Box style={{ position: 'relative' }} className={classes.headerMainWrapper}>
      <Box className={classes.horizontalLine}>
        <Box className={classes.container}>
          <Box justifyContent='space-between' className={classes.headerContent}>
            <Box display='flex' alignItems='center'>
              {
                <Hidden implementation='css' mdUp>
                  <Box style={{ marginRight: '10px' }}>
                    <MenuIcon
                      onClick={() => toggleSideBar()}
                      style={{ verticalAlign: 'middle' }}
                    />
                  </Box>
                </Hidden>
              }
              <Box display='flex' alignItems='center'>
                <Typography className={classes.headerText} variant='h6'>
                  {title}
                </Typography>
              </Box>
            </Box>
            <Box display='flex' alignItems='center'>
              <Hidden implementation='css' mdUp>
                <Link className={classes.link} to='/wallet/transactions'>
                  <IconButton>
                    <HistoryIcon />
                  </IconButton>
                </Link>
              </Hidden>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
