export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';

// Alert
export const HIDE_ALERT = 'HIDE_ALERT';
export const DISPLAY_ALERT = 'DISPLAY_ALERT';

// Error
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const REMOVE_ERROR = 'REMOVE_ERROR';

// Wallet
export const INITIATE_CREATE_WALLET = 'INITIATE_CREATE_WALLET';
export const CREATE_WALLET = 'CREATE_WALLET';
export const GET_WALLET = 'GET_WALLET';
export const SETUP_WALLET_PIN = 'SETUP_WALLET_PIN';
export const CLOSE_NOTICE = 'CLOSE_NOTICE';
export const DISPLAY_NOTICE = 'DISPLAY_NOTICE';
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const UPDATE_WALLET_STATE = 'UPDATE_WALLET_STATE';
export const UPDATE_TRANSACTIONS = 'UPDATE_TRANSACTIONS';

// Auth
export const LOGIN_USER = 'LOGIN_USER';
export const RETRIEVE_USER = 'RETRIEVE_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const REQUEST_OTP = 'REQUEST_OTP';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_AUTH_STATE = 'UPDATE_AUTH_STATE';

export const apis = [
	{ name: 'STAFF', type: 'ST', url: process.env.REACT_APP_STAFF_API },
	{
		name: 'TRANSPORT_AGENT',
		type: 'TA',
		url: process.env.REACT_APP_AGENT_API,
	},
	{
		name: 'LOGISTIC_AGENT',
		type: 'LA',
		url: process.env.REACT_APP_AGENT_API,
	},
	{
		name: 'TRANSPORT_PARTNER',
		type: 'TP',
		url: process.env.REACT_APP_DRIVER_API,
	},
	{
		name: 'LOGISTIC_PARTNER',
		type: 'LP',
		url: process.env.REACT_APP_DRIVER_API,
	},
	{
		name: 'ECOMMERCE_CUSTOMER',
		type: 'EC',
		url: process.env.REACT_APP_ECOMMERCE_API,
	},
	{
		name: 'ECOMMERCE_MERCHANT',
		type: 'EM',
		url: process.env.REACT_APP_ECOMMERCE_API,
	},
	{
		name: 'PMT_PARTNERS',
		type: 'PMT_PARTNERS',
		url: process.env.REACT_APP_PARTNERS_API,
	},
];
