import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../../context/auth/authContext';
import CustomInput from '../../layouts/customComponent/CustomInput';
import styles from './styles';
import logo from '../../assets/img/peace-logo.png';

// Material-UI/Core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(styles);

const ForgetPassword = () => {
	const classes = useStyles();
	const authContext = useContext(AuthContext);
	const { requestOtp, errorMessage } = authContext;
	const history = useHistory();

	const [inputValue, setInputValue] = useState('');
	const [inputErr, setInputErr] = useState(null);

	useEffect(() => {
		if (errorMessage) {
			setIsProcessing(false);
		}
	}, [errorMessage]);

	const [isProcessing, setIsProcessing] = useState(false);
	const handleSubmit = async (e) => {
		const emailRex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

		e.preventDefault();
		if (inputValue === '') {
			setInputErr('Phone number or email is required');
		} else {
			setInputErr(null);
		}

		if (inputValue !== '') {
			const payload = {};
			if (emailRex.test(inputValue)) {
				payload.email = inputValue;
			} else {
				payload.phone = inputValue;
			}

			setIsProcessing(true);
			const res = await requestOtp(payload);
			setIsProcessing(false);
			if (res) {
				if (res.success) {
					setIsProcessing(false);
					history.push('/auth/verify-otp');
				}
			}
		}
	};

	return (
		<>
			<Box
				bgcolor='white'
				height='100vh'
				display='flex'
				alignContent='center'
				justifyContent='center'
			>
				<Grid justifyContent='center' alignItems='center' container>
					<Grid item xs={11} sm={6} md={5} lg={4}>
						<Box
							height='400px'
							border='1px solid rgba(0, 0, 0, 0.1)'
							padding='20px'
							borderRadius='5px'
						>
							<Box margin='auto' marginBottom='20px' width='50px' height='50px'>
								<img style={{ width: '100%' }} src={logo} alt='peace-logo' />
							</Box>

							<Box marginBottom='30px'>
								<Typography paragraph align='center' variant='h6'>
									Recover password
								</Typography>
								<Typography
									color='textSecondary'
									align='center'
									variant='body2'
								>
									Enter your phone number or email address
								</Typography>
							</Box>
							<form onSubmit={handleSubmit}>
								<CustomInput
									style={{ marginBottom: '20px' }}
									label='Enter your phone number or email'
									type='phone'
									variant='outlined'
									value={inputValue}
									error={inputErr ? true : false}
									helperText={inputErr}
									fullWidth={true}
									onChange={(e) => setInputValue(e.target.value)}
								/>
								<Box display='flex' justifyContent='flex-end'>
									<Button
										disabled={isProcessing}
										type='submit'
										className={classes.btnInline}
									>
										{isProcessing && (
											<CircularProgress className={classes.circularProgress} />
										)}
										Send otp
									</Button>
								</Box>
							</form>
							<Typography className={classes.text} variant='body2' paragraph>
								Remember password?
								<Link
									style={{ marginLeft: '5px' }}
									className={classes.link}
									to='/auth/login'
								>
									Login.
								</Link>
							</Typography>
						</Box>
						<Box marginTop='10px' display='flex' justifyContent='space-between'>
							<Box>Pmt wallet</Box>
							<Box>
								<Link
									style={{ marginRight: '15px' }}
									className={classes.inlineLink}
									to='/'
								>
									Help
								</Link>
								<Link className={classes.inlineLink} to='/'>
									Terms & conditions
								</Link>
							</Box>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</>
	);
};

export default ForgetPassword;
