import React, { Component } from 'react';

// MAterial-ui/Core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

export default class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			errorInfo: null,
		};
	}
	componentDidCatch(error, errorInfo) {
		this.setState({
			error: error,
			errorInfo: errorInfo,
		});
	}
	render() {
		if (this.state.errorInfo) {
			return (
				<Box maxWidth='1100px' margin='auto' marginTop='20px'>
					<Typography>
						Something went wrong.
						<details>
							{this.state.error && this.state.errorInfo.toString()}
							<br />
							{this.state.errorInfo.componentStack}
						</details>
					</Typography>
				</Box>
			);
		}
		return this.props.children;
	}
}
