import axios from 'axios';
import setTokenHeader from '../../setTokenInHeader';

import { walletAPI } from '../..';

const createTransfer = async (details) => {
	await setTokenHeader();

	return await axios.post(`${walletAPI}/transfers`, details);
};

export default createTransfer;
