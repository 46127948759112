import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQueryClient, useMutation } from 'react-query';
import WalletContext from '../../../../../context/wallet/walletContext';
import TextField from '../../../customComponent/CustomInput';
import styles from '../../styles';
import updateWallet from '../../../../../api/handler/wallet/updateWallet';
import ToastAlert from '../../../alert/ToastAlert';
import Alert from '../../../alert/SuccessAlert';
import ConfirmUpdate from '../ConfirmUpdate';

// Material-ui/Core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Layout from '../../../Layout';
import loadError from '../../../../../api/loadErr';

const useStyles = makeStyles(styles);

const UpdateName = (props) => {
  const classes = useStyles();
  const walletContext = useContext(WalletContext);
  const { wallets } = walletContext;

  // Initiate Usehistory
  const { goBack } = useHistory();

  // Alert State
  const [alert, setAlert] = useState(null);
  const removeAlert = () => setAlert(null);
  const closeAlertAndRedirect = () => {
    setAlert(null);
    // Redirect Back
    goBack();
  };

  // App State
  const [accountName, setAccountName] = useState('');

  // Const Pin
  const [pin, setPin] = useState('');

  // Display Confirm Pin
  const [isDisplayConfirmModal, setDisplayConfirmModal] = useState(false);
  const closeConfirmModal = () => setDisplayConfirmModal(false);

  // Error state
  const [isAccountNameErr, setAccountNameErr] = useState(false);

  useEffect(() => {
    const walletId = props.match.params.id;
    if (wallets) {
      wallets.find(
        (wallet) => wallet.wallet === walletId && setAccountName(wallet?.name)
      );
    }
  }, [wallets, props.match.params.id]);

  // Handle Form Submittion
  const queryClient = useQueryClient();
  const { isLoading, mutate } = useMutation(updateWallet, {
    onSettled: (data, error) => {
      if (error) {
        setAlert({
          msg: loadError(error),
          type: 'error',
        });
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries('user-account');
      setAlert({
        title: 'Wallet Settings Message',
        msg: 'Bank details updated successfully!',
        type: 'success',
      });

      // Clear State
      setDisplayConfirmModal(false);
      setPin('');
    },
  });

  // Handle Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (accountName === '') {
      setAccountNameErr(true);
    } else {
      setAccountNameErr(false);
    }

    if (accountName !== '') {
      setDisplayConfirmModal(true);
    }
  };

  // Process data
  const processData = () => {
    if (accountName === '') {
      setAccountNameErr(true);
    } else {
      setAccountNameErr(false);
    }

    if (accountName !== '') {
      mutate({
        wallet: props.match.params.id,
        payload: {
          name: accountName,
          pin,
        },
      });
    }
  };

  return (
    <Layout title='Settings'>
      {alert && alert.type === 'error' && (
        <ToastAlert
          msg={alert.msg}
          type={alert.type}
          closeAlert={removeAlert}
        />
      )}
      {alert && alert.type === 'success' && (
        <Alert
          msg={alert.msg}
          type={alert.type}
          removeAlert={closeAlertAndRedirect}
        />
      )}
      {isDisplayConfirmModal && (
        <ConfirmUpdate
          pin={pin}
          setPin={setPin}
          isProcessing={isLoading}
          confirmUpdate={processData}
          close={closeConfirmModal}
        />
      )}
      <Box className={classes.container}>
        <Box marginTop='15px' maxWidth='520px' margin='auto'>
          <Box marginBottom='30px'>
            <Typography variant='h6'>Update Wallet Name</Typography>
          </Box>
          <Box marginTop='10px'>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Box marginBottom='15px'>
                <TextField
                  error={isAccountNameErr}
                  helperText={isAccountNameErr && 'Account name is required'}
                  fullWidth={true}
                  size='small'
                  variant='outlined'
                  label='Account name'
                  value={accountName}
                  onChange={(e) => {
                    setAccountName(e.target.value);
                  }}
                />
              </Box>
              <Button
                disabled={isLoading}
                type='submit'
                className={classes.btnBg}
              >
                Update
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default UpdateName;
