import Index from '../component/views/index';
import Login from '../component/views/auth/Login';
import VerifyOtp from '../component/views/auth/VerifyOtp';
import Register from '../component/views/auth/Register';
import ForgotPssword from '../component/views/auth/ForgotPssword';
import UpdatePassword from '../component/views/auth/UpdatePassword';

// Wallet
import Deposit from '../component/views/Deposit';
import Withdraw from '../component/views/Withdraw';
import Transfer from '../component/views/Transfer';
import Transaction from '../component/views/Transaction';
import CreateWallet from '../component/layouts/wallet/CreateWallet';
import SetupWalletPin from '../component/layouts/wallet/SetupWalletPin';
import SetupWalletPinFromSettings from '../component/layouts/settings/account/SetupWalletPin';
import ChangeWalletPin from '../component/layouts/settings/account/update/WalletPin';
import ChangeBankDetails from '../component/layouts/settings/account/update/Bank';
import SecurityQuestion from '../component/layouts/settings/account/SecurityQuestion';
import WalletView from '../component/layouts/settings/account/WalletView';
import UpdateName from '../component/layouts/settings/account/update/Name';
import AddUserToWallet from '../component/layouts/settings/account/AddUser';

import Setting from '../component/views/Setting';

export const privateRoutes = [
  {
    path: '/',
    name: 'Home',
    component: Index,
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: Deposit,
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: Withdraw,
  },
  {
    path: '/transfer',
    name: 'Transfer',
    component: Transfer,
  },
  {
    path: '/transactions',
    name: 'Transaction',
    component: Transaction,
  },
  {
    path: '/wallet/create',
    name: 'Create Wallet',
    component: CreateWallet,
  },
  {
    path: '/settings',
    name: 'Setting',
    component: Setting,
  },
  {
    path: '/settings/change-pin/wallet/:id',
    name: 'Setting',
    component: ChangeWalletPin,
  },
  {
    path: '/wallet/setup-pin/:id',
    name: 'Setting',
    component: SetupWalletPin,
  },
  {
    path: '/settings/setup-pin/wallet/:id',
    name: 'Setting',
    component: SetupWalletPinFromSettings,
  },
  {
    path: '/settings/wallet/bank/change/:id',
    name: 'Setting',
    component: ChangeBankDetails,
  },
  {
    path: '/settings/wallet/name/change/:id',
    name: 'Setting',
    component: UpdateName,
  },
  {
    path: '/settings/security-questions/wallet/:id',
    name: 'Setting',
    component: SecurityQuestion,
  },
  {
    path: '/settings/wallet/:id',
    name: 'Setting',
    component: WalletView,
  },
  {
    path: '/settings/add-user/:id',
    name: 'Add user to wallet',
    component: AddUserToWallet,
  },
];

export const initiateWallet = [
  {
    path: '/wallet/setup-pin',
    name: 'Setup Wallet Pin',
    component: SetupWalletPin,
  },
];

const appRoute = [
  {
    path: '/auth/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/auth/verify-otp',
    name: 'Verify otp',
    component: VerifyOtp,
  },
  {
    path: '/auth/update-password',
    name: 'Update password',
    component: UpdatePassword,
  },
  {
    path: '/auth/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/forget-password',
    name: 'Forget password',
    component: ForgotPssword,
  },
];

export default appRoute;
