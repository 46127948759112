import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../../context/auth/authContext';
import { Link } from 'react-router-dom';
import CustomInput from '../../layouts/customComponent/CustomInput';
import cx from 'classnames';
import styles from './styles';
import Alert from '../../layouts/alert/ToastAlert';
import CustomRadio from '../../layouts/customComponent/CustomRadio';

// Material-UI/Core
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';

// Material-UI/Icon
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(styles);

const Register = () => {
	const classes = useStyles();
	const authContext = useContext(AuthContext);
	const { registerUser, errorMessage } = authContext;
	const history = useHistory();

	// Target Pasword Input
	const [isDisplayPassword, setIsDisplayPassword] = useState(false);
	const [isDisplayConfirmPassword, setIsDisplayConfirmPassword] =
		useState(false);

	// Toggle Password
	const togglePassword = () => {
		setIsDisplayPassword(!isDisplayPassword);
	};
	// Toggle Password
	const toggleConfirmPassword = () => {
		setIsDisplayConfirmPassword(!isDisplayConfirmPassword);
	};

	const [registrationDetails, setRegistrationDetails] = useState({
		surname: '',
		otherName: '',
		phone: '',
		gender: '',
		email: '',
		password: '',
		confirmPassword: '',
	});

	const {
		surname,
		otherName,
		phone,
		gender,
		password,
		confirmPassword,
		email,
	} = registrationDetails;

	const [surnameErr, setSurnameErr] = useState(null);
	const [otherNameErr, setOtherNameErr] = useState(null);
	const [emailErr, setEmailErr] = useState(null);
	const [genderErr, setGenderErr] = useState(null);
	const [phoneErr, setPhoneErr] = useState(null);
	const [passwordErr, setPasswordErr] = useState(null);
	const [confirmPasswordErr, setConfirmPasswordErr] = useState(null);

	const [isProcessing, setIsProcessing] = useState(false);

	//Alert State
	const [alert, setAlert] = useState(null);
	const removeAlert = () => setAlert(null);

	// Submition Error
	useEffect(() => {
		if (errorMessage) {
			setIsProcessing(false);
			setAlert({ msg: errorMessage, type: 'error' });
		}
	}, [errorMessage]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		const emailRex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
		const phoneRex = /^((\+\d{1,3}[ ]?)?\d{10}) || (\d{11})$/;

		if (surname === '') {
			setSurnameErr('Surname is required');
		} else {
			setSurnameErr(null);
		}

		if (otherName === '') {
			setOtherNameErr('First name is required');
		} else {
			setOtherNameErr(null);
		}

		if (gender === '') {
			setGenderErr('Select your gender');
		} else {
			setGenderErr(null);
		}

		// verify Email
		if (email === '') {
			setEmailErr('Email is required');
		} else if (!email.match(emailRex)) {
			setEmailErr('Incorrect email');
		} else {
			setEmailErr(null);
		}

		// Verify Phone
		if (phone === '') {
			setPhoneErr('Phone is required');
		} else if (!phone.match(phoneRex)) {
			setPhoneErr('Incorrect phone number');
		} else {
			setPhoneErr(null);
		}

		if (password === '') {
			setPasswordErr('Password is required');
		} else {
			setPasswordErr(null);
		}

		// Verify if password match
		if (confirmPassword === '') {
			setConfirmPasswordErr('enter confirm password');
		} else if (confirmPassword !== password) {
			setConfirmPasswordErr('Password do not match');
		} else {
			setConfirmPasswordErr(null);
		}

		// Sunmit form
		if (
			surname !== '' &&
			otherName !== '' &&
			emailRex.test(email) &&
			phone !== '' &&
			gender !== '' &&
			password !== '' &&
			password === confirmPassword
		) {
			setIsProcessing(true);
			const res = await registerUser(registrationDetails);
			if (res) {
				if (res.success) {
					setIsProcessing(false);
					setAlert({ msg: `${res.message}. Login now`, type: 'success' });
					history.push('/auth/login');
					setRegistrationDetails({
						surname: '',
						otherName: '',
						phone: '',
						gender: '',
						email: '',
						password: '',
						confirmPassword: '',
					});
				}
			}
		}
	};

	return (
		<>
			{alert && (
				<Alert msg={alert.msg} type={alert.type} closeAlert={removeAlert} />
			)}
			<div className={classes.container}>
				<Grid container>
					<Grid className={classes.gridItem} item xs={11} sm={8} md={6} lg={5}>
						<Box
							width='100%'
							marginBottom='30px'
							display='flex'
							justifyContent='center'
							alignItems='center'
							position='relative'
						>
							<Box position='absolute' left='0px'>
								<Hidden implementation='css' mdUp>
									<IconButton
										size='small'
										onClick={() => window.history.back()}
									>
										<ArrowBackIosIcon style={{ verticalAlign: 'middle' }} />
									</IconButton>
								</Hidden>
							</Box>
							<Typography
								align='center'
								className={classes.headerText}
								variant='h6'
							>
								Registration
							</Typography>
						</Box>
						<Box display='flex' alignItems='center'>
							<InfoIcon style={{ fontSize: '16px', marginRight: '5px' }} />
							<Typography style={{ lineHeight: 'initial' }} variant='body2'>
								All field is required
							</Typography>
						</Box>
						<form
							onSubmit={(e) => handleSubmit(e)}
							className={cx(classes.formContainer)}
						>
							<CustomInput
								style={{ marginBottom: '10px' }}
								label='surname'
								type='text'
								fullWidth={true}
								error={surnameErr ? true : false}
								value={surname}
								helperText={surnameErr}
								onChange={(e) => {
									setRegistrationDetails({
										...registrationDetails,
										surname: e.target.value,
									});
								}}
							/>
							<CustomInput
								style={{ marginBottom: '10px' }}
								label='first name'
								type='text'
								fullWidth={true}
								error={otherNameErr ? true : false}
								value={otherName}
								helperText={otherNameErr}
								onChange={(e) => {
									setRegistrationDetails({
										...registrationDetails,
										otherName: e.target.value,
									});
								}}
							/>
							<CustomRadio
								value={gender}
								onChange={(e) => {
									setRegistrationDetails({
										...registrationDetails,
										gender: e.target.value,
									});
								}}
								innerStyle={{ flexDirection: 'row' }}
								label={
									genderErr ? (
										<span style={{ color: '#f44336' }}>{genderErr}</span>
									) : (
										'Gender'
									)
								}
							>
								<FormControlLabel
									value='MALE'
									label='Male'
									control={<Radio />}
								/>
								<FormControlLabel
									value='FEMALE'
									label='Female'
									control={<Radio />}
								/>
							</CustomRadio>
							<CustomInput
								style={{ marginBottom: '10px' }}
								label='Email'
								type='email'
								fullWidth={true}
								error={emailErr ? true : false}
								value={email}
								helperText={emailErr}
								onChange={(e) => {
									setRegistrationDetails({
										...registrationDetails,
										email: e.target.value,
									});
								}}
							/>
							<CustomInput
								style={{ marginBottom: '10px' }}
								label='Phone number'
								type='text'
								fullWidth={true}
								error={phoneErr ? true : false}
								value={phone}
								helperText={phoneErr}
								onChange={(e) => {
									setRegistrationDetails({
										...registrationDetails,
										phone: e.target.value,
									});
								}}
							/>
							<CustomInput
								style={{ marginBottom: '10px' }}
								label='Password'
								type={isDisplayPassword ? 'text' : 'password'}
								fullWidth={true}
								endAdornment={
									<InputAdornment position='end'>
										{isDisplayPassword ? (
											<VisibilityOffIcon
												className={classes.visibilityIcon}
												onClick={() => togglePassword()}
											/>
										) : (
											<VisibilityIcon
												className={classes.visibilityIcon}
												onClick={() => togglePassword()}
											/>
										)}
									</InputAdornment>
								}
								error={passwordErr ? true : false}
								value={password}
								helperText={passwordErr}
								onChange={(e) => {
									setRegistrationDetails({
										...registrationDetails,
										password: e.target.value,
									});
								}}
							/>
							<CustomInput
								style={{ marginBottom: '10px' }}
								label='Confirm password'
								type={isDisplayConfirmPassword ? 'text' : 'password'}
								fullWidth={true}
								endAdornment={
									<InputAdornment position='end'>
										{isDisplayConfirmPassword ? (
											<VisibilityOffIcon
												className={classes.visibilityIcon}
												onClick={() => toggleConfirmPassword()}
											/>
										) : (
											<VisibilityIcon
												className={classes.visibilityIcon}
												onClick={() => toggleConfirmPassword()}
											/>
										)}
									</InputAdornment>
								}
								error={confirmPasswordErr ? true : false}
								value={confirmPassword}
								helperText={confirmPasswordErr}
								onChange={(e) => {
									setRegistrationDetails({
										...registrationDetails,
										confirmPassword: e.target.value,
									});
								}}
							/>

							<Button type='submit' className={classes.btn}>
								{isProcessing && (
									<CircularProgress className={classes.circularProgress} />
								)}
								register
							</Button>
						</form>
						<Typography
							style={{ marginTop: '30px' }}
							className={classes.text}
							variant='body2'
							paragraph
						>
							Already have an account?{' '}
							<Link className={classes.link} to='/auth/login'>
								log in.
							</Link>
						</Typography>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default Register;
