import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import getTransaction from '../../../api/handler/wallet/getTransactions';
import styles from './styles';

// Material-ui/Core
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

// Material-ui/Icon
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';

const useStyles = makeStyles(styles);

const Operation = () => {
  const classes = useStyles();
  const [withdraws, setWithdraws] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [transfers, setTransfers] = useState([]);

  // Load Transaction

  useQuery('transactions', getTransaction, {
    onSettled: (data, error) => {
      if (error) {
        console.log(error);
      }
    },
    onSuccess: (data) => {
      if (data.data.success) {
        setTransfers(
          data.data.payload.payload.filter((value) => value.transfer)
        );
        setDeposits(data.data.payload.payload.filter((value) => value.deposit));
        setWithdraws(
          data.data.payload.payload.filter((value) => value.withdraw)
        );
      }
    },
  });

  return (
    <Box className={classes.quickAction} display='grid' gridGap='40px'>
      <Card>
        <Box>
          <Typography
            className={classes.subHeaderText}
            variant='h5'
            align='center'
          >
            Deposit
          </Typography>
          <Box>
            <Box
              color='inherit'
              display='flex'
              alignItems='center'
              flexDirection='column'
            >
              <Typography variant='h3'>
                {deposits ? deposits.length : '0'}
              </Typography>
              <small variant='body1'>Records</small>
            </Box>
            <Link
              style={{
                textAlign: 'center',
                textDecoration: 'none',
                flex: '1',
              }}
              className={classes.quickActionlink}
              to='/deposit'
            >
              <HowToVoteIcon
                className={`${classes.icon} ${classes.successColor}`}
              />
              <Typography>Add money</Typography>
            </Link>
          </Box>
        </Box>
      </Card>
      <Card>
        <Box>
          <Typography
            className={classes.subHeaderText}
            variant='h5'
            align='center'
          >
            Withdraw
          </Typography>
          <Box>
            <Box
              color='inherit'
              display='flex'
              alignItems='center'
              flexDirection='column'
            >
              <Typography variant='h3'>
                {withdraws ? withdraws.length : '0'}
              </Typography>
              <Typography
                style={{
                  fontSize: '12px',
                }}
                variant='body1'
              >
                Records
              </Typography>
            </Box>
            <Link className={classes.quickActionlink} to='/withdraw'>
              <CreditCardIcon
                className={`${classes.icon} ${classes.dangerColor}`}
              />
              <span>Make Withdraw</span>
            </Link>
          </Box>
        </Box>
      </Card>
      <Card>
        <Box>
          <Typography
            className={classes.subHeaderText}
            variant='h5'
            align='center'
          >
            Transfer
          </Typography>
          <Box>
            <Box
              color='inherit'
              display='flex'
              alignItems='center'
              flexDirection='column'
            >
              <Typography variant='h3'>
                {transfers ? transfers.length : '0'}
              </Typography>
              <small variant='body1'>Records</small>
            </Box>
            <Link className={classes.quickActionlink} to='/transfer'>
              <SwapHorizIcon
                className={`${classes.icon} ${classes.infoColor}`}
              />
              <Typography>Make Transfer</Typography>
            </Link>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

export default Operation;
