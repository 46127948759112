import axios from 'axios';
import setTokenHeader from '../../setTokenInHeader';

import { walletAPI } from '../..';

const updateWallet = async (value) => {
  await setTokenHeader();
  return await axios.put(
    `${walletAPI}/accounts/wallet/${value.wallet}`,
    value.payload
  );
};

export default updateWallet;
