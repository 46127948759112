import {
  transition,
  link,
  lightSecColor,
  secColor,
  blackColor,
  whiteColor,
} from '../../assets/js/styleCss';

const headerStyle = (theme) => ({
  container: {
    padding: '10px 15px',
  },
  headerMainWrapper: {
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.2)',
    backgroundColor: whiteColor,
    color: secColor,
  },
  diplayFlex: {
    display: 'flex',
    alignItems: 'center',
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 0',
    '@media (min-width: 768px)': {
      padding: '12px 0',
    },
  },

  menuNav: {
    display: 'none',
    '@media (min-width: 960px)': {
      display: 'flex',
    },
  },

  iconSvg: {
    ...transition,
  },

  headerText: {
    textTransform: 'uppercase',
    color: secColor,
    fontFamily: '"Montserrat", serif',
    fontWeight: '600',
    lineHeight: 'initial',
    display: 'flex',
    fontSize: '18px',

    '@media (min-width: 768px)': {
      fontSize: '18px',
      fontWeight: '600',
    },
    '@media (min-width: 960px)': {
      fontSize: '20px',
      fontWeight: '600',
    },
  },

  headingText: {
    padding: '0px 30px',
    backgroundColor: blackColor,
    color: whiteColor,
    alignSelf: 'stretch',
    fontSize: '13px',
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontWeight: '700',
  },

  link: {
    ...link,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    '& .MuiSvgIcon-root': {
      color: secColor,
    },
    '@media (min-width: 960px)': {
      '&:hover .MuiSvgIcon-root': {
        color: lightSecColor,
      },
    },
  },
  avatar: {
    width: '30px',
    height: '30px',
  },
});

export default headerStyle;
