import React, { useContext } from 'react';
import WalletContext from '../../../context/wallet/walletContext';

// Material UI/Core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { whiteColor, lightSecColor } from '../../assets/js/styleCss';

const styles = makeStyles({
  main: {
    width: '100%',
    '@media (min-width: 960px)': {
      width: 'calc(100% - 280px)',
    },
  },
  container: {
    width: '100%',
    '& .MuiAlert-root': {
      borderRadius: '0px',
    },
  },
  alert: {
    backgroundColor: lightSecColor,
    color: whiteColor,
    padding: '15px',
  },
  link: {
    textDecoration: 'none',
    color: whiteColor,
    '&:hover': {
      opacity: '0.8',
    },
  },
});

const NoticeAlert = () => {
  const classes = styles();
  const walletContext = useContext(WalletContext);
  const { closeNotice, wallets } = walletContext;

  return (
    <Box
      style={{ width: '100%' }}
      className={classes.main}
      zIndex='2000'
      display='flex'
      justifyContent='center'
    >
      <Box zIndex='7000' className={classes.container} top='20px'>
        {wallets && (wallets[0]?.notice || wallets[1]?.notice) && (
          <Box className={classes.alert}>
            {wallets.map((wallet) => (
              <Typography
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                key={wallet.id}
                variant='body2'
              >
                <span>{wallet.notice}</span>
                <IconButton
                  color='inherit'
                  size='small'
                  onClick={() => {
                    closeNotice(wallet.id);
                  }}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              </Typography>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default NoticeAlert;
