import React from 'react';
import Header from './header/Header';
import Footer from './footer/FooterMain';
import MobileSidebar from './sidebar/Mobile';
import Sidebar from './sidebar/NonMobile';
import NoticeAlert from './alert/NoticeAlert';

// Material-ui/Core
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  layoutContainer: {
    width: '100%',
    '@media (min-width: 960px)': {
      width: 'calc(100% - 280px)',
      float: 'right',
    },
  },
});

const Layout = ({ children, title, bgcolor }) => {
  const classes = useStyles();
  return (
    <>
      <Hidden implementation='css' smDown>
        <Sidebar />
      </Hidden>
      <Box
        position='relative'
        display='flex'
        className={classes.layoutContainer}
        flexDirection='column'
        height='100vh'
        bgcolor={bgcolor}
      >
        <NoticeAlert />
        <Header title={title} />
        <MobileSidebar />
        <Box marginBottom='15px' flex='1'>
          {children}
        </Box>
        <Hidden implementation='css' mdDown>
          <Footer />
        </Hidden>
      </Box>
    </>
  );
};

export default Layout;
