import React, { useState, useContext, useEffect } from 'react';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../../context/auth/authContext';
import TextField from '../customComponent/CustomInput';
import Select from '../customComponent/CustomSelect';
import Alert from '../alert/ToastAlert';
import Layout from '../Layout';
import getBanks from '../../../api/handler/wallet/getBanks';
import createWallet from '../../../api/handler/wallet/createWallet';
import loadError from '../../../api/loadErr';
import verifyWallet from '../../../api/handler/wallet/verifyWallet';
import CircularProgress from '../spinner/CircularProgress';

// Material-ui/Core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

// Material-ui/Icon
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { secColor, whiteColor } from '../../assets/js/styleCss';
import { WalletType, UserType } from '../../../utils/constant';

const useStyles = makeStyles({
	grid: {
		'& .MuiGrid-item': {
			margin: 'auto',
		},
	},
	btn: {
		color: whiteColor,
		backgroundColor: secColor,
		width: '100%',
		'&:hover': {
			backgroundColor: secColor,
			opacity: '0.8',
		},
	},
	infoText: {
		display: 'flex',
		alignItems: 'center',
		marginBottom: '15px',
		'& .MuiSvgIcon-root': {
			marginRight: '5px',
			fontSize: '18px',
		},
	},
});

const CreateWallet = () => {
	const classes = useStyles();
	const history = useHistory();
	const authContext = useContext(AuthContext);
	const { user } = authContext;

	useEffect(() => {
		document.title = 'PMT eWallet - Create Wallet';
	});

	//Alert State
	const [alert, setAlert] = useState(null);
	const removeAlert = () => setAlert(null);

	// Verify Wallet State
	const [userWallet, setUserWallet] = useState(null);

	const { isLoading: verifyingWallet } = useQuery(
		'verify-wallet',
		() => verifyWallet(userWallet.wallet),
		{
			enabled: !!userWallet,
			onSettled: (data, error) => {
				if (error) {
					setAlert({
						msg: loadError(error),
						type: 'error',
					});
				}
			},
			onSuccess: (data) => {
				queryClient.invalidateQueries('user-account');
				history.push(`/wallet/setup-pin/${userWallet.wallet}`);
			},
		}
	);

	// Bank State
	const [banks, setBanks] = useState(null);

	const { data: bankData } = useQuery('banks', getBanks, {
		onSettled: (data, error) => {
			if (error) {
				console.log(loadError(error));
				setAlert({
					msg: loadError(error),
					type: 'error',
				});
			}
		},
		onSuccess: (data) => {
			if (data && data.data.payload) {
				setBanks(data.data.payload);
			}
		},
	});

	if (bankData && bankData.data.payload > 0) {
		setBanks(bankData.data.payload);
	}

	const queryClient = useQueryClient();
	const { mutate, isLoading } = useMutation(createWallet, {
		onSettled: (data, error) => {
			if (error) {
				setAlert({
					msg: loadError(error),
					type: 'error',
				});
			}
		},
		onSuccess: (data) => {
			queryClient.invalidateQueries('user-account');
			setUserWallet(data.data.payload);
		},
	});

	const multipleAccountUser = ['E', 'M', 'P'];

	// Create Wallet State
	const [walletDetails, setWalletDetails] = useState({
		type: '',
		name: '',
		accountName: '',
		accountNumber: '',
		bank: '',
	});

	const { accountName, accountNumber, bank, type, name } = walletDetails;

	// Input Err State
	const [nameErr, setNameErr] = useState(null);
	const [accountNameErr, setAccountNameErr] = useState(null);
	const [typeErr, setTypeErr] = useState(null);
	const [accountNumberErr, setAccountNumberErr] = useState(null);
	const [bankErr, setbankErr] = useState(null);

	// Form Submit
	const handleSubmit = async (e) => {
		e.preventDefault();

		// Verify Input
		if (name === '') {
			setNameErr('Please specify wallet name');
		} else {
			setNameErr(null);
		}
		if (accountName === '') {
			setAccountNameErr('Please specify account name');
		} else {
			setAccountNameErr(null);
		}

		if (accountNumber === '') {
			setAccountNumberErr('Please specify account number');
		} else if (!/\d{10}/.test(accountNumber)) {
			setAccountNumberErr('Incorrect account number');
		} else {
			setAccountNumberErr(null);
		}

		if (bank === '') {
			setbankErr('Please Specify bank name');
		} else {
			setbankErr(null);
		}

		// Submit Form
		if (accountName !== '' && /\d{10}/.test(accountNumber) && bank !== '') {
			const payload = {
				...walletDetails,
				userType: user.type === 'REGULAR' ? 'C' : user.userType,
				user: user.id,
				type:
					user.userType === 'A'
						? WalletType.DEBIT
						: multipleAccountUser.includes(user?.userType)
						? type
						: 'BOTH',
			};
			if (user.enterprise) {
				payload.enterprise = user.enterprise;
				if (multipleAccountUser.includes(user?.userType) && type === '') {
					setTypeErr('Please Specify Wallet type');
				} else {
					setTypeErr('');
					mutate(payload);
				}
			} else {
				mutate(payload);
			}
		}
	};

	return (
		<Layout title='Create Wallet'>
			{alert && (
				<Alert msg={alert.msg} type={alert.type} closeAlert={removeAlert} />
			)}
			{verifyingWallet && <CircularProgress />}

			<Box flex='1' display='flex' padding='15px' flexDirection='column'>
				<Grid className={classes.grid} container>
					<Grid item xs={11} sm={8} md={6}>
						<Box marginTop='20px'>
							<Typography variant='body2' className={classes.infoText}>
								<InfoOutlinedIcon /> All fields is required
							</Typography>
							<form>
								<Box marginBottom='20px'>
									<TextField
										size='small'
										variant='outlined'
										label='Wallet name'
										error={nameErr && true}
										helperText={nameErr}
										fullWidth={true}
										value={name}
										onChange={(e) => {
											e.target.value !== '' && setNameErr(null);
											setWalletDetails({
												...walletDetails,
												name: e.target.value,
											});
										}}
									/>
								</Box>
								{multipleAccountUser.includes(user?.userType) && (
									<Box marginBottom='20px'>
										<Select
											size='small'
											variant='outlined'
											error={typeErr && true}
											helperText={typeErr}
											fullWidth={true}
											value={type}
											onChange={(e) => {
												e.target.value !== '' && setTypeErr(null);
												setWalletDetails({
													...walletDetails,
													type: e.target.value,
												});
											}}
										>
											<option value=''>Select account type</option>

											<option value={WalletType.CREDIT}>
												{WalletType.CREDIT}
											</option>
											<option value={WalletType.DEBIT}>
												{WalletType.DEBIT}
											</option>
										</Select>
									</Box>
								)}
								<Box marginBottom='20px'>
									<Select
										size='small'
										variant='outlined'
										error={bankErr ? true : false}
										helperText={bankErr}
										fullWidth={true}
										value={bank}
										onChange={(e) => {
											e.target.value !== '' && setTypeErr(null);
											setWalletDetails({
												...walletDetails,
												bank: e.target.value,
											});
										}}
									>
										<option value=''>Select bank</option>
										{banks ? (
											banks.map((bank, key) => (
												<option key={key} value={bank.id}>
													{bank.name}
												</option>
											))
										) : (
											<option>No banks</option>
										)}
									</Select>
								</Box>
								<Box marginBottom='20px'>
									<TextField
										size='small'
										variant='outlined'
										label='Account number'
										error={accountNumberErr && true}
										helperText={accountNumberErr}
										fullWidth={true}
										value={accountNumber}
										onChange={(e) => {
											e.target.value !== '' && setAccountNumberErr(null);
											setWalletDetails({
												...walletDetails,
												accountNumber: e.target.value,
											});
										}}
									/>
								</Box>
								<Box marginBottom='20px'>
									<TextField
										size='small'
										variant='outlined'
										label='Account name'
										error={accountNameErr && true}
										helperText={accountNameErr}
										fullWidth={true}
										value={accountName}
										onChange={(e) => {
											e.target.value !== '' && setAccountNameErr(null);
											setWalletDetails({
												...walletDetails,
												accountName: e.target.value,
											});
										}}
									/>
								</Box>
								<Button
									onClick={(e) => handleSubmit(e)}
									disabled={isLoading}
									type='submit'
									className={classes.btn}
								>
									Create
								</Button>
							</form>
						</Box>
					</Grid>
				</Grid>
			</Box>
		</Layout>
	);
};

export default CreateWallet;
