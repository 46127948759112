import axios from 'axios';
import setTokenHeader from '../../setTokenInHeader';

import { walletAPI } from '../..';

const getAccount = async () => {
  await setTokenHeader();
  return await axios.get(`${walletAPI}/accounts/me?populate=users`);
};

export default getAccount;
