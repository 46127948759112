import React, { useEffect } from 'react';
import Layout from '../layouts/Layout';
import Content from '../layouts/wallet/Deposit';
import ErrorBoundary from '../errorBoundaries';

const Deposit = () => {
  useEffect(() => {
    document.title = 'PMT eWallet - Deposit';
  });
  return (
    <ErrorBoundary>
      <Layout title='Deposit'>
        <Content />
      </Layout>
    </ErrorBoundary>
  );
};

export default Deposit;
