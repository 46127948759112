import axios from 'axios';

import { loginAPIs } from '../../index';

const loginUser = async (details) => {
	localStorage.setItem('user-type', JSON.stringify(details.userType));
	const filterAPI = loginAPIs.find((api) => details.userType === api.type);
	const url = `${filterAPI.url}/login`;
	delete details.userType;

	console.log(url);

	return await axios.post(url, details);
};

export default loginUser;
