import React, { useEffect } from 'react';

import Layout from '../layouts/Layout';
import User from '../layouts/wallet/User';
import QuickAction from '../layouts/wallet/QuickAction';
import Accounts from '../layouts/wallet/Accounts';
import Chart from '../layouts/wallet/Chart';

// Material-UI/Core
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { container } from '../assets/js/styleCss';

const useStyles = makeStyles({
	container: {
		...container,
	},
	card: {
		padding: '15px',
	},
});
const Home = () => {
	const classes = useStyles();

	  useEffect(() => {
    document.title = 'PMT eWallet - Dashboard';
  });

	return (
		<Layout title='Dashboard'>
			<Box>
				<User />
				<Box className={classes.container}>
					<Accounts />
					<QuickAction />
					<Chart />
				</Box>
			</Box>
		</Layout>
	);
};

export default Home;
