import React, { useState, useContext, useEffect } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import WalletContext from '../../../../../context/wallet/walletContext';
import TextField from '../../../customComponent/CustomInput';
import Select from '../../../customComponent/CustomSelect';
import styles from '../../styles';
import getBanks from '../../../../../api/handler/wallet/getBanks';
import updateWallet from '../../../../../api/handler/wallet/updateWallet';
import ToastAlert from '../../../alert/ToastAlert';
import Alert from '../../../alert/SuccessAlert';
import ConfirmUpdate from '../ConfirmUpdate';

// Material-ui/Core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Layout from '../../../Layout';
import loadError from '../../../../../api/loadErr';

const useStyles = makeStyles(styles);

const Bank = (props) => {
  const classes = useStyles();
  const walletContext = useContext(WalletContext);
  const { wallets } = walletContext;

  // Initiate UseHistory
  const { goBack } = useHistory();

  // Alert State
  const [alert, setAlert] = useState(null);
  const removeAlert = () => setAlert(null);
  const closeAlertAndRedirect = () => {
    setAlert(null);
    goBack();
  };

  // Display Confirm Modal
  const [isDisplayConfirmModal, setDisplayConfirmModal] = useState(false);
  const closeConfirmModal = () => setDisplayConfirmModal(false);

  // Bank State
  const queryClient = useQueryClient();
  const [banks, setBanks] = useState(null);
  useQuery('banks', getBanks, {
    enabled: !!wallets && wallets.length > 0,
    onSettled: (data, error) => {
      if (error) {
        setAlert({
          msg: loadError(error),
        });
      }
    },
    onSuccess: (data) => {
      setBanks(data.data.payload);
    },
  });

  // App State
  // Bank Detail
  const [bankDetails, setBankDetails] = useState({
    bank: '',
    accountName: '',
    accountNumber: '',
  });
  const [pin, setPin] = useState('');
  const { bank, accountName, accountNumber } = bankDetails;

  // Error state
  const [isBankErr, setBankErr] = useState(false);
  const [isAccountNameErr, setAccountNameErr] = useState(false);
  const [accountNumberErr, setAccountNumberErr] = useState(null);

  useEffect(() => {
    const walletId = props.match.params.id;
    if (wallets) {
      wallets.find(
        (wallet) =>
          wallet.wallet === walletId &&
          setBankDetails({
            bank: wallet?.bank.id || '',
            accountName: wallet?.accountName || '',
            accountNumber: wallet?.accountNumber || '',
          })
      );
    }
  }, [wallets, props.match.params.id]);

  // Handle Form Submittion
  const { isLoading, mutate } = useMutation(updateWallet, {
    onSettled: (data, error) => {
      if (error) {
        setAlert({
          msg: loadError(error),
          type: 'error',
        });
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries('user-account');
      setAlert({
        title: 'Wallet Settings Message',
        msg: 'Bank details updated successfully!',
        type: 'success',
      });

      // Clear Pin
      setPin('');
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    if (bank === '') {
      setBankErr(true);
    } else {
      setBankErr(false);
    }
    if (accountName === '') {
      setAccountNameErr(true);
    } else {
      setAccountNameErr(false);
    }
    if (accountNumber === '') {
      setAccountNumberErr('Bank number is required');
    } else if (!/^\d{10}$/.test(accountNumber)) {
      setAccountNumberErr('Invalid bank number');
    } else {
      setAccountNumberErr(false);
    }

    if (accountName !== '' && /^\d{10}$/.test(accountNumber)) {
      setDisplayConfirmModal(true);
    }
  };

  // Process Data
  const processData = () => {
    mutate({
      wallet: props.match.params.id,
      payload: {
        ...setBankDetails,
      },
    });
  };

  return (
    <Layout title='Settings'>
      {isDisplayConfirmModal && (
        <ConfirmUpdate
          pin={pin}
          setPin={setPin}
          isProcessing={isLoading}
          confirmUpdate={processData}
          close={closeConfirmModal}
        />
      )}
      {alert && alert.type === 'error' && (
        <ToastAlert
          msg={alert.msg}
          type={alert.type}
          closeAlert={removeAlert}
        />
      )}
      {alert && alert.type === 'success' && (
        <Alert
          msg={alert.msg}
          type={alert.type}
          removeAlert={closeAlertAndRedirect}
        />
      )}
      <Box className={classes.container}>
        <Box marginTop='15px' maxWidth='520px' margin='auto'>
          <Box>
            <Typography variant='h6'>Update Bank Details</Typography>
          </Box>
          <Box marginTop='10px'>
            <form onSubmit={(e) => handleSubmit(e)}>
              <Box marginBottom='15px'>
                <Select
                  error={isBankErr}
                  helperText={isBankErr && 'Select a bank'}
                  variant='outlined'
                  fullWidth={true}
                  size='small'
                  value={bank}
                  onChange={(e) => {
                    setBankDetails({
                      ...bankDetails,
                      bank: e.target.value,
                    });
                  }}
                >
                  <option value=''>Select bank</option>
                  {banks && banks.length > 0 ? (
                    banks.map((bank) => (
                      <option key={bank.id} value={bank.id}>
                        {bank.name}
                      </option>
                    ))
                  ) : (
                    <option>No banks</option>
                  )}
                </Select>
              </Box>

              <Box marginBottom='15px'>
                <TextField
                  error={isAccountNameErr}
                  helperText={isAccountNameErr && 'Account name is required'}
                  fullWidth={true}
                  size='small'
                  variant='outlined'
                  label='Account name'
                  value={accountName}
                  onChange={(e) => {
                    setBankDetails({
                      ...bankDetails,
                      accountName: e.target.value,
                    });
                  }}
                />
              </Box>
              <Box marginBottom='15px'>
                <TextField
                  error={accountNumberErr ? true : false}
                  helperText={accountNumberErr}
                  fullWidth={true}
                  size='small'
                  variant='outlined'
                  label='Account number'
                  value={accountNumber}
                  onChange={(e) => {
                    setBankDetails({
                      ...bankDetails,
                      accountNumber: e.target.value,
                    });
                  }}
                />
              </Box>
              <Button
                disabled={isLoading}
                type='submit'
                className={classes.btnBg}
              >
                {isLoading && (
                  <CircularProgress className={classes.circularProgress} />
                )}
                Update
              </Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default Bank;
