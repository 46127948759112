import React from 'react';
import ReactDOM from 'react-dom';
import ClientProvider from './utils/ClientProvider';

import App from './App';
import reportWebVitals from './reportWebVitals';

import AppState from './context/app/AppState';
import AuthState from './context/auth/AuthState';
import WalletState from './context/wallet/WalletState';

ReactDOM.render(
	<ClientProvider>
		<AuthState>
			<WalletState>
				<AppState>
					<App />
				</AppState>
			</WalletState>
		</AuthState>
	</ClientProvider>,
	document.getElementById('root')
);

reportWebVitals();
