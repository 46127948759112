import React from 'react';
import styles from './styles';

// Material-ui/Core
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(styles);

const Content = ({ title, children, style, isChange, handleChange }) => {
  const classes = useStyles();
  return (
    <Box style={style} className={classes.container}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        className={classes.header}
      >
        <Box>{title}</Box>{' '}
        {isChange && (
          <Button
            onClick={() => handleChange()}
            size='small'
            className={classes.closeBtn}
          >
            Change
          </Button>
        )}
      </Box>
      <Box className={classes.children}>{children}</Box>
    </Box>
  );
};

export default Content;
