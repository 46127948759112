import React, { useRef } from 'react';
import styles from './styles';
import numberWithComma from '../../utils/numberWithCommas';
import logo from '../../assets/img/peace-logo.png';
import bg from '../../assets/img/peace-logo.png';

import domtoimage from 'dom-to-image';

// MAterial-ui/Core
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

// Material-ui/Icon
import FavoriteIcon from '@material-ui/icons/Favorite';

const useStyles = makeStyles(styles);

const ConfirmTransfer = ({ close, amount, walletTo, walletFrom }) => {
	const classes = useStyles();

	const receiptEl = useRef(null);

	const handleDownload = () => {
		domtoimage
			.toJpeg(receiptEl.current, { quality: 0.95 })
			.then(function (dataUrl) {
				var link = document.createElement('a');
				link.download = 'pmt-transfer-receipt.jpeg';
				link.href = dataUrl;
				link.click();
			});
	};

	return (
		<Box className={classes.fixedContainer} position='fixed'>
			<Grid
				style={{
					height: '100vh',
				}}
				container
				justifyContent='center'
				alignItems='center'
			>
				<Grid className={classes.box} item xs={11} sm={7} md={4}>
					<Box
						padding='15px'
						ref={receiptEl}
						style={{
							background: `linear-gradient(90deg, rgba(250, 250, 250, 1), rgba(250, 250, 250, 0.8)), url('${bg}') repeat no-repeat `,
							backgroundSize: 'cover',
							backgroundPosition: 'center ',
						}}
					>
						<Box marginBottom='10px'>
							<Box>
								<Box
									margin='auto'
									marginBottom='10px'
									width='50px'
									height='50px'
								>
									<img style={{ width: '100%' }} src={logo} alt='peace-logo' />
								</Box>
								<Typography align='center' variant='h6'>
									PMT Wallet
								</Typography>
							</Box>
							<Typography
								style={{ marginTop: '10px' }}
								align='center'
								variant='body1'
							>
								Transfer receipt
							</Typography>
						</Box>
						<Divider />
						<Box marginTop='10px'>
							<Typography style={{ marginBottom: '10px' }} variant='body2'>
								<span>Transaction Date:</span> {'  '}
								<span>{new Date().toLocaleDateString()}</span>
							</Typography>

							<Typography
								style={{ marginBottom: '10px', marginTop: '10px' }}
								variant='body2'
							>
								<span>Transaction Type:</span> {'  '}
								<span>PMT Transfer</span>
							</Typography>
							<Typography
								style={{ marginBottom: '10px', marginTop: '10px' }}
								variant='body2'
							>
								<span>Amount:</span> {'  '}
								<span className={classes.amount}>
									&#8358;{'  '}
									{numberWithComma(amount)}
								</span>
							</Typography>
							<Typography
								style={{ marginBottom: '10px', marginTop: '10px' }}
								variant='body2'
							>
								<span>Source wallet:</span> {'  '}
								<span>{walletFrom && walletFrom.wallet}</span>
							</Typography>
							<Typography
								style={{ marginBottom: '10px', marginTop: '10px' }}
								variant='body2'
							>
								<span>Source wallet Name:</span> {'  '}
								<span>{walletFrom.name}</span>
							</Typography>
							<Typography
								style={{ marginBottom: '10px', marginTop: '10px' }}
								variant='body2'
							>
								<span>Beneficiary wallet:</span> {'  '}
								<span>{walletTo}</span>
							</Typography>
							<Divider />
							<Box
								marginTop='10px'
								display='flex'
								alignItems='center'
								justifyContent='center'
							>
								<FavoriteIcon className={classes.favoriteIcon} />
								<Typography
									style={{
										fontStyle: 'italic',
										fontSize: '12px',
										whiteSpace: 'no-wrap',
									}}
									variant='body2'
								>
									Powered by PMT
								</Typography>
							</Box>
						</Box>
					</Box>

					<Box
						marginTop='30px'
						display='flex'
						alignItems='center'
						justifyContent='center'
					>
						<Button
							size='small'
							style={{ marginRight: '10px' }}
							className={classes.btnInline}
							onClick={() => handleDownload()}
						>
							Download receipt
						</Button>
						<Button
							size='small'
							onClick={() => close()}
							className={classes.btnInline}
						>
							Done
						</Button>
					</Box>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ConfirmTransfer;
