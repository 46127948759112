import axios from 'axios';
import setTokenHeader from '../../setTokenInHeader';

import { walletAPI } from '../..';

const resendTransferOtp = async (id) => {
	await setTokenHeader();

	return await axios.get(`${walletAPI}/transfers/otp/${id}`);
};

export default resendTransferOtp;
