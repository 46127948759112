import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import WalletContext from '../../../../context/wallet/walletContext';
import TextField from '../../customComponent/CustomInput';
import Select from '../../customComponent/CustomSelect';
import Layout from '../../Layout';
import styles from '../styles';
import ConfirmUpdate from './ConfirmUpdate';
import Alert from '../../alert/SuccessAlert';
import retrieveSecurityQuestions from '../../../../api/handler/wallet/retrieveSecurityQuestions';
import updateWallet from '../../../../api/handler/wallet/updateWallet';
import ToastAlert from '../../alert/ToastAlert';

// Material-ui/Core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import loadError from '../../../../api/loadErr';

const useStyles = makeStyles(styles);

const SecurityQuestion = (props) => {
  const classes = useStyles();
  const walletContext = useContext(WalletContext);
  const { wallets } = walletContext;
  const { goBack } = useHistory();

  const [retrieveQuestionsData, setRetrieveQuestionsData] = useState(null);

  // get Questions
  useQuery('security question', retrieveSecurityQuestions, {
    enabled: !!wallets && wallets.length > 0,
    onSettled: (data, error) => {
      if (error) {
        setAlert({
          msg: loadError(error),
          type: 'error',
        });
      }
      if (data) {
        setRetrieveQuestionsData(data.data.payload);
      }
    },
  });

  // Modal State
  const [isDisplayModal, setDisplayModal] = useState(false);
  const closeModal = () => {
    setDisplayModal(false);
  };

  // Pin State
  const [pin, setPin] = useState('');

  //  Alert State
  const [alert, setAlert] = useState(null);
  const removeAlert = () => setAlert(null);

  // Close Alert Redirect
  const removeAndRedirectAlert = () => {
    setAlert(null);
    goBack();
  };

  // question1 Detail
  const [questionDetails, setQuestionDetails] = useState({
    question1: '',
    answer1: '',
    question2: '',
    answer2: '',
    question3: '',
    answer3: '',
  });
  const { question1, answer1, question2, answer2, question3, answer3 } =
    questionDetails;

  // Error state
  const [isQuestion1Err, setQestion1Err] = useState(false);
  const [isQuestion2Err, setQestion2Err] = useState(false);
  const [isQuestion3Err, setQestion3Err] = useState(false);

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(updateWallet, {
    onSettled: (data, error) => {
      if (error) {
        setAlert({
          msg: loadError(error),
          type: 'error',
        });
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries('user-account');
      // Clear State
      setPin('');
      setStep(0);
      setDisplayModal(false);
      setQuestionDetails({
        question1: '',
        answer1: '',
        question2: '',
        answer2: '',
        question3: '',
        answer3: '',
      });
      setCompleted({});
      setAlert({
        msg: 'Security question updated Successfully!',
        title: 'Updated Message',
        type: 'success',
      });
    },
  });

  // Handle Form Submittion
  const walletId = props.match.params.id;
  const handleSubmit = async () => {
    mutate({ payload: { ...questionDetails, pin }, wallet: walletId });
  };

  // Step State
  const [step, setStep] = useState(0);
  const steps = ['question one', 'question two', 'question three'];
  const [completed, setCompleted] = useState({});

  const handleBack = (e) => {
    e.preventDefault();
    const completedData = completed;
    const level = step - 1;
    const isFound = Object.keys(completedData).find(
      (value) => parseInt(value) === level
    );
    if (isFound) {
      delete completedData[level];
      setCompleted(completedData);
    }
    step > 0 && setStep(level);
  };

  const handleNext = (e) => {
    e.preventDefault();
    const newCompleted = completed;

    // Check for Step
    if (step === 0) {
      if (question1 !== '' && answer1 !== '') {
        newCompleted[step] = true;
        setCompleted(newCompleted);
        setStep(1);

        setQestion1Err(false);
      } else {
        setQestion1Err(true);
      }
    }

    if (step === 1) {
      if (question2 !== '' && answer2 !== '') {
        newCompleted[step] = true;
        setCompleted(newCompleted);
        setStep(2);
        setQestion2Err(false);
      } else {
        setQestion2Err(true);
      }
    }
    if (step === 2) {
      if (question2 !== '' && answer2 !== '') {
        newCompleted[step] = true;
        setCompleted(newCompleted);
        setDisplayModal(true);
        setQestion3Err(false);
      } else {
        setQestion3Err(true);
      }
    }
  };

  return (
    <Layout title='Settings'>
      {isDisplayModal && (
        <ConfirmUpdate
          pin={pin}
          setPin={setPin}
          isProcessing={isLoading}
          confirmUpdate={handleSubmit}
          close={closeModal}
        />
      )}
      {alert && alert.type === 'success' && (
        <Alert
          msg={alert.msg}
          title={alert.title}
          type={alert.type}
          removeAlert={removeAndRedirectAlert}
        />
      )}
      {alert && alert.type === 'error' && (
        <ToastAlert
          msg={alert.msg}
          type={alert.type}
          closeAlert={removeAlert}
        />
      )}
      <Box className={classes.container}>
        <Box marginTop='15px' maxWidth='520px' margin='auto'>
          <Box>
            <Typography variant='h6'>
              Acount Settings{' '}
              <small style={{ fontSize: '14px' }}>Security questions</small>
            </Typography>
          </Box>
          <Stepper className={classes.stepper} nonLinear activeStep={step - 1}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton completed={completed[index]}>{label}</StepButton>
              </Step>
            ))}
          </Stepper>
          <Box marginTop='10px'>
            <Box hidden={step !== 0}>
              {step === 0 && (
                <Box>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Box marginBottom='15px'>
                      <Select
                        error={isQuestion1Err}
                        helperText={isQuestion1Err && 'Select question'}
                        variant='outlined'
                        fullWidth={true}
                        size='small'
                        value={question1}
                        onChange={(e) => {
                          setQuestionDetails({
                            ...questionDetails,
                            question1: e.target.value,
                          });
                        }}
                      >
                        <option value=''>Select question one</option>
                        {retrieveQuestionsData &&
                        retrieveQuestionsData.length > 0 ? (
                          retrieveQuestionsData.map((question) => (
                            <option key={question.id} value={question.value}>
                              {question.value}
                            </option>
                          ))
                        ) : (
                          <option>No questions</option>
                        )}
                      </Select>
                    </Box>

                    <Box marginBottom='15px'>
                      <TextField
                        error={isQuestion1Err}
                        helperText={isQuestion1Err && 'Provid answer'}
                        fullWidth={true}
                        size='small'
                        variant='outlined'
                        label='Answer'
                        value={answer1}
                        onChange={(e) => {
                          setQuestionDetails({
                            ...questionDetails,
                            answer1: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Button
                      size='small'
                      type='button'
                      className={classes.btnInline}
                      onClick={handleNext}
                    >
                      next
                    </Button>
                  </form>
                </Box>
              )}
            </Box>
            <Box hidden={step !== 1}>
              {step === 1 && (
                <Box>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Box marginBottom='15px'>
                      <Select
                        error={isQuestion2Err}
                        helperText={isQuestion2Err && 'Select question'}
                        variant='outlined'
                        fullWidth={true}
                        size='small'
                        value={question2}
                        onChange={(e) => {
                          setQuestionDetails({
                            ...questionDetails,
                            question2: e.target.value,
                          });
                        }}
                      >
                        <option value=''>Select question two</option>
                        {retrieveQuestionsData &&
                        retrieveQuestionsData.length > 0 ? (
                          retrieveQuestionsData.map((question) => (
                            <option key={question.id} value={question.value}>
                              {question.value}
                            </option>
                          ))
                        ) : (
                          <option>No questions</option>
                        )}
                      </Select>
                    </Box>

                    <Box marginBottom='15px'>
                      <TextField
                        error={isQuestion2Err}
                        helperText={isQuestion2Err && 'Provide answer'}
                        fullWidth={true}
                        size='small'
                        variant='outlined'
                        label='Answer'
                        value={answer2}
                        onChange={(e) => {
                          setQuestionDetails({
                            ...questionDetails,
                            answer2: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Button
                      type='button'
                      className={classes.btnInline}
                      style={{ marginRight: '15px' }}
                      size='small'
                      onClick={handleBack}
                    >
                      back
                    </Button>
                    <Button
                      size='small'
                      type='button'
                      className={classes.btnInline}
                      onClick={handleNext}
                    >
                      next
                    </Button>
                  </form>
                </Box>
              )}
            </Box>
            <Box hidden={step !== 2}>
              {step === 2 && (
                <Box>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <Box marginBottom='15px'>
                      <Select
                        error={isQuestion3Err}
                        helperText={isQuestion3Err && 'Select question'}
                        variant='outlined'
                        fullWidth={true}
                        size='small'
                        value={question3}
                        onChange={(e) => {
                          setQuestionDetails({
                            ...questionDetails,
                            question3: e.target.value,
                          });
                        }}
                      >
                        <option value=''>Select question three</option>
                        {retrieveQuestionsData &&
                        retrieveQuestionsData.length > 0 ? (
                          retrieveQuestionsData.map((question) => (
                            <option key={question.id} value={question.value}>
                              {question.value}
                            </option>
                          ))
                        ) : (
                          <option>No questions</option>
                        )}
                      </Select>
                    </Box>

                    <Box marginBottom='15px'>
                      <TextField
                        error={isQuestion3Err}
                        helperText={isQuestion3Err && 'Provide answer'}
                        fullWidth={true}
                        size='small'
                        variant='outlined'
                        label='Answer'
                        value={answer3}
                        onChange={(e) => {
                          setQuestionDetails({
                            ...questionDetails,
                            answer3: e.target.value,
                          });
                        }}
                      />
                    </Box>
                    <Button
                      type='button'
                      className={classes.btnInline}
                      style={{ marginRight: '15px' }}
                      size='small'
                      onClick={handleBack}
                    >
                      back
                    </Button>
                    <Button
                      size='small'
                      type='button'
                      className={classes.btnInline}
                      onClick={(e) => handleNext(e)}
                    >
                      submit
                    </Button>
                  </form>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default SecurityQuestion;
