import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import AuthContext from '../../../context/auth/authContext';
import CustomInput from '../../layouts/customComponent/CustomInput';
import cx from 'classnames';
import Authbar from '../../layouts/header/Authbar';
import styles from './styles';
import Alert from '../../layouts/alert/ToastAlert';

// Material-UI/Core
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';

// Material-UI/Icon
import LockIcon from '@material-ui/icons/Lock';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(styles);

const UpdatePassword = () => {
	const classes = useStyles();
	const authContext = useContext(AuthContext);
	const { updateUser, errorMessage } = authContext;
	const history = useHistory();

	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [passwordErr, setPasswordErr] = useState(null);
	const [confirmPasswordErr, setConfirmPasswordErr] = useState(null);

	// Target Pasword Input
	const [isDisplayPassword, setIsDisplayPassword] = useState(false);
	const [isDisplayconfirmPassword, setIsDisplayConfirmPassword] =
		useState(false);

	// Toggle Password
	const togglePassword = () => {
		setIsDisplayPassword(!isDisplayPassword);
	};
	// Toggle Confirm password
	const toggleConfirmPassword = () => {
		setIsDisplayConfirmPassword(!isDisplayconfirmPassword);
	};

	//Alert State
	const [alert, setAlert] = useState(null);
	const removeAlert = () => setAlert(null);

	useEffect(() => {
		if (errorMessage) {
			setAlert({ msg: errorMessage, type: 'error' });
			setIsProcessing(false);
		}
	}, [errorMessage]);

	const [isProcessing, setIsProcessing] = useState(false);
	const handleUpdate = async (e) => {
		e.preventDefault();
		if (password === '') {
			setPasswordErr('OTP Is require');
		}
		if (confirmPassword !== password) {
			setConfirmPasswordErr('Password do no match');
		}

		const userContact = JSON.parse(localStorage.getItem('otp-contact'));

		if (password !== '' && confirmPassword !== '' && userContact) {
			setIsProcessing(true);
			const res = await updateUser({ password });
			if (res) {
				if (res.success) {
					setIsProcessing(false);
					await updateUser({ password, ...userContact });
					setAlert({
						type: 'success',
						msg: 'Password updated successfully!',
					});
					history.push('/');
				}
			}
		}
	};

	return (
		<>
			<Authbar />
			{alert && (
				<Alert msg={alert.msg} type={alert.type} closeAlert={removeAlert} />
			)}
			<div className={classes.container}>
				<Grid container className={`${classes.gridContainer}`}>
					<Grid className={classes.gridItem} item xs={11} sm={8} md={4}>
						<Typography
							style={{ marginBottom: '50px' }}
							className={classes.title}
							variant='h6'
						>
							New password
						</Typography>
						<form onSubmit={handleUpdate} className={cx(classes.formContainer)}>
							<CustomInput
								style={{ marginBottom: '20px' }}
								label='Password'
								variant='outlined'
								value={password}
								error={passwordErr ? true : false}
								helperText={passwordErr}
								type={isDisplayPassword ? 'text' : 'password'}
								fullWidth={true}
								startAdornment={
									<InputAdornment position='start'>
										<LockIcon className={classes.formIcon} />
									</InputAdornment>
								}
								endAdornment={
									<InputAdornment position='end'>
										{isDisplayPassword ? (
											<VisibilityOffIcon
												className={classes.visibilityIcon}
												onClick={() => togglePassword()}
											/>
										) : (
											<VisibilityIcon
												className={classes.visibilityIcon}
												onClick={() => togglePassword()}
											/>
										)}
									</InputAdornment>
								}
								onChange={(e) => setPassword(e.target.value)}
							/>
							<CustomInput
								style={{ marginBottom: '10px' }}
								label='Confirm password'
								variant='outlined'
								value={confirmPassword}
								error={confirmPasswordErr ? true : false}
								helperText={confirmPasswordErr}
								type={isDisplayconfirmPassword ? 'text' : 'password'}
								fullWidth={true}
								startAdornment={
									<InputAdornment position='start'>
										<LockIcon className={classes.formIcon} />
									</InputAdornment>
								}
								endAdornment={
									<InputAdornment position='end'>
										{isDisplayconfirmPassword ? (
											<VisibilityOffIcon
												className={classes.visibilityIcon}
												onClick={() => toggleConfirmPassword()}
											/>
										) : (
											<VisibilityIcon
												className={classes.visibilityIcon}
												onClick={() => toggleConfirmPassword()}
											/>
										)}
									</InputAdornment>
								}
								onChange={(e) => setConfirmPassword(e.target.value)}
							/>
							<Button
								disabled={isProcessing}
								type='submit'
								className={classes.btn}
							>
								{isProcessing && (
									<CircularProgress className={classes.circularProgress} />
								)}
								update
							</Button>
						</form>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default UpdatePassword;
