import axios from 'axios';
import setTokenHeader from '../../setTokenInHeader';
import { walletAPI } from '../..';

const getBank = async () => {
	await setTokenHeader();
	return await axios.get(`${walletAPI}/banks?sort=name`);
};

export default getBank;
