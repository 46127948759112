import React, { useState } from 'react';
import styles from './styles';
// import PersonalInformation from './PersonalInfo';
import Account from './account';
import Alert from '../alert/SuccessAlert';
import ToastAlert from '../alert/ToastAlert';

// Material-ui/Core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(styles);

const Index = () => {
	const classes = useStyles();
	const [alert, setAlert] = useState(null);
	const removeAlert = () => setAlert(null);

	return (
		<Box marginTop='20px' className={classes.container}>
			{alert && alert.type === 'success' && (
				<Alert
					title={alert.title}
					msg={alert.msg}
					type={alert.type}
					removeAlert={removeAlert}
				/>
			)}
			{alert && alert.type === 'error' && (
				<ToastAlert
					msg={alert.msg}
					type={alert.type}
					closeAlert={removeAlert}
				/>
			)}
			<Account setAlert={setAlert} />
		</Box>
	);
};

export default Index;
