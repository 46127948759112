import React, { useEffect } from 'react';
import Layout from '../layouts/Layout';
import Content from '../layouts/wallet/AllTransactions';

const Transaction = () => {
  useEffect(() => {
    document.title = 'PMT eWallet - Transaction';
  });
  return (
    <Layout title='Transaction History'>
      <Content />
    </Layout>
  );
};

export default Transaction;
