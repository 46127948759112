import React, { useState, useContext } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import WalletContext from '../../../context/wallet/walletContext';
import AuthContext from '../../../context/auth/authContext';
import styles from './styles';
import numberWithComma from '../../utils/numberWithCommas';
import uuid from 'react-uuid';
import { usePaystackPayment } from 'react-paystack';
import Alert from '../alert/SuccessAlert';
import InfoAlert from '../alert/ToastAlert';
import cx from 'classnames';
import createDeposit from '../../../api/handler/wallet/createDeposit';
import loadError from '../../../api/loadErr';
import CircularProgress from '../spinner/CircularProgress';
import Content from './components/Content';
import CustomInput from '../customComponent/CustomInput';

// MAterial-ui/Core
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Material-ui/Icon
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(styles);

const Deposit = () => {
  const classes = useStyles();
  const walletContext = useContext(WalletContext);
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const { wallets } = walletContext;

  const queryClient = useQueryClient();

  // Wallet Amount State
  const [amount, setAmount] = useState('');
  const [walletTo, setWalletTo] = useState('');
  const [isAmountErr, setAmountErr] = useState(false);
  const [isWalletErr, setWalletErr] = useState(false);

  // Alert State
  const [alert, setAlert] = useState(null);
  const removeAlert = () => setAlert(null);

  const { isLoading, mutate } = useMutation(createDeposit, {
    onSettled: (data, error) => {
      if (error) {
        setAlert({
          msg: loadError(error),
          type: 'error',
        });
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries('user-account');
      setAlert({
        type: 'success',
        msg: 'Deposit was created successfully!',
        title: 'Deposit Message',
      });
      setAmount('');
      setWalletTo('');
    },
  });

  // Paystack Config
  const config = {
    reference: `ref-${uuid()}`,
    email: user && user.email,
    amount: amount * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_KEY,
    metadata: {
      custom_fields: [
        {
          display_name: 'Customer ID',
          variable_name: 'customer_id',
          value: user && user._id,
        },
        {
          display_name: 'Customer Name',
          variable_name: 'customer_name',
          value: user && `${user.surname} ${user.otherName}`,
        },
      ],
    },
  };
  // Initialize payStack
  const initializePayment = usePaystackPayment(config);

  // Handler for Paystack Success
  const onSuccess = (reference) => {
    const paymentDetails = {
      amount,
      walletTo,
      gateway: { currency: 'NGN' },
      paymentGateway: 'PAYSTACK',
      paymentMethod: 'GATEWAY',
      trxref: reference.trxref,
      // @ Change Wallet
    };
    mutate(paymentDetails);
  };

  // Handler for Paystack Failure
  const onClose = () => {
    setAlert({
      msg: 'Transaction cancel',
      type: 'info',
    });
  };

  // Handle Select Wallet
  const handleSelectWallet = (wallet) => {
    setWalletErr(false);
    setWalletTo(wallet);
  };

  // Handle Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    const amountReg = /[\d+]/;
    if (wallets && wallets.length > 0) {
      
      if (!amountReg.test(amount)) {
        setAmountErr(true);
      } else {
        setAmountErr(false);
      }
      if (walletTo === '') {
        setWalletErr(true);
      } else {
        setWalletErr(false);
      }

      if ((parseInt(amount, 10) < 1000)) {
        setAlert({
          type: 'error',
          msg: 'Amount must not be lest 1000',
        });
        setAmountErr(true);
      } else {
        setAmountErr(false);
        if (amountReg.test(amount) && walletTo !== '') {
          initializePayment(onSuccess, onClose);
        }
      }
    } else {
      setAlert({
        type: 'info',
        msg: 'Unable to create deposit. Please setup wallet',
      });
    }
  };

  return (
    <Box className={classes.depositContainer}>
      {isLoading && <CircularProgress />}
      {alert && alert.type === 'info' && (
        <InfoAlert msg={alert.msg} type={alert.type} closeAlert={removeAlert} />
      )}
      {alert && alert.type === 'error' && (
        <InfoAlert msg={alert.msg} type={alert.type} closeAlert={removeAlert} />
      )}
      {alert && alert.type === 'success' && (
        <Alert
          msg={alert.msg}
          title={alert.title}
          type={alert.type}
          removeAlert={removeAlert}
        />
      )}
      <Box maxWidth='540px' style={{ margin: '20px auto' }}>
        <form onSubmit={handleSubmit}>
          <Box>
            <Content
              style={{
                marginBottom: '30px',
              }}
              title='Specify amount'
            >
              <CustomInput
                error={isAmountErr}
                helperText={isAmountErr && 'Amount is required'}
                variant='outlined'
                fullWidth={true}
                size='small'
                type='number'
                value={amount}
                onChange={(e) => {
                  e.target.value.length > 1000 && setAmountErr(false);
                  setAmount(e.target.value);
                }}
              />
            </Content>
            <Content
              style={{
                marginBottom: '30px',
              }}
              title='Select Wallet Account'
            >
              {wallets && wallets.length > 0 ? (
                <Typography
                  className={cx({ [classes.errorText]: isWalletErr })}
                  variant='body2'
                >
                  {isWalletErr && '*Select wallet to deposit into'}
                </Typography>
              ) : (
                <Typography variant='body2'>No Wallet(s)</Typography>
              )}
              <Box
                className={cx(classes.walletWrapper, {
                  [classes.walletGrid]: wallets && wallets.length > 1,
                })}
              >
                {wallets &&
                  wallets.map((wallet) => (
                    <Button
                      style={{
                        textTransform: 'capitalize',
                      }}
                      onClick={() => handleSelectWallet(wallet.wallet)}
                      key={wallet.id}
                    >
                      <Box
                        position='relative'
                        display='flex'
                        alignItems='center'
                      >
                        {wallet.wallet === walletTo && (
                          <Box position='absolute' right='-15px' top='-5px'>
                            <CheckCircleIcon className={classes.checked} />
                          </Box>
                        )}
                        <Box marginRight='10px'>
                          <AccountBalanceWalletIcon />
                        </Box>
                        <Box>
                          <Typography align='left' variant='body2'>
                            Wallet ID: {wallet.wallet}
                          </Typography>
                          <Typography align='left' variant='body2'>
                            Balance: &#8358;{'  '}
                            {numberWithComma(wallet ? wallet.balance : '-')}
                          </Typography>
                          <Typography align='left' variant='body2'>
                            Type:{'  '}
                            {numberWithComma(wallet ? wallet.type : '-')}
                          </Typography>
                        </Box>
                      </Box>
                    </Button>
                  ))}
              </Box>
            </Content>
            <Box marginTop='20px'>
              <Button
                disabled={isLoading}
                type='submit'
                className={classes.btnBg}
              >
                Deposit{' '}
                <span style={{ marginLeft: '10px' }}>
                  &#8358;{amount !== '' ? numberWithComma(amount) : '0'}
                </span>
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Deposit;
