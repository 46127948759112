import React, { useEffect } from 'react';
import Layout from '../layouts/Layout';
import Content from '../layouts/settings';

const Setting = () => {
  useEffect(() => {
    document.title = 'PMT eWallet - Setting';
  });
  return (
    <Layout title='Settings'>
      <Content />
    </Layout>
  );
};

export default Setting;
