import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles';

// Material-ui/Core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

// Material-ui/Icon
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles(styles);

const WalletName = ({ wallet }) => {
  const classes = useStyles();
  return (
    <>
      <Box
        marginTop='20px'
        marginBottom='30px'
        display='flex'
        alignItems='center'
      >
        <Typography variant='body1'>Wallet name: {wallet.name}</Typography>
        <Link
          style={{ fontFamily: 'inherit', marginLeft: '20px' }}
          className={classes.link}
          to={`/settings/wallet/name/change/${wallet.wallet}`}
        >
          <EditIcon
            style={{
              fontSize: '18px',
              color: 'inherit',
            }}
          />
        </Link>
      </Box>
    </>
  );
};

export default WalletName;
