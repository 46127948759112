import React from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

import { secColor } from '../../assets/js/styleCss';

const styles = makeStyles({
	container: {
		display: 'flex',
		alignItems: 'center',
	},
	checkbox: {
		padding: '0px',
		marginRight: '5px',
		'&.Mui-checked': {
			color: secColor,
		},
	},
});

const CustomCheckbox = (props) => {
	const classes = styles();
	const { label, style, onChange, checked } = props;
	return (
		<div style={style} className={classes.container}>
			<Checkbox
				onChange={onChange}
				checked={checked}
				className={classes.checkbox}
			/>
			{label && <Typography variant='body2'>{label}</Typography>}
		</div>
	);
};

export default CustomCheckbox;
