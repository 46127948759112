import axios from 'axios';
import setTokenHeader from '../../setTokenInHeader';

import { walletAPI } from '../..';

const verifyWallet = async (id) => {
	await setTokenHeader();
	return await axios.get(`${walletAPI}/accounts/verify/${id}`);
};

export default verifyWallet;
