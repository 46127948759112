import React, { useState, useContext } from 'react';
import AuthContext from '../../../../context/auth/authContext';
import styles from '../styles';
import TextField from '../../customComponent/CustomInput';
import numberWithComma from '../../../utils/numberWithCommas';

// MAterial-ui/Core
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';

// Material-ui/Icon
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(styles);

const PinForm = ({
	close,
	amount,
	selectedWallet,
	pin,
	setPin,
	processWithdraw,
	isProcessing,
}) => {
	const classes = useStyles();
	const authContext = useContext(AuthContext);
	const { user } = authContext;

	//  App State

	const [isDisplayPassword, setIsDisplayPassword] = useState(false);

	// Toggle Password
	const togglePassword = () => {
		setIsDisplayPassword(!isDisplayPassword);
	};

	// Pin Err
	const [isPinErr, setPinErr] = useState(false);

	const confrimPin = (e) => {
		e.preventDefault();
		if (pin === '') {
			setPinErr(true);
		} else {
			setPinErr(false);
		}

		if (pin !== '') {
			processWithdraw();
		}
	};

	return (
		<Box className={classes.fixedContainer} position='fixed'>
			<Grid
				className={classes.formGrid}
				container
				justifyContent='center'
				alignItems='center'
			>
				<Grid item xs={11} sm={7} md={4}>
					<form onSubmit={confrimPin} style={{ backgroundColor: 'white' }}>
						<Box
							display='flex'
							alignItems='center'
							justifyContent='space-between'
							marginBottom='20px'
						>
							<Typography variant='h6'>Confirm Withdraw</Typography>
							<IconButton onClick={() => close()} size='small'>
								<CloseIcon />
							</IconButton>
						</Box>
						<Box marginBottom='20px'>
							<Typography
								style={{ marginBottom: '10px' }}
								className={classes.textFlex}
								variant='body2'
							>
								<span>From:</span>
								<span>
									{user &&
										`${user.surname} ${user.otherName}, ${selectedWallet.wallet}`}
								</span>
							</Typography>
							<Divider />
							<Typography
								style={{ marginBottom: '10px', marginTop: '10px' }}
								className={classes.textFlex}
								variant='body2'
							>
								<span>Bank:</span>
								<span>
									{selectedWallet ? (
										<span style={{ display: 'flex', flexDirection: 'column' }}>
											<span style={{ marginBottom: '5px' }}>
												{selectedWallet.bank.name}
											</span>
											<small>
												Account number: {selectedWallet.accountNumber}
											</small>
										</span>
									) : (
										'-'
									)}
								</span>
							</Typography>
							<Divider />
							<Typography
								style={{ marginBottom: '10px', marginTop: '10px' }}
								className={classes.textFlex}
								variant='body2'
							>
								<span>Date:</span>
								<span>{new Date().toLocaleDateString()}</span>
							</Typography>
							<Divider />
							<Typography
								style={{ marginBottom: '10px', marginTop: '10px' }}
								className={classes.textFlex}
								variant='body2'
							>
								<span>Amount:</span>
								<span className={classes.amount}>
									&#8358;{numberWithComma(amount)}
								</span>
							</Typography>
						</Box>
						<Box marginBottom='10px'>
							<TextField
								label='Wallet pin'
								error={isPinErr}
								helperText={isPinErr ? 'Pin is required' : ''}
								fullWidth={true}
								size='small'
								type={isDisplayPassword ? 'text' : 'password'}
								variant='outlined'
								value={pin}
								onChange={(e) => setPin(e.target.value)}
								inputProps={{
									maxLength: 5,
									minLength: 0,
								}}
								endAdornment={
									<InputAdornment position='end'>
										{isDisplayPassword ? (
											<VisibilityOffIcon
												className={classes.visibilityIcon}
												onClick={() => togglePassword()}
											/>
										) : (
											<VisibilityIcon
												className={classes.visibilityIcon}
												onClick={() => togglePassword()}
											/>
										)}
									</InputAdornment>
								}
							/>
						</Box>
						<Button
							disabled={isProcessing}
							type='submit'
							className={classes.btnBg}
						>
							confirm
						</Button>
					</form>
				</Grid>
			</Grid>
		</Box>
	);
};

export default PinForm;
