import React, { useContext, useState, useEffect } from 'react';
import WalletContext from '../../../../context/wallet/walletContext';
import styles from '../styles';
import numberWithComma from '../../../utils/numberWithCommas';

// MAterial-ui/Core
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

// Material-ui/Icon
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(styles);

const TransactionModal = ({
  close,
  transaction,
  displayConfirmTransfer,
  isProcessing,
  displayOtpModal,
}) => {
  const classes = useStyles();
  const walletContext = useContext(WalletContext);
  const { wallets } = walletContext;

  const [walletIds, setWalletIds] = useState([]);

  useEffect(
    () => {
      const ids = [];
      if (wallets) {
        wallets.forEach((wallet) => ids.push(wallet.wallet));
      }
      setWalletIds(ids);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <Box className={classes.fixedContainer} position='fixed'>
      <Grid
        className={classes.formGrid}
        container
        justifyContent='center'
        alignItems='center'
      >
        <Grid item xs={11} sm={7} md={4}>
          <Box
            padding='20px'
            bgcolor='white'
            borderRadius='5px'
            className={classes.gridContent}
          >
            <Box
              display='flex'
              alignItems='center'
              justifyContent='space-between'
              marginBottom='20px'
            >
              <Typography style={{ textTransform: 'capitalize' }} variant='h6'>
                {transaction.type === 'D'
                  ? 'Deposit'
                  : transaction.type === 'W'
                  ? 'Withdraw'
                  : 'Transfer'}{' '}
                Details
              </Typography>
              <IconButton onClick={() => close()} size='small'>
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
            </Box>
            <Box marginBottom='20px'>
              {transaction.transfer && (
                <>
                  <Typography
                    style={{ marginBottom: '10px' }}
                    className={classes.textFlex}
                    variant='body2'
                  >
                    <span>Transaction ref:</span>
                    <span>{transaction.transfer.trxref}</span>
                  </Typography>
                  <Divider />
                </>
              )}
              {(transaction.type === 'T' || transaction.type === 'W') &&
                transaction.walletFrom && (
                  <>
                    <Typography
                      style={{ marginBottom: '10px', marginTop: '10px' }}
                      className={classes.textFlex}
                      variant='body2'
                    >
                      <span>Wallet from:</span>
                      <span>{transaction.walletFrom}</span>
                    </Typography>
                    <Divider />
                  </>
                )}
              {transaction.accountTo && transaction.type === 'D' && (
                <>
                  <Typography
                    style={{ marginBottom: '10px', marginTop: '10px' }}
                    className={classes.textFlex}
                    variant='body2'
                  >
                    <span>Wallet:</span>
                    <span>
                      <span
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <span style={{ marginBottom: '5px' }}>
                          Name: {transaction.accountTo.name}
                        </span>
                        <small>Wallet ID: {transaction.accountTo.wallet}</small>
                      </span>
                    </span>
                  </Typography>
                  <Divider />
                </>
              )}
              {transaction.accountTo && transaction.type !== 'D' && (
                <>
                  <Typography
                    style={{ marginBottom: '10px', marginTop: '10px' }}
                    className={classes.textFlex}
                    variant='body2'
                  >
                    <span>Transfer to:</span>
                    <span>
                      <span
                        style={{ display: 'flex', flexDirection: 'column' }}
                      >
                        <span style={{ marginBottom: '5px' }}>
                          {transaction.accountTo.name}
                        </span>
                        <small>Wallet to: {transaction.accountTo.wallet}</small>
                      </span>
                    </span>
                  </Typography>
                  <Divider />
                </>
              )}
              {(transaction.transfer || transaction.withdraw) && (
                <>
                  <Typography
                    style={{ marginBottom: '10px', marginTop: '10px' }}
                    className={classes.textFlex}
                    variant='body2'
                  >
                    <span>Status:</span>
                    <span>
                      {transaction.transfer.status ||
                        transaction.withdraw.status}
                    </span>
                  </Typography>
                  <Divider />
                </>
              )}

              <Typography
                style={{ marginBottom: '10px', marginTop: '10px' }}
                className={classes.textFlex}
                variant='body2'
              >
                <span>Date:</span>
                <span>
                  {new Date(transaction.createdAt).toLocaleDateString()}
                </span>
              </Typography>
              <Divider />
              {transaction.transfer && (
                <>
                  <Typography
                    style={{ marginBottom: '10px', marginTop: '10px' }}
                    className={classes.textFlex}
                    variant='body2'
                  >
                    <span>Narration:</span>
                    <span>{transaction.transfer.narration}</span>
                  </Typography>
                  <Divider />
                </>
              )}
              <Typography
                style={{ marginTop: '10px', marginBottom: '0px' }}
                className={classes.textFlex}
                variant='body2'
              >
                <span>Amount:</span>
                <span className={classes.amount}>
                  &#8358;{numberWithComma(transaction.amount)}
                </span>
              </Typography>
            </Box>
            <Box>
              {transaction.transfer &&
                transaction.otp &&
                transaction.type === 'T' &&
                transaction.transfer.status === 'PENDING' && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Button
                      onClick={() => displayOtpModal('APPROVE')}
                      className={classes.btnBg}
                    >
                      Complete Transfer
                    </Button>
                    <Button
                      disabled={isProcessing}
                      style={{ marginTop: '10px' }}
                      onClick={() => displayConfirmTransfer('CANCEL')}
                      className={classes.btnBgCancel}
                    >
                      Cancel Transfer
                    </Button>
                  </Box>
                )}
              {transaction.transfer &&
                ['S', 'L', 'M'].includes(transaction.type) &&
                walletIds.includes(transaction.transfer.walletFrom) &&
                transaction.transfer.status === 'PENDING' && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Button
                      onClick={() =>
                        displayConfirmTransfer('APPROVE', 'Confirm Transfer')
                      }
                      className={classes.btnBg}
                    >
                      Confirm Transfer
                    </Button>
                    <Button
                      disabled={isProcessing}
                      style={{ marginTop: '10px' }}
                      onClick={() =>
                        displayConfirmTransfer('CANCEL', 'Cancel Transfer')
                      }
                      className={classes.btnBgCancel}
                    >
                      Cancel Transfer
                    </Button>
                  </Box>
                )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TransactionModal;
