import React, { useState, useContext } from 'react';
import WalletContext from '../../../context/wallet/walletContext';
import { useQueryClient, useMutation } from 'react-query';
import styles from './styles';
import numberWithComma from '../../utils/numberWithCommas';
import ConfirmWithdraw from './modal/ConfirmWithdraw';
import Alert from '../alert/SuccessAlert';
import InfoAlert from '../alert/ToastAlert';
import cx from 'classnames';
import createWithdraw from '../../../api/handler/wallet/createWithdraw';
import loadError from '../../../api/loadErr';
import Content from './components/Content';
import CustomInput from '../customComponent/CustomInput';
import CircularProgress from '../spinner/CircularProgress';

// MAterial-ui/Core
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// Material-ui/Icon
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(styles);

const Withdraw = () => {
  const classes = useStyles();
  const walletContext = useContext(WalletContext);
  const { wallets } = walletContext;

  // Initiate QueryClient
  const queryClient = useQueryClient();

  //  App State
  const [amount, setAmount] = useState('');
  const [amountErr, setAmountErr] = useState(null);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [isWalletErr, setWalletErr] = useState(false);
  const [pin, setPin] = useState('');

  // Display Pin Form
  const [isDisplayPinForm, setIsDisplayPinForm] = useState(false);

  // Alert State
  const [alert, setAlert] = useState(null);
  const removeAlert = () => setAlert(null);

  // Handle Submit
  const amountRex = /^\d+(\.\d{2})?$/;
  const handleSubmit = (e) => {
    e.preventDefault();
    if (wallets && wallets.length > 0) {
      if (!amountRex.test(amount)) {
        setAmountErr('Invalid amount');
      } else if (amount === '0') {
        setAmountErr('Please specify amount');
      } else {
        setAmountErr(null);
      }

      // Wallet Check
      if (!selectedWallet) {
        setWalletErr(true);
      } else {
        setWalletErr(false);
      }

      if (amountRex.test(amount) && amount !== '0' && selectedWallet) {
        setIsDisplayPinForm(true);
        setAmountErr(null);
      }
    } else {
      setAlert({
        type: 'info',
        msg: 'Unable to create withdraw. Please setup wallet',
      });
    }
  };

  const { mutate, isLoading } = useMutation(createWithdraw, {
    onSettled: (data, error) => {
      if (error) {
        setAlert({
          msg: loadError(error),
          type: 'error',
        });
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries('transactions');
      setAlert({
        title: 'Widthdraw',
        msg: data.data.payload.message,
        type: 'success',
      });
      // Clear State
      setPin('');
      setAmount('');
      setIsDisplayPinForm(false);
      setSelectedWallet('');
    },
  });

  const processWithdraw = async () => {
    mutate({
      amount,
      pin,
      walletFrom: selectedWallet.wallet,
    });
  };

  const handleClose = () => setIsDisplayPinForm(false);

  return (
    <Box className={classes.depositContainer}>
      {isLoading && <CircularProgress />}
      {alert && alert.type === 'info' && (
        <InfoAlert msg={alert.msg} type={alert.type} closeAlert={removeAlert} />
      )}
      {alert && alert.type === 'error' && (
        <InfoAlert msg={alert.msg} type={alert.type} closeAlert={removeAlert} />
      )}
      {alert && alert.type === 'success' && (
        <Alert
          title={alert.title}
          msg={alert.msg}
          type={alert.type}
          removeAlert={removeAlert}
        />
      )}
      {isDisplayPinForm && (
        <ConfirmWithdraw
          pin={pin}
          isProcessing={isLoading}
          setPin={setPin}
          processWithdraw={processWithdraw}
          amount={amount}
          selectedWallet={selectedWallet}
          close={handleClose}
        />
      )}
      <Box maxWidth='540px' style={{ margin: '20px auto' }}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <Box>
            <Content
              style={{
                marginBottom: '30px',
              }}
              title='Specify amount'
            >
              <CustomInput
                error={amountErr}
                helperText={amountErr && 'Amount is required'}
                variant='outlined'
                fullWidth={true}
                size='small'
                type='number'
                value={amount}
                onChange={(e) => {
                  e.target.value.length > 0 && setAmountErr(false);
                  setAmount(e.target.value);
                }}
              />
            </Content>
            <Content
              style={{
                marginBottom: '30px',
              }}
              title='Select Wallet Account'
            >
              {wallets && wallets.length > 0 ? (
                <Typography
                  className={cx({ [classes.errorText]: isWalletErr })}
                  variant='body2'
                >
                  {isWalletErr && '*Select wallet to deposit into'}
                </Typography>
              ) : (
                <Typography variant='body2'>No Wallet(s)</Typography>
              )}
              <Box
                className={cx(classes.walletWrapper, {
                  [classes.walletGrid]: wallets && wallets.length > 1,
                })}
              >
                {wallets &&
                  wallets.map((wallet) => (
                    <Button
                      style={{
                        textTransform: 'capitalize',
                      }}
                      onClick={() => setSelectedWallet(wallet)}
                      key={wallet.id}
                    >
                      <Box
                        position='relative'
                        display='flex'
                        alignItems='center'
                      >
                        {selectedWallet &&
                          wallet.wallet === selectedWallet.wallet && (
                            <Box position='absolute' right='-15px' top='-5px'>
                              <CheckCircleIcon className={classes.checked} />
                            </Box>
                          )}
                        <Box marginRight='10px'>
                          <AccountBalanceWalletIcon />
                        </Box>
                        <Box>
                          <Typography align='left' variant='body2'>
                            Wallet ID: {wallet.wallet}
                          </Typography>
                          <Typography align='left' variant='body2'>
                            Balance: &#8358;{'  '}
                            {numberWithComma(wallet ? wallet.balance : '-')}
                          </Typography>
                          <Typography align='left' variant='body2'>
                            Type:{'  '}
                            {numberWithComma(wallet ? wallet.type : '-')}
                          </Typography>
                        </Box>
                      </Box>
                    </Button>
                  ))}
              </Box>
            </Content>
            <Box marginTop='20px'>
              <Box marginBottom='10px' display='flex' alignItems='center'>
                <InfoOutlinedIcon
                  style={{ fontSize: '16px', marginRight: '3px' }}
                />
                <Typography style={{ lineHeight: 'initial' }} variant='body2'>
                  Processing fees apply to all widthdraws.
                </Typography>
              </Box>
              <Button type='submit' className={classes.btnBg}>
                Withdraw{' '}
                <span style={{ marginLeft: '10px' }}>
                  &#8358;{amount !== '' ? numberWithComma(amount) : '0'}
                </span>
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Withdraw;
