import React, { useContext, useEffect, useState } from 'react';
import WalletContext from '../../../../context/wallet/walletContext';
import { Link } from 'react-router-dom';
import Layout from '../../Layout';
import styles from '../styles';
import EnableOTP from './EnableOTP';
import WalletOperation from './WalletOperation';
import AutoTransfer from './AutoTransfer';
import Alert from '../../alert/ToastAlert';
import WalletName from './WalletName';
import Loading from '../../spinner/Spinner';

// Material-ui/Core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(styles);

const WalletView = (props) => {
  const classes = useStyles();

  const id = props.match.params.id;
  const walletContext = useContext(WalletContext);
  const { wallets } = walletContext;

  const [wallet, setWallet] = useState(null);

  useEffect(() => {
    if (wallets) {
      setWallet(wallets.find((account) => account.id === id));
    }
  }, [wallets, id]);

  // Alert State
  const [alert, setAlert] = useState(null);
  const removeAlert = () => setAlert(null);

  if (wallet === -1) {
    <Layout title='Wallet settings'>
      <Box marginTop='10px' marginBottom='30px'>
        <Typography align='center' variant='body1'>
          Something went wrong
        </Typography>
      </Box>
    </Layout>;
  }

  return (
    <Layout title={`Wallet settings`}>
      {alert && (
        <Alert msg={alert.msg} type={alert.type} closeAlert={removeAlert} />
      )}
      {wallet && wallet !== null ? (
        <Box
          style={{
            marginTop: '20px',
            marginBottom: '20px',
          }}
          className={classes.container}
          marginTop='40px'
        >
          <WalletName wallet={wallet} />
          <Box marginTop='8px' marginBottom='30px'>
            <Typography variant='body1'>
              Account type:{' '}
              {wallet.type === 'BOTH' ? 'CREDIT/DEBIT' : wallet.type}
            </Typography>
          </Box>
          {wallet.isOverdraft ? (
            <Box marginTop='10px' marginBottom='30px'>
              <Typography variant='body1'>Overdraft: YES</Typography>
            </Box>
          ) : (
            ''
          )}
          {wallet.overdraft ? (
            <Box marginTop='10px' marginBottom='30px'>
              <Typography variant='body1'>
                Overdraft: {wallet.overdraft}
              </Typography>
            </Box>
          ) : (
            ''
          )}
          <EnableOTP wallet={wallet} />

          <AutoTransfer wallet={wallet} />

          <Box marginTop='30px'>
            <Typography>
              <Link
                style={{ fontFamily: 'inherit' }}
                className={classes.link}
                to={`/settings/add-user/${wallet.wallet}`}
              >
                Add user to manage wallet
              </Link>
            </Typography>
          </Box>

          <Box marginTop='30px'>
            <Typography>
              <Link
                style={{ fontFamily: 'inherit' }}
                className={classes.link}
                to={`/settings/security-questions/wallet/${wallet.wallet}`}
              >
                Security questions
              </Link>
            </Typography>
          </Box>
          <Box marginTop='30px'>
            {wallet.isPinDefault ? (
              <Typography>
                <Link
                  style={{ fontFamily: 'inherit' }}
                  className={classes.link}
                  to={`/settings/setup-pin/wallet/${wallet.wallet}`}
                >
                  Setup wallet Pin
                </Link>
              </Typography>
            ) : (
              <Typography>
                <Link
                  style={{ fontFamily: 'inherit' }}
                  className={classes.link}
                  to={`/settings/change-pin/wallet/${wallet.wallet}`}
                >
                  Change wallet pin
                </Link>
              </Typography>
            )}
          </Box>
          {wallet.isActive && <WalletOperation wallet={wallet} />}
        </Box>
      ) : (
        <Loading />
      )}
    </Layout>
  );
};

export default WalletView;
