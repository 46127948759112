import React, { useEffect } from 'react';

// Material UI/Core
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@material-ui/core/Snackbar';

const ToastAlert = ({ style, msg, type, closeAlert }) => {
	useEffect(() => {
		if (msg) {
			setTimeout(() => {
				closeAlert();
			}, 10000);
		}
	});

	/* return (
		<Box
			style={{ width: '100%' }}
			className={classes.main}
			zIndex='15000'
			position='fixed'
			display='flex'
			justifyContent='center'
		>
			<Box zIndex='10000' className={classes.container} top='15px'>
				<Alert
					variant='filled'
					severity={type}
					action={
						<IconButton
							color='inherit'
							size='small'
							onClick={() => {
								closeAlert();
							}}
						>
							<CloseIcon fontSize='inherit' />
						</IconButton>
					}
				>
					{msg}
				</Alert>
			</Box>
		</Box>
	); */

	return (
		<Snackbar
			// anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			open={msg ? true : false}
			autoHideDuration={6000}
			onClose={closeAlert}
			style={{ zIndex: 10000 }}
		>
			<Alert
				variant='filled'
				severity={type}
				action={
					<IconButton
						color='inherit'
						size='small'
						onClick={() => {
							closeAlert();
						}}
					>
						<CloseIcon fontSize='inherit' />
					</IconButton>
				}
			>
				{msg}
			</Alert>
		</Snackbar>
	);
};

export default ToastAlert;
