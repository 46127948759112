import axios from 'axios';
import { loginAPIs } from '../../index';
import setTokenHeader from '../../setTokenInHeader';

const getUser = async () => {
	await setTokenHeader();
	const userType = JSON.parse(localStorage.getItem('user-type'));

	const filterAPI = loginAPIs.find((api) => api.type === userType);

	const res = await axios.get(`${filterAPI.url}/me`);

	if (!res.data.success) {
		throw new Error(res.data.message);
	}

	return res;
};

export default getUser;
