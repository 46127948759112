import {
  INITIATE_CREATE_WALLET,
  CREATE_WALLET,
  GET_WALLET,
  ERROR_MESSAGE,
  REMOVE_ERROR,
  DISPLAY_NOTICE,
  GET_TRANSACTION,
  CLOSE_NOTICE,
  UPDATE_WALLET_STATE,
  UPDATE_TRANSACTIONS,
} from '../types';

const walletReducer = (state, action) => {
  switch (action.type) {
    case INITIATE_CREATE_WALLET:
      return {
        ...state,
        initiateCreateWallet: true,
      };
    case UPDATE_WALLET_STATE:
      return {
        ...state,
        initiateCreateWallet: false,
        wallets: action.payload,
      };
    case CREATE_WALLET:
      return {
        ...state,
        initiateCreateWallet: false,
        wallet: action.payload,
      };
    case DISPLAY_NOTICE:
      return {
        ...state,
        isNotice: true,
      };
    case CLOSE_NOTICE:
      return {
        ...state,
        wallets: state.wallets.map(
          (wallet) => wallet.id === action.payload && { ...wallet, notice: '' }
        ),
      };
    case GET_WALLET:
      return {
        ...state,
        wallets: action.payload.payload,
        isWallet: action.payload.payload.length > 0 ? true : false,
        isNotice: action.payload.payload[0]?.notice ? true : false,
      };
    case GET_TRANSACTION:
      const filterTransactions = action.payload.sort(
        (transactions1, transactions2) =>
          new Date(transactions2.createdAt).getTime() -
          new Date(transactions1.createdAt).getTime()
      );
      return {
        ...state,
        transactions: filterTransactions,
        deposits: action.deposits,
        transfers: action.transfers,
        withdraws: action.withdraws,
      };
    case UPDATE_TRANSACTIONS:
      const filterData = action.payload.sort(
        (transactions1, transactions2) =>
          new Date(transactions2.createdAt).getTime() -
          new Date(transactions1.createdAt).getTime()
      );
      return {
        ...state,
        transactions: filterData,
        deposits: action.deposits,
        transfers: action.transfers,
        withdraws: action.withdraws,
      };
    case ERROR_MESSAGE:
      return {
        ...state,
        walletErr: action.error,
      };
    case REMOVE_ERROR:
      return {
        ...state,
        walletErr: null,
      };
    default:
      return state;
  }
};

export default walletReducer;
