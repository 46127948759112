import axios from 'axios';
import setTokenHeader from '../../setTokenInHeader';

import { walletAPI } from '../..';

const createWithdraw = async (details) => {
	await setTokenHeader();

	return await axios.post(`${walletAPI}/withdraws`, details);
};

export default createWithdraw;
