import axios from 'axios';
import setTokenHeader from '../../setTokenInHeader';

import { walletAPI } from '../..';

const updateWalletPin = async (value) => {
  await setTokenHeader();
  const payload = { newPin: value.pin, pin: '00000' };
  return await axios.put(
    `${walletAPI}/accounts/wallet/${value.wallet}`,
    payload
  );
};

export default updateWalletPin;
