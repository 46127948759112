import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import styles from '../styles';
import verifyWallet from '../../../../api/handler/wallet/verifyWallet';
import { isBrowser } from 'react-device-detect';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Material-ui/Core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';

// MAterial-ui/Icon
import FileCopyIcon from '@material-ui/icons/FileCopy';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import InfoIcon from '@material-ui/icons/Info';
import loadError from '../../../../api/loadErr';

const useStyles = makeStyles(styles);

const Wallet = ({ wallet, setAlert, setCopiedMsg }) => {
  const classes = useStyles();

  // Initiate History
  const { push } = useHistory();

  const copyEl = useRef(null);

  // Handle Copy Id
  const handleCopyText = () => {
    const content = copyEl.current.textContent;
    // navigator.clipboard.writeText(content);

    setCopiedMsg(`${content} copied`);
    setTimeout(() => {
      setCopiedMsg(null);
    }, 2000);
  };

  const toggleSelectedWallet = (value) => {
    push(`/settings/wallet/${value.id}`);
  };

  // Triger Verification
  const [triggerVerification, setTriggerVerification] = useState(false);

  // useQuery
  const queryClient = useQueryClient();
  const { isLoading } = useQuery(
    ['verify-wallet', wallet.wallet],
    () => verifyWallet(wallet.wallet),
    {
      enabled: !!triggerVerification,
      onSettled: (data, error) => {
        if (error) {
          setAlert({
            msg: loadError(error),
            type: 'error',
          });
        }
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries('user-account');
      },
    }
  );

  return (
    <>
      <Box key={wallet.id}>
        <Box
          display='flex'
          alignItems='flex-start'
          justifyContent='space-between'
        >
          <Box margin='10px 0px'>
            <Typography variant='body1'>{wallet && wallet.name}</Typography>
            <Box display='flex' alignItems='center'>
              <Typography variant='body2'>
                Wallet ID: <span ref={copyEl}>{wallet && wallet.wallet}</span>
              </Typography>
              {isBrowser && (
                <Tooltip title='Copy' placement='right'>
                  <CopyToClipboard
                    text={wallet && wallet.wallet}
                    onCopy={() => handleCopyText()}
                  >
                    <IconButton size='small'>
                      <FileCopyIcon
                        style={{
                          fontSize: '14px',
                          color: 'inherit',
                        }}
                      />
                    </IconButton>
                  </CopyToClipboard>
                </Tooltip>
              )}
            </Box>
            <Box>
              {wallet && wallet.isVerified ? (
                <Box
                  display='flex'
                  alignItems='center'
                  className={classes.successText}
                >
                  <VerifiedUserIcon />
                  <Typography variant='body2'>verified</Typography>
                </Box>
              ) : (
                <Box
                  marginTop='10px'
                  display='flex'
                  alignItems='center'
                  className={classes.errorText}
                >
                  <Box marginRight='8px' display='flex' alignItems='center'>
                    <InfoIcon />{' '}
                    <Typography variant='body2'>Not verified</Typography>
                  </Box>
                  {!wallet.isVerified && (
                    <Button
                      disabled={isLoading}
                      onClick={() => setTriggerVerification(true)}
                      className={classes.btnOutline}
                      size='small'
                    >
                      Verify wallet
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <Box>
            <Tooltip title='view settings'>
              <Button
                className={classes.btnOutline}
                size='small'
                onClick={() => toggleSelectedWallet(wallet)}
              >
                View Details
              </Button>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Wallet;
