import axios from 'axios';
import setTokenHeader from '../../setTokenInHeader';

import { walletAPI } from '../..';

const retrieveSecurityQuestions = async () => {
	await setTokenHeader();
	return await axios.get(`${walletAPI}/settings?type=SECURITY_QUESTION`);
};

export default retrieveSecurityQuestions;
