import axios from 'axios';
import setTokenHeader from '../../setTokenInHeader';

import { walletAPI } from '../..';

const createDeposit = async (details) => {
	await setTokenHeader();

	return await axios.post(`${walletAPI}/deposits`, details);
};

export default createDeposit;
