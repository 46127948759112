import React, { useReducer } from 'react';
import authReducer from './authReducer';
import AuthContext from './authContext';
import jwt from 'jsonwebtoken';

import { LOGOUT_USER, UPDATE_AUTH_STATE } from '../types';
const jwtSecret = process.env.REACT_APP_JSON_SECRET;

const AuthState = ({ children }) => {
  const initialState = {
    isAuthenticated: false,
    token: null,
    user: null,
    userType: null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  // Load user
  const updateUserState = async (details) => {
    if (details) {
      const userDetails = jwt.sign(details, jwtSecret);
      localStorage.setItem('pmt_user_details', JSON.stringify(userDetails));
    }

    dispatch({
      type: UPDATE_AUTH_STATE,
      payload: details,
    });
  };

  const getUserDetails_from_storage = () => {
    const details = JSON.parse(localStorage.getItem('pmt_user_details'));
    if (details) {
      const decodedDetails = jwt.verify(details, jwtSecret);
      return decodedDetails;
    }
  };

  // Logout User
  const logOut = () => {
    localStorage.removeItem('user-type');
    localStorage.removeItem('pmt_user_details');
    dispatch({
      type: LOGOUT_USER,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        user: state.user ? state.user : getUserDetails_from_storage(),
        userType: state.userType,
        updateUserState,
        // updateUser,
        logOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthState;
