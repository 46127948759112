import React from 'react';

// Material-UI/Core
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';

import { grayColor, secColor } from '../../assets/js/styleCss';

const useStyles = makeStyles({
	radio: {
		marginLeft: 'initial',
		marginRight: 'initial',
		'& .MuiSvgIcon-root': {
			fontSize: '20px',
		},
		'& .MuiTypography-body1': {
			fontSize: '14px',
			color: grayColor[6],
		},
		'& .MuiCheckbox-root': {
			padding: '0px',
		},
		'& .Mui-checked': {
			color: secColor,
		},
	},
});

const CustomCheckbox = (props) => {
	const classes = useStyles();
	const { label, style, innerStyle, value, children, onChange } = props;
	return (
		<Box style={style}>
			<FormLabel component='legend'>{label}</FormLabel>
			<RadioGroup
				style={innerStyle}
				className={classes.radio}
				value={value}
				onChange={onChange}
			>
				{children}
			</RadioGroup>
		</Box>
	);
};

export default CustomCheckbox;
