import axios from 'axios';
import setTokenHeader from '../../setTokenInHeader';

import { loginAPIs } from '../..';

const searchUser = async (payload) => {
  await setTokenHeader();
  const userType = JSON.parse(localStorage.getItem('user-type'));
  if (userType) {
    const match = loginAPIs.find((api) => api.type === userType);
    return await axios.get(`${match.url}/any?phone=${payload}`);
  }
};

export default searchUser;
