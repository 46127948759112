import React from 'react';
import DownlaodApp from './DownlaodApp';
import About from './About';
import Copyright from './Copyright';

// Material-UI/Core
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { grayColor, whiteColor } from '../../assets/js/styleCss';

const styles = makeStyles({
	container: {
		// backgroundColor: grayColor[3],
		color: whiteColor,
	},
	gridContainer: {
		backgroundColor: grayColor[5],
		color: grayColor[6],
		padding: '20px 15px',
		display: 'flex',
		justifyContent: 'space-between',
	},
});

const FooterMain = () => {
	const classes = styles();
	return (
		<Box>
			<Box className={classes.gridContainer}>
				<About />
				<DownlaodApp />
			</Box>
			<Copyright />
		</Box>
	);
};

export default FooterMain;
