import {
  container,
  secColor,
  transition,
  whiteColor,
  dangerColor,
  grayColor,
  successColor,
} from '../../assets/js/styleCss';

const styles = () => ({
  container: {
    ...container,
    marginTop: '10px',
  },
  arrowIcon: {
    ...transition,
  },
  displayFlex: {
    display: 'flex',
  },
  rotateArrow: {
    transform: 'rotate(90deg)',
  },
  spaceBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (min-width: 960px)': {
      justifyContent: 'flex-start',
    },
  },
  btnBg: {
    backgroundColor: secColor,
    position: 'relative',
    color: whiteColor,
    width: '100%',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: secColor,
      opacity: '0.8',
    },
  },
  btnInline: {
    backgroundColor: secColor,
    position: 'relative',
    color: whiteColor,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: secColor,
      opacity: '0.8',
    },
  },
  visibilityIcon: {
    color: grayColor[6],
    fontSize: '17px',
    cursor: 'pointer',
  },
  logout: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      fontSize: '18px',
      marginRight: '5px',
    },
  },
  circularProgress: {
    position: 'absolute',
    height: '25px !important',
    width: '25px !important',
    color: whiteColor,
  },
  switch: {
    marginLeft: '20px',
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: secColor,
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: secColor,
    },
  },
  stepper: {
    padding: '0px',
    margin: '20px 0px 30px',
    '&.MuiPaper-root': {
      backgroundColor: 'transparent !important',
    },
    '& .MuiStepIcon-root.MuiStepIcon-active': {
      color: secColor,
    },
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  errorText: {
    color: dangerColor[0],
    '& .MuiSvgIcon-root': {
      fontSize: '16px',
      marginRight: '5px',
    },
  },
  successText: {
    color: successColor[0],
    '& .MuiSvgIcon-root': {
      fontSize: '16px',
      marginRight: '5px',
    },
  },
  btnOutline: {
    color: secColor,
    border: `1px solid ${secColor}`,
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: secColor,
      color: whiteColor,
    },
  },
  box: {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: whiteColor,
    padding: '15px',
    borderRadius: '5px',
    margin: '10px 0px 20px',
  },
});

export default styles;
