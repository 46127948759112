import React, { useContext, useRef, useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import WalletContext from '../../../../context/wallet/walletContext';
import AuthContext from '../../../../context/auth/authContext';
import styles from '../styles';
import Wallet from './Wallet';

// Material-ui/Core
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Card from '@material-ui/core/Card';

// MAterial-ui/Icon
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import { secColor } from '../../../assets/js/styleCss';

const useStyles = makeStyles(styles);

const Index = ({ setAlert }) => {
  const classes = useStyles();

  // User Type
  const userTypes = ['E', 'M'];
  const walletTypes = ['DEBIT', 'CREDIT'];

  // Initialize Location
  const location = useLocation();

  // Initialize History
  const history = useHistory();

  const walletContext = useContext(WalletContext);
  const { wallets } = walletContext;
  const authContext = useContext(AuthContext);
  const { user, logOut } = authContext;

  const [adminUser, setAdminUser] = useState(null);

  // Check for Admin
  useEffect(
    () => {
      if (wallets && wallets[0].users) {
        setAdminUser(
          wallets[0].users.filter((walletUser) => walletUser.id === user.id)[0]
        );
      }
    },
    // eslint-disable-next-line
    [wallets, user.id]
  );

  const copyEl = useRef(null);

  // Copied State
  const [copiedMsg, setCopiedMsg] = useState(null);
  const closeAlert = () => setCopiedMsg(null);

  // Handle Copy Id
  const handleCopyText = () => {
    const content = copyEl.current.textContent;
    navigator.clipboard.writeText(content);

    setCopiedMsg(`${content} copied`);
    setTimeout(() => {
      setCopiedMsg(null);
    }, 2000);
  };

  // Handle Redirection
  const handleRedirection = (e) => {
    e.preventDefault();
    localStorage.setItem('prev_path', JSON.stringify(location.pathname));
    history.push('/wallet/create');
  };

  return (
    <Box marginTop='20px'>
      <Snackbar
        open={copiedMsg ? true : false}
        autoHideDuration={6000}
        onClose={closeAlert}
      >
        <Alert severity='success'>{copiedMsg}</Alert>
      </Snackbar>
      <Box marginBottom='10px'>
        <Typography variant='h6'>
          Wallet Account{wallets && wallets.length > 1 ? 's' : ''}
        </Typography>
      </Box>

      <Box
        marginBottom='30px'
        display='flex'
        justifyContent='space-between'
        alignItems='flex-start'
      >
        <Box width='100%'>
          {wallets &&
            userTypes.includes(user?.userType) &&
            wallets?.length < 2 &&
            adminUser?.id === wallets[0].admin && (
              <Card className={classes.box} display='flex' alignItems='center'>
                <InfoIcon
                  style={{
                    fontSize: '24px',
                    marginRight: '5px',
                  }}
                />
                <Typography>
                  Already have a {wallets[0]?.type} wallet account.
                  <Link
                    style={{
                      color: secColor,
                    }}
                    className={classes.link}
                    to='/wallet/create'
                    onClick={(e) => {
                      handleRedirection(e);
                    }}
                  >
                    Create a{' '}
                    {walletTypes.filter((type) => type !== wallets[0]?.type)[0]}
                    {'  '}
                    wallet account.
                  </Link>
                </Typography>
              </Card>
            )}
          {!wallets?.length > 0 && (
            <Card className={classes.box}>
              <InfoIcon
                style={{
                  fontSize: '24px',
                  marginRight: '5px',
                }}
              />
              <Typography>
                No Account in Wallet.{' '}
                <Link
                  style={{
                    color: secColor,
                  }}
                  className={classes.link}
                  to='/wallet/create'
                  onClick={(e) => {
                    handleRedirection(e);
                  }}
                >
                  Set up Wallet
                </Link>
              </Typography>
            </Card>
          )}
          <Box>
            {wallets &&
              wallets.map((wallet) => (
                <Wallet
                  wallet={wallet}
                  setAlert={setAlert}
                  handleCopyText={handleCopyText}
                  key={wallet.id}
                  setCopiedMsg={setCopiedMsg}
                />
              ))}
          </Box>
        </Box>
      </Box>

      <Divider />
      <Box marginTop='30px'>
        <Typography
          onClick={() => logOut()}
          className={classes.logout}
          variant='body1'
        >
          <ExitToAppIcon /> Log out
        </Typography>
      </Box>
    </Box>
  );
};

export default Index;
