import React from 'react';
import styles from './styles';
import appStore from '../../assets/img/appstore.png';
import playStore from '../../assets/img/play-store.png';

// Material-Ui/Core
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(styles);

const DownlaodApp = () => {
	const classes = useStyles();
	return (
		<Box>
			<Typography
				style={{ marginBottom: '5px' }}
				className={classes.headerText}
				variant='h6'
			>
				Download app
			</Typography>
			<Box
				style={{
					display: 'grid',
					gridTemplateColumns: 'repeat(2, 1fr)',
					gap: '10px',
					maxWidth: '300px',
				}}
			>
				<Box>
					<img style={{ width: '100%' }} src={playStore} alt='play-store' />
				</Box>
				<Box>
					<img style={{ width: '100%' }} src={appStore} alt='appStore-store' />
				</Box>
			</Box>
		</Box>
	);
};

export default DownlaodApp;
