import React, { useState } from 'react';
import TextField from '../../customComponent/CustomInput';
import CustomCheckbox from '../../customComponent/CustomCheckbox';

// MAterial-ui/Core
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

// Material-ui/Icon
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import InfoIcon from '@material-ui/icons/Info';

import {
	defaultBoxShadow,
	whiteColor,
	grayColor,
	dangerColor,
	secColor,
} from '../../../assets/js/styleCss';

const useStyles = makeStyles({
	fixed: {
		position: 'fixed',
		top: '0px',
		left: '0px',
		width: '100%',
		height: '100vh',
	},
	formGrid: {
		height: '100vh',
		backgroundColor: 'rgba(250, 250, 250, 0.6)',
	},
	gridItem: {
		...defaultBoxShadow,
		padding: '15px',
	},
	btnBg: {
		backgroundColor: secColor,
		position: 'relative',
		color: whiteColor,
		width: '100%',
		textTransform: 'capitalize',
		'&:hover': {
			backgroundColor: secColor,
			opacity: '0.8',
		},
	},
	visibilityIcon: {
		color: grayColor[6],
		fontSize: '17px',
		cursor: 'pointer',
	},
	closeIcon: {
		color: dangerColor[0],
	},
	smallText: {
		fontSize: '12px',
	},
});

const ConfirmAutoTransfer = ({
	close,
	pin,
	setPin,
	isProcessing,
	confirmUpdate,
	autoTransferWallets,
	selectedWallets,
	handleSelect,
}) => {
	const classes = useStyles();

	//  App State
	const [isDisplayPassword, setIsDisplayPassword] = useState(false);

	// Toggle Password
	const togglePassword = () => {
		setIsDisplayPassword(!isDisplayPassword);
	};

	// Pin Err
	const [isPinErr, setPinErr] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (pin === '') {
			setPinErr(true);
		} else {
			setPinErr(false);
		}

		if (pin !== '') {
			confirmUpdate(selectedWallets);
		}
	};

	return (
		<Box
			style={{
				overflowY: 'auto',
			}}
			zIndex='5000'
			width='100%'
			left='0px'
			top='0px'
			height='100vh'
			position='fixed'
		>
			<Grid
				className={classes.formGrid}
				container
				justifyContent='center'
				alignItems='center'
			>
				<Grid
					style={{
						backgroundColor: 'white',
						marginTop: '30px',
						marginBottom: '30px',
					}}
					className={classes.gridItem}
					item
					xs={11}
					sm={7}
					md={4}
				>
					<form onSubmit={handleSubmit}>
						<Box
							display='flex'
							alignItems='center'
							justifyContent='space-between'
							marginBottom='10px'
						>
							<Typography variant='h6'>Confirm Update</Typography>
							<IconButton onClick={() => close()} size='small'>
								<CloseIcon className={classes.closeIcon} />
							</IconButton>
						</Box>
						<Box marginBottom='20px'>
							<Box display='flex' marginBottom='5px' alignItems='center'>
								<InfoIcon style={{ fontSize: '16px', marginRight: '5px' }} />
								<Typography className={classes.smallText}>
									Select wallet you can automatically credit
								</Typography>
							</Box>
							<Box>
								{autoTransferWallets &&
									autoTransferWallets.map((wallet) => (
										<Box marginBottom='5px' key={wallet.id}>
											<CustomCheckbox
												onChange={() => handleSelect(wallet.id)}
												checked={selectedWallets.includes(wallet.id)}
												label={wallet.name}
											/>
										</Box>
									))}
							</Box>
						</Box>
						<Box marginBottom='10px'>
							<TextField
								label='Wallet pin'
								error={isPinErr}
								helperText={isPinErr ? 'Pin is required' : ''}
								fullWidth={true}
								size='small'
								type={isDisplayPassword ? 'text' : 'password'}
								variant='outlined'
								value={pin}
								onChange={(e) => setPin(e.target.value)}
								endAdornment={
									<InputAdornment position='end'>
										{isDisplayPassword ? (
											<VisibilityOffIcon
												className={classes.visibilityIcon}
												onClick={() => togglePassword()}
											/>
										) : (
											<VisibilityIcon
												className={classes.visibilityIcon}
												onClick={() => togglePassword()}
											/>
										)}
									</InputAdornment>
								}
							/>
						</Box>
						<Button
							disabled={isProcessing}
							type='submit'
							className={classes.btnBg}
						>
							confirm
						</Button>
					</form>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ConfirmAutoTransfer;
