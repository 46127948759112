import React, { useState } from 'react';
import styles from '../styles';
import TextField from '../../customComponent/CustomInput';
import numberWithComma from '../../../utils/numberWithCommas';

// MAterial-ui/Core
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Divider from '@material-ui/core/Divider';

// Material-ui/Icon
import CloseIcon from '@material-ui/icons/Close';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

const useStyles = makeStyles(styles);

const ConfirmTransfer = ({
	close,
	amount,
	title,
	walletTo,
	accountTo,
	createdAt,
	walletFrom,
	narration,
	pin,
	setPin,
	isProcessing,
	processTransfer,
}) => {
	const classes = useStyles();

	//  App State
	const [isDisplayPassword, setIsDisplayPassword] = useState(false);

	// Toggle Password
	const togglePassword = () => {
		setIsDisplayPassword(!isDisplayPassword);
	};

	// Pin Err
	const [isPinErr, setPinErr] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (pin === '') {
			setPinErr(true);
		} else {
			setPinErr(false);
		}

		if (pin !== '') {
			processTransfer();
		}
	};

	return (
		<Box className={classes.fixedContainer} position='fixed'>
			<Grid
				className={classes.formGrid}
				container
				justifyContent='center'
				alignItems='center'
			>
				<Grid item xs={11} sm={7} md={4}>
					<form onSubmit={handleSubmit} style={{ backgroundColor: 'white' }}>
						<Box
							display='flex'
							alignItems='center'
							justifyContent='space-between'
							marginBottom='20px'
						>
							<Typography variant='h6'>{title}</Typography>
							<IconButton onClick={() => close()} size='small'>
								<CloseIcon className={classes.closeIcon} />
							</IconButton>
						</Box>
						<Box marginBottom='20px'>
							<Typography
								style={{ marginBottom: '10px' }}
								className={classes.textFlex}
								variant='body2'
							>
								<span>From:</span>
								<span>
									{`${walletFrom.name}, ${walletFrom.wallet || walletFrom}`}
								</span>
							</Typography>
							<Divider />
							{accountTo ? (
								<Typography
									style={{ marginBottom: '10px', marginTop: '10px' }}
									className={classes.textFlex}
									variant='body2'
								>
									<span>To:</span>
									<span>
										<span>{`${accountTo.name}, ${accountTo.wallet}`}</span>
									</span>
								</Typography>
							) : (
								<Typography
									style={{ marginBottom: '10px', marginTop: '10px' }}
									className={classes.textFlex}
									variant='body2'
								>
									<span>To:</span>
									<span>{`${walletTo.name}, ${walletTo.wallet}`}</span>
								</Typography>
							)}
							<Divider />
							<Typography
								style={{ marginBottom: '10px', marginTop: '10px' }}
								className={classes.textFlex}
								variant='body2'
							>
								<span>Date:</span>
								<span>
									{createdAt
										? new Date(createdAt).toLocaleDateString()
										: new Date().toLocaleDateString()}
								</span>
							</Typography>
							<Divider />
							<Typography
								style={{ marginBottom: '10px', marginTop: '10px' }}
								className={classes.textFlex}
								variant='body2'
							>
								<span>Amount:</span>
								<span className={classes.amount}>
									&#8358;{'  '}
									{numberWithComma(amount)}
								</span>
							</Typography>

							{narration && (
								<>
									<Divider />
									<Typography
										style={{ marginBottom: '10px', marginTop: '10px' }}
										className={classes.textFlex}
										variant='body2'
									>
										<span>Narration:</span>
										<span>{narration}</span>
									</Typography>
								</>
							)}
						</Box>
						<Box marginBottom='10px'>
							<TextField
								label='Wallet pin'
								error={isPinErr}
								helperText={isPinErr ? 'Pin is required' : ''}
								fullWidth={true}
								size='small'
								type={isDisplayPassword ? 'text' : 'password'}
								variant='outlined'
								value={pin}
								onChange={(e) => setPin(e.target.value)}
								inputProps={{
									maxLength: 5,
									minLength: 0,
								}}
								endAdornment={
									<InputAdornment position='end'>
										{isDisplayPassword ? (
											<VisibilityOffIcon
												className={classes.visibilityIcon}
												onClick={() => togglePassword()}
											/>
										) : (
											<VisibilityIcon
												className={classes.visibilityIcon}
												onClick={() => togglePassword()}
											/>
										)}
									</InputAdornment>
								}
							/>
						</Box>
						<Button
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
							disabled={isProcessing}
							type='submit'
							className={classes.btnBg}
						>
							confirm
						</Button>
					</form>
				</Grid>
			</Grid>
		</Box>
	);
};

export default ConfirmTransfer;
