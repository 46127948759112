import React, { useReducer } from 'react';
import appReducer from './appReducer';
import AppContext from './appContext';

import {
	TOGGLE_SIDEBAR,
	/* DISPLAY_ALERT,
	HIDE_ALERT, */
} from '../types';

const AppState = (props) => {
	const initialState = {
		isDisplaySidebar: false,
		alertMsg: null,
	};

	const [state, dispatch] = useReducer(appReducer, initialState);

	// Toggle side bar
	const toggleSideBar = () => {
		dispatch({
			type: TOGGLE_SIDEBAR,
		});
	};

	/* 	// Set Alert
	const setAlert = (msg) => {
		dispatch({
			type: DISPLAY_ALERT,
			payload: msg,
		});

		setTimeout(() => {
			dispatch({
				type: HIDE_ALERT,
			});
		}, 2000);
	}; */

	return (
		<AppContext.Provider
			value={{
				isDisplaySidebar: state.isDisplaySidebar,
				alertMsg: state.alertMsg,
				toggleSideBar,
			}}
		>
			{props.children}
		</AppContext.Provider>
	);
};

export default AppState;
