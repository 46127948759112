import axios from 'axios';
import setTokenHeader from '../../setTokenInHeader';

import { walletAPI } from '../..';

const getBank = async (id) => {
	await setTokenHeader();
	return await axios.get(`${walletAPI}/accounts/info/${id}`);
};

export default getBank;
