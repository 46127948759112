import { TOGGLE_SIDEBAR, DISPLAY_ALERT, HIDE_ALERT } from '../types';

const appReducer = (state, action) => {
	switch (action.type) {
		case TOGGLE_SIDEBAR:
			return {
				...state,
				isDisplaySidebar: !state.isDisplaySidebar,
			};

		case DISPLAY_ALERT:
			return {
				...state,
				alertMsg: action.payload,
			};

		case HIDE_ALERT:
			return {
				...state,
				alertMsg: null,
			};

		default:
			return state;
	}
};

export default appReducer;
