import React, { useState } from 'react';
import { useMutation } from 'react-query';
import numberWithComma from '../../utils/numberWithCommas';
import styles from './styles';
import TransactionModal from './modal/TransactionModal';
import ConfirmTransfer from './modal/ConfirmTransfer';
import Alert from '../alert/SuccessAlert';
import updateTransfer from '../../../api/handler/wallet/updateTransfer';
import ToastAlert from '../alert/ToastAlert';
import VerifyOtp from './modal/VerifyOtp';

// Material-ui/core
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

// Material-ui/Icon
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import loadError from '../../../api/loadErr';

const useStyles = makeStyles(styles);

const TransactionItem = ({ transaction }) => {
  const classes = useStyles();

  // Trasnfer Status
  const [transferStatus, setTransferStatus] = useState('');

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const handleClick = (value) => {
    setSelectedTransaction(value);
  };

  // Modal
  const [modalTitle, setModalTitle] = useState('');
  const closeModal = () => setSelectedTransaction(null);
  const close = () => {
    setDisplayConfirmTransfer(false);
    setPin('');
  };

  // Alert State
  const [alert, setAlert] = useState(null);
  const removeAlert = () => {
    setAlert(null);
    setDisplayConfirmTransfer(false);
    setSelectedTransaction(null);
  };

  // Approve Tranfer State
  const [pin, setPin] = useState('');
  const [otp, setOtp] = useState('');

  // Handle Transfer Update
  const [isDisplayConfirmTransfer, setDisplayConfirmTransfer] = useState(false);
  const displayConfirmTransfer = (status, title) => {
    setTransferStatus(status);
    setModalTitle(title);
    setDisplayConfirmTransfer(true);
  };

  // Display Verify OTP
  const [isDisplayOtpModal, setDisplayOtpModal] = useState(false);
  const closeOtpModal = () => setDisplayOtpModal(false);
  const displayOtpModal = (value) => {
    setTransferStatus(value);
    setDisplayOtpModal(true);
  };

  // Approve Transfer
  const { mutate, isLoading: isProcessing } = useMutation(updateTransfer, {
    onSettled: (data, error) => {
      if (error) {
        setAlert({
          msg: loadError(error),
          type: 'error',
        });
      }
    },
    onSuccess: (data) => {
      setAlert({
        msg: data.data.message,
        type: 'success',
        title: 'Transfer message',
      });
      setPin('');
      setOtp('');
    },
  });

  // Handler
  const processTransfer = async () => {
    const payload = {
      details: { pin, status: transferStatus },
      id: selectedTransaction.id,
    };
    mutate(payload);
  };

  // Handle Verify Otp
  const handleVerifyOtp = () => {
    mutate({
      details: {
        pin,
        status: 'APPROVE',
        otp,
      },
      id: selectedTransaction.id,
    });
  };

  return (
    <>
      {alert && alert.type === 'error' && (
        <ToastAlert
          msg={alert.msg}
          type={alert.type}
          closeAlert={removeAlert}
        />
      )}
      {alert && alert.type === 'success' && (
        <Alert
          title={alert.title}
          msg={alert.msg}
          type={alert.type}
          removeAlert={removeAlert}
        />
      )}
      {selectedTransaction && (
        <TransactionModal
          isProcessing={isProcessing}
          displayConfirmTransfer={displayConfirmTransfer}
          displayOtpModal={displayOtpModal}
          close={closeModal}
          transaction={selectedTransaction}
        />
      )}
      {isDisplayOtpModal && (
        <VerifyOtp
          title='Enter OTP and Wallet Pin'
          close={closeOtpModal}
          id={selectedTransaction.id}
          otp={otp}
          pin={pin}
          setPin={setPin}
          setAlert={setAlert}
          setOtp={setOtp}
          isProcessing={isProcessing}
          handleVerifyOtp={handleVerifyOtp}
          displayPin={true}
        />
      )}
      {isDisplayConfirmTransfer && (
        <ConfirmTransfer
          close={close}
          title={modalTitle}
          createdAt={selectedTransaction.createdAt}
          narration={selectedTransaction.narration}
          amount={selectedTransaction.amount}
          accountTo={selectedTransaction.accountTo}
          walletFrom={selectedTransaction.accountFrom}
          isProcessing={isProcessing}
          pin={pin}
          setPin={setPin}
          processTransfer={processTransfer}
        />
      )}
      <List className={classes.transactionList}>
        <ListItem>
          <Box
            onClick={() => handleClick(transaction)}
            display='flex'
            alignItems='flex-end'
            justifyContent='space-between'
            width='100%'
            style={{
              cursor: 'pointer',
            }}
          >
            <Box display='flex' alignItems='center'>
              {transaction.type === 'D' ? (
                <ArrowDownwardIcon className={classes.depositIcon} />
              ) : transaction.type === 'W' ? (
                <ArrowUpwardIcon className={classes.withdrawIcon} />
              ) : (
                <SwapHorizIcon className={classes.transferIcon} />
              )}
              <Box>
                <small>{new Date(transaction.createdAt).toDateString()}</small>
                <Typography
                  className={classes.headerText}
                  style={{
                    lineHeight: 'initial',
                    textTransform: 'capitalize',
                    margin: '5px 0px',
                    fontSize: '18px',
                    fontWeight: '600',
                  }}
                  variant='body1'
                >
                  {transaction.type === 'D'
                    ? 'Deposit'
                    : transaction.type === 'W'
                    ? 'Withdraw'
                    : 'Transfer'}
                </Typography>
              </Box>
            </Box>
            <Typography variant='body1'>
              ₦{numberWithComma(transaction.amount)}
            </Typography>
          </Box>
        </ListItem>
      </List>
    </>
  );
};

export default TransactionItem;
