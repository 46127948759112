import {
  btn,
  container,
  dangerColor,
  defaultBoxShadow,
  grayColor,
  infoColor,
  link,
  primaryColor,
  secColor,
  successColor,
  transition,
  whiteColor,
} from '../../assets/js/styleCss';

const styles = () => ({
  container: {},
  fixedContainer: {
    position: 'fixed',
    zIndex: '4800',
    width: '100%',
    height: '100vh',
    overflowY: 'scroll',
    top: '0',
    left: '0',
    backgroundColor: 'rgba(250, 250, 250, 0.9)',
  },
  formGrid: {
    height: '100vh',
    margin: '20px 0px !important',
    '& form': {
      ...defaultBoxShadow,
      padding: '15px',
    },
  },
  gridContent: {
    ...defaultBoxShadow,
  },
  content: {
    ...container,
    color: grayColor[6],
  },
  gridItem: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    alignItems: 'center',
  },
  box: {
    ...defaultBoxShadow,
    marginTop: '30px',
    marginBottom: '30px',
    backgroundColor: whiteColor,
    padding: '20px',
  },
  card: {
    padding: '20px',
  },
  cardContentM: {
    display: 'grid',
    gridGap: '15px',
    color: secColor,
    gridTemplateColumns: '1fr',
    '@media (min-width: 760px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  cardContent: {
    display: 'grid',
    gridGap: '15px',
    color: secColor,
    gridTemplateColumns: '1fr',
  },
  quickAction: {
    gridGap: '20px',
    margin: '10px 0px',
    gridTemplateColumns: '1fr',
    color: secColor,
    '& .MuiCard-root': {
      ...transition,
      padding: '15px',
      '&:hover': {
        ...defaultBoxShadow,
        transform: 'translateY(-15px)',
      },
    },
    '@media (min-width: 760px)': {
      marginTop: '40px',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gridGap: '30px',
    },
  },
  mobileView: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: 'calc(90vh)',
    marginBottom: '15px',
  },
  userText: {
    fontSize: '18px',
    '@media (min-width: 960px)': {
      fontSize: '24px',
    },
    '& + p': {
      fontSize: '12px',
      '@media (min-width: 960px)': {
        fontSize: '14px',
      },
    },
  },
  icon: {
    color: primaryColor,
    fontSize: '18px',
    verticalAlign: 'middle',
  },
  favoriteIcon: {
    color: secColor,
    fontSize: '16px',
    marginRight: '5px',
  },
  verifyIcon: {
    color: successColor[0],
  },
  depositContainer: {
    ...container,
  },
  transactionContainer: {
    ...container,
  },
  walletWrapper: {
    textAlign: 'center',
  },
  walletGrid: {
    display: 'grid',
    gap: '10px',
    textAlign: 'start',
    gridTemplateColumns: '1fr',
    '@media (min-width: 760px)': {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
  },
  list: {
    padding: '0px',
    '& .MuiListItem-root': {
      padding: '8px 0px',
    },
  },
  transactionList: {
    padding: '0px',
    '& .MuiListItem-root': {
      padding: '8px 0px',
      marginBottom: '20px',
    },
    '& .MuiListItem-root:last-child': {
      padding: '8px 0px',
      marginBottom: '20px',
    },
  },
  amountLargeText: {
    color: secColor,
  },
  amount: {
    fontSize: '16px !important',
    fontWeight: 'bold',
    color: secColor,
  },
  headerText: {
    color: secColor,
  },
  subHeaderText: {
    color: secColor,
    marginBottom: '15px',
    fontSize: '18px',
  },
  smallText: {
    color: secColor,
  },
  textFlex: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  errorText: {
    color: dangerColor[0],
    display: 'flex',
    alignItems: 'center',
    '& .MuiSvgIcon-root': {
      marginRight: '5px',
      fontSize: '17px',
    },
  },
  depositIcon: {
    color: successColor[0],
    marginRight: '10px',
    fontSize: '18px',
  },
  closeIcon: {
    color: dangerColor[0],
  },
  visibilityIcon: {
    color: grayColor[6],
    fontSize: '14px',
    cursor: 'pointer',
  },
  verifyBtn: {
    color: primaryColor,
    backgroundColor: whiteColor,
    border: `1px solid ${primaryColor}`,
    textTransform: 'capitalize',
    '&:hover': {
      color: whiteColor,
      backgroundColor: primaryColor,
    },
  },
  resendBtn: {
    ...link,
    color: secColor,
  },
  switch: {
    '& .MuiSwitch-colorSecondary.Mui-checked': {
      color: secColor,
    },
    '& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
      backgroundColor: secColor,
    },
  },
  avatar: {
    height: '50px',
    width: '50px',
  },
  checked: {
    color: successColor[0],
    fontSize: '16px',
  },
  circularProgress: {
    color: primaryColor,
  },
  transferIcon: {
    color: infoColor[4],
    marginRight: '10px',
    fontSize: '18px',
  },
  withdrawIcon: {
    color: dangerColor[0],
    marginRight: '10px',
    fontSize: '18px',
  },
  cancelIcon: {
    color: grayColor[6],
    fontSize: '18px',
    marginLeft: '10px',
  },
  quickActionlink: {
    color: 'inherit',
    textDecoration: 'none',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    borderRadius: '5px',
    marginTop: '20px',
    '& svg': {
      marginRight: '10px',
    },
    '&:hover': {
      color: whiteColor,
      backgroundColor: secColor,
    },
  },
  link: {
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: secColor,
    },
  },
  btnBg: {
    ...btn,
    textTransform: 'capitalize',
    position: 'relative',
    display: 'inline-block',
    backgroundColor: secColor,
    color: whiteColor,
    width: '100%',
    '&:hover': {
      backgroundColor: secColor,
      opacity: '0.8',
    },
    '& svg': {
      position: 'abolute',
      color: 'inherit',
      fontSize: '16px',
      width: '16px',
    },
  },
  copyBtn: {
    display: 'none',
    '@media (min-width: 960px)': {
      display: 'block',
    },
  },
  btnInline: {
    ...btn,
    textTransform: 'capitalize',
    display: 'inline-block',
    backgroundColor: secColor,
    color: whiteColor,
    width: '40%',
    '&:hover': {
      backgroundColor: secColor,
      opacity: '0.8',
    },
  },
  btnBgCancel: {
    ...btn,
    textTransform: 'capitalize',
    display: 'inline-block',
    backgroundColor: dangerColor[0],
    color: whiteColor,
    width: '100%',
    '&:hover': {
      backgroundColor: primaryColor,
      opacity: '0.8',
    },
  },
  successColor: {
    color: successColor[0],
  },
  dangerColor: {
    color: dangerColor[0],
  },
  infoColor: {
    color: infoColor[4],
  },
});

export default styles;
