import axios from 'axios';
import setTokenHeader from '../../setTokenInHeader';

import { walletAPI } from '../..';

const retreiveTransactions = async () => {
  await setTokenHeader();

  return axios.get(
    `${walletAPI}/transactions/user?sort=-_id&populate=deposit,transfer,withdraw,accountTo`
  );

  /* return axios
    .all([
      axios.get(`${walletAPI}/deposits`),
      axios.get(`${walletAPI}/transfers?populate=accountTo,accountFrom`),
      axios.get(`${walletAPI}/withdraws`),
    ])
    .then(axios.spread((...res) => res)); */
};

export default retreiveTransactions;
