import React from 'react';

// Material-UI/Core
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import { grayColor, whiteColor } from '../../assets/js/styleCss';

const styles = makeStyles({
	container: {
		textAlign: 'center',
		color: whiteColor,
		backgroundColor: grayColor[6],
		padding: '20px 15px',
		flexDirection: 'column',
		display: 'flex',
		justifyContent: 'space-between',
		'@media (min-width: 960px)': {
			flexDirection: 'row',
		},
	},
	termsCondition: {
		marginBottom: '20px',
		'@media (min-width: 960px)': {
			marginBottom: '0px',
		},
	},
	text: {
		fontSize: '13px',
	},
});

const Copyright = () => {
	const classes = styles();
	return (
		<div className={classes.container}>
			<Box className={classes.termsCondition} display='flex'>
				<Typography
					style={{ marginRight: '30px' }}
					className={classes.text}
					variant='body2'
				>
					Terms & Conditions
				</Typography>
				<Typography className={classes.text} variant='body2'>
					Help
				</Typography>
			</Box>
			<Typography className={classes.text} variant='body2'>
				@ {new Date().getFullYear()} PMT Wallet. All rights reserved.
			</Typography>
		</div>
	);
};

export default Copyright;
