import React from 'react';
import { useIsFetching } from 'react-query';

// Material-UI/Core
import { CircularProgress, Box } from '@material-ui/core';
import { secColor } from '../component/assets/js/styleCss';

const GlobalLoadingIndicator = () => {
  const isFetching = useIsFetching();
  return isFetching ? (
    <Box position='fixed' top='15px' right='15px' zIndex='15000'>
      <CircularProgress
        style={{ width: '30px', height: '30px', color: secColor }}
      />
    </Box>
  ) : null;
};

export default GlobalLoadingIndicator;
