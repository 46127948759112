export const UserType = {
	CUSTOMER: 'C',
	MERCHANT: 'M',
	PARTNER: 'P',
	ENTERPRISE: 'E',
};

export const WalletType = {
	DEBIT: 'DEBIT',
	CREDIT: 'CREDIT',
};
