import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import styles from '../styles';
import ConfirmUpdate from './ConfirmUpdate';
import Alert from '../../alert/SuccessAlert';
import updateWallet from '../../../../api/handler/wallet/updateWallet';

// Material-Ui/Core
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core/styles';
import loadError from '../../../../api/loadErr';

const useStyles = makeStyles(styles);

const DisableWalletOperation = ({ wallet }) => {
  const classes = useStyles();

  // App State
  const [isChecked, setChecked] = useState(wallet && wallet.isActive);
  const toggleCheck = () => {
    setDisplayModal(true);
    setChecked(!isChecked);
  };

  // Display Confirm Pin
  const [isDisplayModal, setDisplayModal] = useState(false);
  const closeModal = () => {
    setChecked(!wallet.isActive);
    setPin('');
    setDisplayModal(false);
  };

  // Pin
  const [pin, setPin] = useState('');

  // Alert State
  const [alert, setAlert] = useState(null);
  const removeAlert = () => setAlert(null);

  const queryClient = useQueryClient();

  const { isLoading, mutate } = useMutation(updateWallet, {
    onSettled: (data, error) => {
      if (error) {
        setAlert({
          msg: loadError(error),
          type: 'error',
        });
      }
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries('user-account');
      setDisplayModal(false);
      setPin('');
      setAlert({
        title: 'Wallet Settings Message',
        msg: 'Wallet disable successfully!',
        type: 'success',
      });
    },
  });

  // Process Update
  const confirmUpdate = async () => {
    mutate({
      payload: {
        isActive: isChecked,
        pin,
      },
      wallet: wallet.wallet,
    });
  };

  return (
    <>
      {alert && alert.type === 'success' && (
        <Alert
          msg={alert.msg}
          title={alert.title}
          type={alert.type}
          removeAlert={removeAlert}
        />
      )}
      {isDisplayModal && (
        <ConfirmUpdate
          pin={pin}
          setPin={setPin}
          isProcessing={isLoading}
          confirmUpdate={confirmUpdate}
          close={closeModal}
        />
      )}
      <Box marginTop='30px' className={classes.spaceBetween}>
        <Typography>
          {wallet && wallet.isActive ? 'Disable wallet' : 'Enable wallet'}
        </Typography>
        <Switch
          className={classes.switch}
          size='small'
          checked={!isChecked}
          onChange={() => toggleCheck()}
        />
      </Box>
    </>
  );
};

export default DisableWalletOperation;
